import { useState, useEffect } from 'react'
import './BlueCheckbox.scss'

const BlueCheckbox = ({ value = -1, onChange, err, setErr = false }) => {

    const [check, setCheck] = useState(value !== -1 ? value : true)

    useEffect(() => {
        if (setErr) {
            setErr(false)
        }
    }, [value]);

    return (
        <label className='checkbox_container_blue'>
            <input type="checkbox"
                style={{ width: '0px', opacity: 0, visibility: 'hidden', height: 0, clip: 'rect(1px, 1px, 1px, 1px)' }}
                className='checkbox'
                checked={check}
                onChange={(e) => {
                    const check = e?.target?.checked
                    setCheck(check)
                    onChange(check)
                }}
            />
            <span className={!err ? 'checkbox_blue' : 'checkbox_blue_err'}></span>
        </label>
    )
}

export default BlueCheckbox