import headers from '../../common/headers'
import { LineChart } from '../../charts/LineChart/LineChart';
import { SliderRange } from '../Slider/Slider';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { Andrey2Https, Andrey2Http } from '../../fetchUrls'
import { useDataTable, useChartData } from '../../common/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react'
import { RangeBox, TableBox, DropContainer, SearchGlobalInput, DropDownSelector, Tabs, CheckboxToggleBlue, Checkbox } from "@lk-gtcom/ecomlab-components";
import "./AnalysisPlatformByBrand.scss"
import { Helmet } from 'react-helmet-async';

const btn_tab = [
    // {
    //     label: 'Top',
    //     value: 'top'
    // },
    {
        label: 'Products',
        value: 'goods'
    },
    {
        label: 'Category',
        value: 'category'
    },
    {
        label: 'Sellers',
        value: 'sellers'
    },
    {
        label: 'By days',
        value: 'days'
    },
    {
        label: 'Price segments',
        value: 'price_segment'
    }
]

const options_period = [
    {
        label: 'days',
        value: 'days'
    },
    {
        label: 'week',
        value: 'week'
    },

    {
        label: 'months',
        value: 'months'
    }
]


const AnalysisPlatformByBrand = () => {

    const activeTab = useParams()['*']
    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [showDropBox, setShowDropBox] = useState(false)
    const [date, setDate] = useState([new Date().getTime() - (604800000), new Date().getTime()])

    const [tableMetrics, setTableMetrics] = useState([])
    const [tableMetric, setTableMetric] = useState('IDC')

    const [search, setSearch] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [searchData, setSearchData] = useState([])

    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(0)
    const [price, setPrice] = useState([minValue, maxValue])
    const [segments, setSegments] = useState(1)

    const [selectedItems, setSelectedItems] = useState([])
    const [isReverse, setIsReverse] = useState(false)

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { loading, sort, filters } = tableProps
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs

    const [setChartData, chartData, setChartFuncs] = useChartData()
    const { setChartLoading, resetChart } = setChartFuncs

    const [choisePrice, setChoisePrice] = useState([])

    const [chartDataName, setChartDataName] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState(options_period[0])

    const [searchParams, setSearchParams] = useSearchParams()
    const [checkFbs, setCheckFbs] = useState(false)
    const [isClick, setIsClick] = useState(false)

    const resetFilter = () => {
        setSearchData([])
    }

    const resetTable = () => {
        paginatorRef.current.reset()
        paginatorRef.current.setTotal(0)
        setSort({})
        setHeaders([])
        setFetchedData([])
        setChartData([])
    }

    const onHandleSliderChange = (minMax) => {
        setPrice(minMax)
    }

    const fetchFilter = ([search, page = 1]) => {
        if (search?.length > 0) {

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v1/gtcom/brands/search_string/`
            } else {
                url = `${Andrey2Http}/api/v1/gtcom/brands/search_string/`
            }

            let [date_from, date_to] = date

            date_from = new Date(date_from + 86401000)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                date_from,
                date_to,
                page: 1,
                limit: 15,
                brand_search: search
            })


            fetch(url, { body, method: 'POST', headers })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    setSearchData({ brand: Object?.values(json)?.[0] });
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    const fetchAllAccountData = (params, abortController = new AbortController()) => {

        if (!pageValue) return
        setLoading(true)

        let sort;
        let filtersParam;

        if (params) {
            [sort, filtersParam] = params
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v2/gtcom/brands/${pageValue}/table/`
        } else {
            url = `${Andrey2Http}/api/v2/gtcom/brands/${pageValue}/table/`
        }

        let [date_from, date_to] = date
        date_from = new Date(date_from + 86401000)?.toISOString().split('T')?.[0]
        date_to = new Date(date_to)?.toISOString().split('T')?.[0]

        const priceData = pageValue == 'price_segment'
            ?
            {
                min_price: price[0],
                max_price: price[1],
                number_of_segments: segments
            }
            : {}

        const reverseData = pageValue == 'days'
            ?
            {
                reverse: isReverse,
            }
            : {}

        const body = JSON.stringify({
            // brand_name_filter: search, // старый фильтр
            brand_id_filter: [
                search
            ],
            limit: paginatorRef.current.limit,
            page: paginatorRef.current.page,
            group_type: selectedPeriod?.value,
            fbofbs_filter: checkFbs,
            date_from,
            date_to,
            sort_name: sort?.[0],
            sort_order: sort?.[1],
            filters: filtersParam,
            ...priceData,
            ...reverseData,
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setTable(json)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })

    }

    const fetchMinMaxPrice = () => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v2/gtcom/brands/price_range/`
        } else {
            url = `${Andrey2Https}/api/v2/gtcom/brands/price_range/`
        }

        let [date_from, date_to] = date
        date_from = new Date(date_from + 86401000)?.toISOString().split('T')?.[0]
        date_to = new Date(date_to)?.toISOString().split('T')?.[0]

        const body = JSON.stringify({
            brand_id_filter: search,
            fbofbs_filter: checkFbs,
            date_from,
            date_to,
        })


        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                const { min_price, max_price } = json
                setMinValue(min_price)
                setMaxValue(max_price)
                setPrice([min_price, max_price])
            })
            .catch(err => {
                console.log(err)
            })

    }

    const fetchChartData = (abortController = new AbortController()) => {

        setChartLoading(true)

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v2/gtcom/brands/${pageValue}/graph/`
        } else {
            url = `${Andrey2Http}/api/v2/gtcom/brands/${pageValue}/graph/`
        }

        let searchId;
        let [date_from, date_to] = date
        date_from = new Date(date_from + 86401000)?.toISOString().split('T')?.[0]
        date_to = new Date(date_to)?.toISOString().split('T')?.[0]

        const id_arr = selectedItems?.map(el => {
            if (el) {
                if (pageValue === 'goods' && el?.['Product']) {
                    if (Array.isArray(el?.['Product'])) {
                        const [img, name, category, brand, nm_id] = el?.['Product']
                        return el?.['Product'][6]
                    }
                }

                if (pageValue === 'sellers' && el?.seller_id) {
                    return el?.seller_id
                }

                if (pageValue === 'category' && el?.category_id) {
                    return el?.category_id
                }
                if (pageValue === 'top') {
                    return el?.brand_id
                }
                if (pageValue === 'price_segment' && el?.min_price && el.max_price) {
                    return [el?.min_price, el?.max_price]
                }
            }
        })

        if (pageValue === 'goods') {
            searchId = { nm_id: id_arr }
        }
        if (pageValue === 'sellers') {
            searchId = { seller_id: id_arr }
        }
        if (pageValue === 'category') {
            searchId = { category_id: id_arr }
        }
        if (pageValue === 'top') {
            searchId = { brand_id: id_arr }
        }
        if (pageValue === 'price_segment') {
            if (Array.isArray(id_arr) && id_arr?.length > 0) {
                searchId = {
                    min_price: id_arr[0][0],
                    max_price: id_arr[0][1],
                }
            }
        }

        // const priceData = pageValue == 'price_segment'
        //     ?
        //     {
        //         min_price: choisePrice[0],
        //         max_price: choisePrice[1],
        //     }
        //     : {}

        const body = JSON.stringify({
            brand_id_filter: [search], // top, goods, graph, days, pice
            fbofbs_filter: checkFbs,
            date_from,
            date_to,
            limit: 300,
            page: 1,
            // ...priceData,
            metrics: [],
            metric_name: tableMetric,
            ...searchId
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setChartData(json)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setChartLoading(false)
                if (abortController.signal.aborted) return
            })

    }

    const fetchMetricsList = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v2/gtcom/brands/${pageValue}/metric_list/`
        } else {
            url = `${Andrey2Http}/api/v2/gtcom/brands/${pageValue}/metric_list/`
        }


        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setTableMetrics(json);
                setTableMetric(json[0]?.value)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleCheck = (data) => {
        setSelectedItems(data)
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (pageValue == 'top') {
            fetchAllAccountData([], abortController)
        }

        if (search && search?.length > 0) {
            if (pageValue === 'price_segment') {
                fetchMinMaxPrice()
            }
            resetTable()

            if (pageValue !== 'price_segment') {
                fetchAllAccountData([], abortController)
            }
        } else resetTable()
        return () => abortController.abort()
    }, [date, pageValue, search, selectedPeriod, checkFbs, isReverse])


    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    const onRedirect = e => {
        const [__, link] = e
        if(Array.isArray(link)){
            window.open(link?.[1], '_blank')
        } else {
            window.open(link, '_blank')
        }     
    }

    useEffect(() => {
        const query = searchParams.get('query')
        if (query) {
            setSearch(query)
            setSearchValue(query)
        }
    }, [searchParams])

    useEffect(() => {
        const abortController = new AbortController()
        if (pageValue == 'goods'
            || pageValue == 'sellers'
            || pageValue == 'category'
            || pageValue == 'price_segment') {
            if (search?.length > 0) {
                if (pageValue != 'days' && selectedItems?.length > 0) fetchChartData(abortController)
                if (pageValue == 'days') fetchChartData(abortController)
            }
        }

        // if (pageValue == 'price_segment') {
        //     if (choisePrice?.length > 0 && search?.length > 0) {
        //         fetchChartData(abortController)
        //     }
        // }
        if (pageValue == 'top') {
            fetchChartData(abortController)
        }
        if (pageValue == 'days' && search?.length > 0) {
            fetchChartData(abortController)
        }
        return () => abortController.abort()
    }, [chartData?.selectedLegend, selectedItems, date, pageValue, search, selectedPeriod, checkFbs, tableMetric, choisePrice])

    useEffect(() => {
        fetchMetricsList()
    }, [pageValue]);

    useEffect(() => {
        setSelectedItems([])
        setChoisePrice([])
        setSelectedItems([])
        setChartData([])
    }, [pageValue, search]);

    useEffect(() => {
        resetChart()
    }, [pageValue]);

    // const onAction = (e) => {
    //     setChoisePrice([e.min_price, e.max_price])
    // }

    return (
        <>
            <Helmet>
                <title>Brand analysis</title>
            </Helmet>
            <div className='analysis-platform-by-brand'>
                <div className='analysis-platform-by-brand__content'>
                    <div className='top-panel'>
                        <SearchGlobalInput
                            {...searchData}
                            setSearch={({ extra }) => setSearch(extra)}
                            fetchSearch={e => fetchFilter(e)}
                            setSearchData={e => setSearchData(e)}
                            value={searchValue}
                        />
                        <TooltipLightBlue
                            text={<p className='text_tooltip'>FBO (sales from the site's warehouse) is the default. Click on the switch to see FBS (sales from the site's warehouse)</p>}
                            top='32px'
                            left='0px'
                            maxWidth='110px'
                            child={
                                <label className='checkbox-container'>
                                    <CheckboxToggleBlue
                                        value={checkFbs}
                                        onChange={(e) => setCheckFbs(!checkFbs)}
                                    />
                                    <p className='text_label'>FBS</p>
                                </label>
                            }
                        />
                    </div>

                    <div className='toolbar-medium'>
                        <Tabs pageValue={pageValue} tabs={btn_tab} />

                        <div className='range-group-btn-and-range-box'>
                            {pageValue == "date" && <div className='drop-box'>
                                <p className='text'>By</p>
                                <DropDownSelector
                                    options_prop={options_period}
                                    state={selectedPeriod}
                                    setState={e => setSelectedPeriod(e)}
                                    defaultValue={selectedPeriod}
                                    className='connections_page_selector'
                                    isClearable={false}
                                />
                            </div>}
                            <RangeBox lang='en' date={date} setDate={e => setDate(e)} minMaxDate={[1, new Date().setHours(0, 0, 0, 100)]} />
                        </div>

                    </div>

                    {<DropContainer
                        lang='en'
                        showDropBox={showDropBox}
                        setShowDropBox={(e) => setShowDropBox(e)}
                        name={chartData?.loading ? 'loading...' : chartData?.title}
                        selectedMetric={tableMetric}
                        setSelectedMetric={setTableMetric}
                        metricsList={tableMetrics}
                    >
                        <div className='charts-container'>
                            {
                                <LineChart
                                    {...chartData}
                                    lang='en'
                                />
                            }
                        </div>
                    </DropContainer>}

                    {pageValue === "price_segment" &&
                        <SliderRange
                            minValue={minValue}
                            maxValue={maxValue}
                            segments={segments}
                            setSegments={e => setSegments(e)}
                            setState={e => onHandleSliderChange(e)}
                            callbackHandler={e => {
                                setIsClick(!isClick)
                                resetChart()
                                fetchAllAccountData(e)
                            }}
                            lang='en'
                        />
                    }
                </div>
                <TableBox lang='en'
                    {...tableProps}
                    paginator={true}
                    fetchCallback={e => fetchAllAccountData(e)}
                    sortingFunc={e => fetchAllAccountData(e)}
                    ref={paginatorRef}
                    onAction={(e) => onRedirect(e)}
                    maxSelectedCount={pageValue == 'price_segment' ? 1 : 2000}
                    onRedirect={onRedirect}
                    date={date}
                    onCheck={e => handleCheck(e)}
                    onUncheckAllDependence={[pageValue, search, isClick]}
                    child={
                        <div style={{ display: 'flex', gap: '.5em', flexDirection: 'column', boxSizing: 'border-box', padding: '.5em 0 1em' }}>
                            {
                                pageValue === 'days' &&
                                <div>
                                    <p style={{ display: 'flex', gap: '.5em' }}>
                                        Перевернуть
                                        <Checkbox value={isReverse} onChange={checked => setIsReverse(checked)} />
                                    </p>
                                </div>
                            }
                        </div>
                    }
                />

            </div >
        </>

    )
}

export { AnalysisPlatformByBrand }