import { useState } from 'react';
import "./ModalPasswordRecovery.scss"
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components";

const ModalPasswordRecovery = ({setIsModal}) => {

    const [isModalMail, setIsModalMail] = useState(true)

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='password-recovery'>
                <button className='btn-close' onClick={(e) => setIsModal(false)}></button>
                <h2 className='title'
                    onClick={(e) => setIsModal(false)}
                >Restore password</h2>
                <div className='input-with-btn'>
                    <InputDinamycPlaceholder
                        onChange={e => (e)}
                        type="e-mail"
                        classname='autorization__input-field'
                        placeholder='E-mail'
                        err={''}
                        textErr="Invalid email format"
                        value={''}
                        setValue={(e) => (e)}
                    />
                </div>
                <button
                    className='btn__blue'
                >Confirm</button>
            </div>
        </div>
    )
}

export { ModalPasswordRecovery }