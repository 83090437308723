import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import err_icon from './img/err_icon.svg'
import './ModalErr.scss'

const ModalErr = ({title,  setIsModal}) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>  
            <div className='modal-err'>
                <img src={err_icon} />
                <h2 className='title'>Something went wrong</h2>
                <p className='text_grey-blue'>Try to refresh the page</p>
                <button  onClick={(e) => setIsModal(false)}  className='btn__blue'>Ок</button>
            </div>
       </div>
    )
}

export { ModalErr }