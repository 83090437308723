import headers from '../../../common/headers';
import link_icon from './img/link_icon.svg'
import drop_icon from './img/drop_icon.svg'
import drop_active_icon from './img/drop-active_icon.svg'
import { RavshanArticlesHttps } from '../../../fetchUrls';
import { handleApiResponseOrThrow } from '../../../common/utils';
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls';
import { useEffect, useState, useRef, useCallback } from 'react';
import { InputSearch, ArticleView, ShortTileArticleBox, usePagination, NoArticles } from "@lk-gtcom/ecomlab-components";
import "./ModalKnowledgeBase.scss"

const ModalKnowledgeBase = ({ setIsModal, sidebarItems, isModal }) => {

    const html = document.querySelector('html')
    const [isOpen, setIsOpen] = useState(new Set())
    const [isRate, setIsRate] = useState(null)
    const [step, setStep] = useState(1)
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [isActiveSection, setIsActiveSection] = useState(null)

    const [sidebarState, setSidebarState] = useState([])
    const [activeMenuItem, setActiveMenuItem] = useState(null)
    const [articles, setArticles] = useState([])
    const [search, setSearch] = useState('')

    const [contentData, setContentData] = useState([])
    const [apiData, setApiData] = useState(null)
    const setContentDataCallback = useCallback(e => setContentData(e), [])
    const [contentLinks, setContentLinks] = useState([])

    const openContainer = (label) => {
        const newState = new Set(isOpen)
        if (newState.has(label)) {
            newState.delete(label)
        } else {
            newState.add(label)
        }
        setIsOpen(newState)
    }

    const paginatorRef = useRef()
    const paginatorCallback2 = useRef()

    const [
        page,
        limit,
        total,
        setTotal,
        Paginator,
        resetPaginator,
        paginatorTransition
    ] = usePagination(paginatorCallback2.current, [], paginatorRef.current?.params)

    const fetchSidebar = () => {
        const url = `${RavshanArticlesHttps}/get_portals_levels`

        const getHeaders = {
            ...headers,
            'portal-id': 5,
            'language': 'en',
            'Accept': 'application/json'
        }

        fetch(url, { headers: getHeaders })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                if (Array.isArray(json)) {
                    setSidebarState(json)
                }
            })
            .catch(err => console.log(err))
    }

    const fetchArticlesByLevel = (value, params) => {
        setApiData(null)
        setIsActiveSection(null)
        const abortController = new AbortController()

        const url = `${RavshanArticlesHttps}/get_articles_by_parents`

        let filters = null, sorting = null
        if (params?.length) {
            [sorting, filters] = params
        }

        const orderParam = ''
        const orderParamDirection = ''

        const getHeaders = {
            ...headers,
            "level-id": activeMenuItem,
            "language": 'en',
            "page": page,
            "limit": limit,
            "status": "active",
            'with-status': false
        }

        // if (orderParam) Object.assign(getHeaders, { "order-param": btoa(encodeURI(orderParam)) })
        // if (orderParamDirection) Object.assign(getHeaders, { "order-param-direction": orderParamDirection })
        // if (filters && filters.length) Object.assign(getHeaders, { "filters": btoa(encodeURI(JSON.stringify({ filters }))) })

        fetch(url, { headers: getHeaders, signal: abortController.signal })
            .then(res => handleApiResponseOrThrow(res))
            .then(json => {
                setTotal(json.total)
                setArticles(json?.data)
            })
            .catch(err => console.error(err))
        return abortController
    }

    const fetchArticleSearch = (id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/search`
        } else {
            url = `${RavshanHttp}/search`
        }

        const currentHeaders = {
            ...headers,
            // 'language': 'en',
            // 'level-id': 5,
            // 'page': page,
            // 'limit': limit
        }

        const body = JSON.stringify({
            query: search,
            'language': 'en',
            'level_id': activeMenuItem,
            "portal_id": 7,
            'page': page,
            'limit': limit
        })

        fetch(url, { body, method: 'POST', headers: currentHeaders })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                const { data, labels, total, sort, column_size } = json
                setTotal(json.total)
                setArticles(json?.data)
                setArticles(data)
            })
            .catch(err => console.error(err))
    }

    const fetchGeArticleByPortal = (id) => {
        setApiData(null)
        setIsActiveSection(null)
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/lk/get_article`
        } else {
            url = `${RavshanHttp}/api/lk/get_article`
        }

        const currentHeaders = {
            ...headers,
            'article-id': id,
            'active': true,
        }

        fetch(url, { method: 'GET', headers: currentHeaders })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setApiData(json)
                //  setLoading(true)
                setIsActiveSection(json?.short_name)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        fetchSidebar()
    }, []);

    useEffect(() => {
        if (isModal && apiData) {
            html.style = 'overflow: hidden'
        }
    })


    useEffect(() => {
        setApiData(null)
        if (search?.length > 0) {
            resetPaginator()
        }
    }, [search]);

    useEffect(() => {
        if (search?.length > 0) {
            fetchArticleSearch()
        } else {
            fetchArticlesByLevel()
        }
    }, [activeMenuItem, page, limit, search]);

    useEffect(() => {
        if (sidebarState?.length > 0) {
            setActiveMenuItem(sidebarState[0].value)
            setBreadcrumbs([sidebarState[0].label])
            fetchGeArticleByPortal(sidebarState[0].value)
        }
    }, [sidebarState]);

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
                html.style = 'overflow: unset'
            }
        }}>
            <div className='modal-knowledge-base'>
                <div className='modal-knowledge-base__sidebar'>
                    <h1 className='title'>Know Base Gtcom<a target="_blank" href='https://wiki.ecomru.ru/'><img src={link_icon} /></a></h1>

                    <div className='sidebar-content'>
                        {sidebarState?.map(({ label, subItems, value, place }, ind) => {
                            const tileMain = label
                            return <div className='card-info-knowledge' key={label + ind}>
                                <h3 className='title_section' onClick={(e) => {
                                    resetPaginator()
                                    setSearch('')
                                    setApiData(null)
                                    setIsActiveSection(null)
                                    setActiveMenuItem(value)
                                    setBreadcrumbs([tileMain])
                                }} >{label}</h3>
                                <div className='card-info-knowledge__content'>
                                    {(subItems && subItems?.length > 0) &&
                                        subItems.map(({ label, sections }, ind) => {
                                            const titleParrent = label
                                            return <>
                                                <div className='label-and-icon' onClick={(e) => {
                                                    if (sections.length > 0) {
                                                        openContainer(label)
                                                    }
                                                }}>
                                                    <p className={isOpen.has(titleParrent) ? 'text_active' : (label == isActiveSection ? 'text_active' : 'text')}
                                                        key={label + ind}
                                                        onClick={(e) => {
                                                            if (!sections || sections.length == 0) {
                                                                setBreadcrumbs([tileMain, titleParrent])
                                                                setIsActiveSection(label)
                                                            }
                                                        }}
                                                    >{label}</p>
                                                    {(sections?.length > 0) && <img src={isOpen.has(titleParrent) ? drop_active_icon : drop_icon} />}
                                                </div>

                                                {sections && <div className={isOpen.has(titleParrent) ? 'drop-conent_active' : 'drop-conent'}>
                                                    {sections?.map(({ label }, ind) =>
                                                        <p className={label == isActiveSection ? 'text_active' : 'text'}
                                                            onClick={(e) => {
                                                                setBreadcrumbs([tileMain, titleParrent, label])
                                                                setIsActiveSection(label)
                                                            }}
                                                            key={label + ind}
                                                        >{label}</p>
                                                    )}
                                                </div>
                                                }
                                            </>
                                        })}
                                </div>
                            </div>
                        })}
                    </div>
                </div>


                <div className='modal-knowledge-base__content'>
                    <InputSearch
                        onKeyDown={(e) => (e)}
                        onChange={(e) => setSearch(e)}
                    />
                    {((breadcrumbs?.length > 0) && search?.length == 0) && <div className='breadcrumbs-list'>
                        {breadcrumbs?.map((el, ind) => <p className='breadcrumbs-list__item'
                            key={el + ind}>{el}</p>)}
                    </div>}

                    {search?.length > 0 && <p className='breadcrumbs-list__item'>Search results</p>}

                    {apiData ?
                        (apiData?.content?.length > 0 ? <div className='message-box'>
                            <ArticleView
                                readonly
                                articleTitle={apiData?.name}
                                apiData={apiData}
                                contentData={contentData}
                                setContentData={setContentDataCallback}
                                setListOfContent={setContentLinks}
                                hideInfoPanel
                                hideRecommendation
                            />
                        </div>
                            : 'В этой статье нет информации'
                        )
                        :
                        articles.length ? <div className='article-main-content'>
                            <div className='article-main-content__content'>
                                {articles.map((data, ind) => {
                                    const [id, portalId, active, photo_preview, name, tags, annotation, date, author, timeToRead, views] = data

                                    return <div onClick={(e) => {
                                        e.stopPropagation()
                                        fetchGeArticleByPortal(id)

                                    }}>
                                        <ShortTileArticleBox
                                            id={id}
                                            name={name}
                                            author={author}
                                            annotation={annotation}
                                            photoPreview={photo_preview}
                                            tags={tags}
                                            date={new Date(date)}
                                            timeToRead={timeToRead}
                                            views={views}
                                            statusArray={["https://dl.ecomru.ru/svg-storage/icon-waiting.svg", "Update"]}
                                            onLinkClick={(e) => (e)}
                                            showStatus
                                            stopRedirect
                                        />
                                    </div>
                                })}
                            </div>
                            {Paginator}

                        </div>
                            : <NoArticles />
                    }
                </div>
            </div>
        </div >
    )
}

export { ModalKnowledgeBase }