import { useState } from 'react'
import { LineChart } from '../../charts/LineChart/LineChart'
import { TableBox, DropContainer, Tabs } from "@lk-gtcom/ecomlab-components"
import { DynamicsComponent } from '../../components/DynamicsComponent/DynamicsComponent'
import "./ProductCardExtended_FinancialAnalysis.scss"

const btn_tab = [
    {
        label: 'По ключевым словам',
        value: 'by_keywords',
    },
    {
        label: 'По категориям',
        value: 'by_category',
    },
    {
        label: 'По брендам',
        value: 'by_brand',
    },
    {
        label: 'По продавцу',
        value: 'by_seller',
    }
]


const ProductCardExtended_FinancialAnalysis = ({
    fetchedMetrics, tableProps, paginatorRef, tableUrl, tableBody, setCheckedProducts, resetTable, fetchTableData, chartData
}) => {

    const [showDropBox, setShowDropBox] = useState(true)
    const [pageValue, setPageValue] = useState(btn_tab[0].value)

    return (
        <section className='financial-analysis-inside-content'>
            <DropContainer
                lang='en'
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
            >
                <div className='inside-box'>
                    <LineChart
                        legendPosition='top'
                        {...chartData}
                        lang='en'
                    />

                    <DynamicsComponent
                        title='Воронка продаж с рекламных каналов'
                    />
                </div>
            </DropContainer>

            <Tabs tabs={btn_tab} pageValue={pageValue} setPageValue={e => setPageValue(e)} stopRedirect />

            <TableBox
                {...tableProps}
                fetchCallback={e => {
                    resetTable()
                    fetchTableData(e)
                }}
                ref={paginatorRef}
                tableUrl={tableUrl}
                tableBody={tableBody}
                onCheck={setCheckedProducts}
                lang='en'
            />
        </section>
    )
}

export { ProductCardExtended_FinancialAnalysis }