import { useState } from 'react'
import { TableBox, CardStatistics } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_Feedbacks.scss"

const ProductCardExtended_Feedbacks = ({
    tableData,
    chartData,
    tableCallback,
    productMetrics,
    paginatorRef
}) => {

    return (
        <section className='feedbacks-inside-content'>
            <div className='card-group'>
                {productMetrics?.map((e, i) => {
                    const title = e?.labels?.title
                    const data = e?.data
                    const unit = e?.unit
                    return <CardStatistics
                        key={i + title}
                        title={title}
                        value={data}
                        units={unit}
                    />
                })}
            </div>

            <TableBox
                {...tableData}
                ref={paginatorRef}
                sortingFunc={tableCallback}
                paginator={true}
                fetchCallback={tableCallback}
            />
        </section>
    )
}

export { ProductCardExtended_Feedbacks }