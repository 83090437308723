import React, { Component } from "react";
import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './CarouselSlick.scss'
import not_img from './img/not-img.svg'
import { useState, useEffect } from "react";

const CarouselSlick = ({ imgArr }) => {

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);
    }, []);

    return (
        <div className="carousel-slick">
            <div className="carousel-slick_min">
                <Slider
                    arrows={false}
                    focusOnSelect={true}
                    // infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                    asNavFor={nav2}
                    ref={slider1}
                >
                    {imgArr?.length > 0 ? imgArr.map((el, ind) =>
                        <div className="img-box" key={el + ind}>
                             <div className="content-img">
                                <img className="img_slide" src={el} />
                             </div>           
                        </div>
                    )
                        :
                        <div className="img-box" style={{display: 'flex', width: '80%'}}>
                            <div className="content-img">
                                <img className="not_img" src={not_img} />
                            </div>
                        </div>
                    }
                </Slider>
            </div>

            {imgArr?.length > 1 && <Slider
                focusOnSelect={true}
                infinite={false}
                speed={500}
                slidesToShow={3}
                slidesToScroll={1}
                asNavFor={nav1}
                ref={slider2}>
                {imgArr.map((el, ind) =>
                    <div className="img-box" key={el + ind}>
                         <div className="content-img_min">
                            <img className="img_slide" src={el} />
                         </div>
                    </div>
                )}
            </Slider>}
        </div>


    );
}

export { CarouselSlick }