import { Link } from 'react-router-dom';
import banner from './img/banner-404.svg'
import { Helmet } from 'react-helmet-async';
import "./Page404.scss"

const Page404 = () => {

    return (
        <div className='page-404'>
            <Helmet>
                <title>404</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <h2 className='title'>Page not found</h2>
            <Link to='/top-categories' className='title_medium'>Back to the main page</Link>
            <img src={banner} />
       </div>
    )
}

export { Page404 }