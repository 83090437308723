import React from 'react'
import './FooterItem.scss'
import { Link } from 'react-router-dom'

const FooterItem = ({ label, subItems, onClick }) => {

    const footerLinks = subItems.map((el) => {
        const { label: sidebarLabel, path = '#' } = el
        return (
            <li key={sidebarLabel} className='footer__list_item' onClick={e => { if(onClick) onClick(el) }}>
                <Link to={path} className="footer__link">{sidebarLabel}</Link>
            </li>
        );
    });

    return (
        <div className="footer__item">
            <h4 className="footer__title">{label}</h4>
            <ul className="footer__list">
                {footerLinks}
            </ul>
        </div>
    )
}
export { FooterItem }