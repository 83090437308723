import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'


export const useGeneralStore = create(
    persist(
        ( set ) => ({
            // Пространства
            spaceList: [],
            setSpaceList: (list) => set({ spaceList: list }),

            currentSpace: {},
            setCurrentSpace: (space) => set({ currentSpace: space }),

            selectedSpace: {},
            setSelectedSpace: (value) => {
                console.log(value);
                
                set({ selectedSpace: value })
            },


            // Уведомления
            notificationCounter: 0,
            setNotificationCounter: (value) => set({ notificationCounter: value }),
        
            // Баланс
            balance: 0,
            setBalance: (value) => set({ balance: value }),

            accounList: [],
            setAccountList: (list) => set({ accounList: list }),
        
            employeesList: [],
            setEmployeesList: (list) => set({ employeesList: list }),

        }),
        {
            name: 'general-storage',
            // storage: createJSONStorage(() => localStorage)
        }
    )
);


export default useGeneralStore;
