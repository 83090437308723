import Footer from './components/Footer';
import Cookies from 'universal-cookie'
import { Modal } from './components/Modal/Modal';
import { Page404 } from './pages/Page404/Page404'
import { HomePage } from './pages/HomePage/HomePage';
import { Settings } from './pages/Settings/Settings';
import { NotActive } from './components/NotActive/NotActive';
import { TopBrands } from './components/TopBrands/TopBrands';
import { HeaderMain } from './components/HeaderMain/HeaderMain';
import { TopSellers } from './components/TopSellers/TopSellers';
import { TopCategory } from './components/TopCategory/TopCategory';
import { NicheSelection } from './components/NicheSelection/NicheSelection';
import { NicheSearchVer2 } from './components/NicheSearchVer2/NicheSearchVer2';
import { setUpFetchProxy } from './microServices/api/fetch';
import { AnalysisCategory } from './components/AnalysisCategory/AnalysisCategory';
import { ModalKnowledgeBase } from './components/Modal/ModalKnowledgeBase/ModalKnowledgeBase';
import { ProductCardExtended } from './pages/ProductCardExtended/ProductCardExtended';
import { Cookie, ACCEPT_COOKIE } from './components/Cookie/Cookie';
import { AnalysisPlatformByBrand } from './components/AnalysisPlatformByBrand/AnalysisPlatformByBrand';
import { AnalysisPlatformBySellers } from './components/AnalysisPlatformBySellers/AnalysisPlatformBySellers';
import { refresh, parseJwt, TOKEN_LIFETIME_MS } from './common/authHelper';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect, createContext, useReducer } from 'react';
import { useShallow } from 'zustand/react/shallow';
import customHeaders, { getSpace } from './common/headers';
import useGeneralStore from './store/general';
import useModal from './store/modal';
import { NotificationsVer2 } from './pages/NotificationsVer2/NotificationsVer2'
import './index.css'
import SkuSearch from './pages/SkuSearch/SkuSearch';

export const ACTION_TYPES = {
  setAccountId: 'set account id',
  setName: 'set name',
  setServiceData: 'set service data'
}

const defaultState = {
  accountId: 0,
  name: "",
  serviceData: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.setAccountId:
      return { ...state, accountId: action.payload }
    case ACTION_TYPES.setName:
      return { ...state, name: action.payload }
    case ACTION_TYPES.setServiceData:
      return { ...state, serviceData: action.payload }
    default:
      return defaultState
  }
}

function App() {
  const path = useParams();
  const navigate = useNavigate();
  
  
  const { isModalActive, setIsModalActive } = useModal(
    useShallow((state) => ({
      isModalActive: state.isModalActive,
      setIsModalActive: state.setIsModalActive,
    }))
  );

  const [isModalFeedback, setIsModalFeedback] = useState(false)
  const [showNotificationBox, setShowNotificationBox] = useState(false);
  const [activeContent, setActiveContent] = useState("");
  const [connectedModal, setConnectedModal] = useState(false);

  const [connectionIds, setConnectionIds] = useState([]);

  const [editableConnection, dispatch] = useReducer(
    reducer,
    defaultState
  );
  const [showCookie, setShowCookie] = useState(true)

  const [optionList, setOptionsList] = useState([]);
  const [currentSpace, setCurrentSpace] = useState([]);

  const { selectedSpace, setSelectedSpace, spaceList, setSpaceList } =
    useGeneralStore(
      useShallow((state) => ({
        selectedSpace: state.selectedSpace,
        setSelectedSpace: state.setSelectedSpace,
        spaceList: state.spaceList,
        setSpaceList: state.setSpaceList,
      }))
    );
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  // Модальные окна
  const [isModalKnowledgeBase, setIsModalKnowledgeBase] = useState(false)


  const sidebarItems = [
    {
      label: 'Category analysis',
      subItems: [
        {
          label: 'Category analysis',
          path: 'category-analysis/goods',
          subItems: [],
        },
        {
          label: 'Top categories',
          path: 'top-categories',
          subItems: [],
        },
      ]
    },
    {
      label: 'Brand analysis',
      subItems: [
        {
          label: 'Brand analysis',
          path: 'brand-analysis/goods',
          subItems: [],
        },
        {
          label: 'Top brands',
          path: 'top-brands',
          subItems: [],
        },
      ]
    },
    {
      label: 'Seller analysis',
      subItems: [
        {
          label: 'Seller analysis',
          path: 'seller-analysis/goods',
          subItems: [],
        },
        {
          label: 'Top sellers',
          path: 'top-sellers',
          subItems: [],
        },
      ]
    },
    {
      label: 'SKU analysis',
      path: 'sku-search/',
      subItems: [],
    },
    {
      label: 'Niche search',
      path: 'niche-selection',
      subItems: [],
    },
  ];

  const [activeItems, setActiveItems] = useState(sidebarItems[0])
  const [activeSubItems, setActiveSubItems] = useState('')


  // Достает из сессии сохраненные пункты основного меню
  useEffect(() => {
    setTimeout(() => {
      const activeItemStorage = sessionStorage.getItem('menu_isOpenItem')
      const activeSubItemStorage = sessionStorage.getItem('menu_isOpenSubItem')

      if ((path['*'] == 'landing') || path['*'].split('/')[0] == 'settings') {
        setActiveItems(null)
        setActiveSubItems(null)
      } else {
        if (activeItemStorage || activeSubItemStorage) {
          setActiveItems(JSON.parse(activeItemStorage ? activeItemStorage : null))
          setActiveSubItems(JSON.parse(activeSubItemStorage ? activeSubItemStorage : null))
        } else {
          setActiveItems(sidebarItems[0])
          setActiveSubItems('')
        }
      }
    }, 100)
  }, [path['*']]);

  // В основном меню убираем активные пункты, если страница им не соответствует
  useEffect(() => {
    if ((path['*'] == 'landing') || path['*'].split('/')[0] == 'settings') {
      setActiveItems(null)
      setActiveSubItems(null)
    }
  }, [path, navigate]);

  useEffect(() => {
    document.body.scrollTop = 0
  }, [navigate]);

  // Устанавливаем язык
  useEffect(() => {
    localStorage.setItem('lang', 'en')
  }, []);

  // Записывает в сессию сохраненные пункты основного меню
  useEffect(() => {

    sessionStorage.setItem('menu_isOpenItem', JSON.stringify(activeItems ? activeItems : null))
    sessionStorage.setItem('menu_isOpenSubItem', JSON.stringify(activeSubItems ? activeSubItems : null))
  }, [activeItems, activeSubItems]);

  const setNavigation = (cookies) => {
    const isAccount = localStorage.getItem("not_one_account");
    const auth_token = cookies.get("auth");

    if ((auth_token)) {
      if (isAccount) {
        if (path["*"] === "" && activeContent !== "Admin") {
          navigate(`/top-categories`);
        }
      } else {
        //перенаправляет постоянно на лендинг, что мешает постоянно и препятствует корректной работе меню
        //ломает логику пунктов меню, 
        //navigate("/analytics/date");
      }
    }
    else {
      navigate('../auth')
    }
  };


  useEffect(() => {
    const cookies = new Cookies();

    setUpFetchProxy()

    const authLocal = localStorage.getItem('auth')
    const refreshLocal = localStorage.getItem('refresh')
    const acceptCookie = cookies.get(ACCEPT_COOKIE)

    if (acceptCookie) setShowCookie(false)

    if (authLocal || refreshLocal) {
      cookies.remove('auth', { path: '/' })
      cookies.remove('refresh', { path: '/' })

      let accessTokenExpiryDate = new Date()
      let refreshTokenExpiryDate = new Date()

      const { exp: refreshExpTime } = parseJwt(refreshLocal)

      accessTokenExpiryDate.setTime(accessTokenExpiryDate.getTime() + TOKEN_LIFETIME_MS)
      refreshTokenExpiryDate.setTime(refreshTokenExpiryDate.getTime() + refreshExpTime)

      cookies.set('auth', authLocal, { expires: accessTokenExpiryDate, path: '/' })
      cookies.set('refresh', refreshLocal, { expires: refreshTokenExpiryDate, path: '/' })

      localStorage.removeItem('auth')
      localStorage.removeItem('refresh')
      window.location.reload()
    }

    const authToken = cookies.get('auth')
    const refreshToken = cookies.get('refresh')

    if (!authToken && refreshToken) {
      refresh({ token: refreshToken, onError: () => navigate('../auth'), shouldStayOnPage: true })
      return
    }

    setNavigation(cookies);
  }, []);

  return (
    <>
      {showCookie && <Cookie setIsModal={(e) => setShowCookie(e)} />}

      {isModalKnowledgeBase && <ModalKnowledgeBase
        sidebarItems={sidebarItems}
        setIsModal={(e) => setIsModalKnowledgeBase(e)} />}

      <main className="main">
        <ConnectionsContext.Provider value={{ editableConnection, dispatch }}>
          <div className="content">
            <NotificationContext.Provider
              value={{
                showNotificationBox,
                setShowNotificationBox,
              }}
            >
              <HeaderMain
                sidebarItems={sidebarItems}
                activeContent={activeContent}
                activeItems={activeItems}
                setActiveItems={(e) => setActiveItems(e)}
                setActiveSubItems={(e) => setActiveSubItems(e)}
                setIsModalKnowledgeBase={(e) => setIsModalKnowledgeBase(e)}
                activeSubItems={activeSubItems}
              />
              <div className='content-area'>
                <Routes>

                  {/* Настройки */}
                  <Route
                    path="/settings/*"
                    element={
                      <Settings
                        setActiveItems={(e) => setActiveItems(e)}
                        setActiveSubItems={(e) => setActiveSubItems(e)}
                        isModalFeedback={isModalFeedback}
                        setIsModalFeedback={(e) => setIsModalFeedback(e)}
                        setConnectedModal={(e) => setConnectedModal(e)}
                        setIsModalActive={(e) => setIsModalActive(e)}
                        isModalActive={isModalActive}
                      />
                    }
                  />

                  {/* Лендинг */}
                  <Route path="/landing" element={<HomePage />} />

                  {/* Категории */}
                  <Route path='category-analysis/*' element={<AnalysisCategory />} />
                  <Route path='top-categories/*' element={<TopCategory />} />

                  {/* Бренды */}
                  <Route path='top-brands/*' element={<TopBrands />} />

                  {/* Продавцы */}
                  <Route path='seller-analysis/*' element={<AnalysisPlatformBySellers />} />
                  <Route path='top-sellers/*' element={<TopSellers />} />

                  <Route path='niche-search/*' element={<NicheSearchVer2 />} />
                  <Route path='niche-selection/*' element={<NicheSelection setIsModalKnowledgeBase={(e) => setIsModalKnowledgeBase(e)} />} />
                  <Route path='sku-analysis/*' element={<ProductCardExtended title='Sku analysis' />} />
                  <Route path='sku-search/*' element={<SkuSearch />} />
                  <Route path='brand-analysis/*' element={<AnalysisPlatformByBrand />} />

                  {/* Страница в разработке */}
                  <Route path='not-active' element={<NotActive />} />

                  {/* 404 */}
                  <Route path='/*' element={<Page404 />} />

                  <Route path="/service/*" element={<NotificationsVer2 />} />

          
                </Routes>
              </div>
            </NotificationContext.Provider>
          </div>
          {isModalActive ? (
            <Modal
              optionList={optionList}
              selectedSpace={selectedSpace}
              setSelectedSpace={(e) => setSelectedSpace(e)}
              setIsModalFeedback={(e) => setIsModalFeedback(e)}
              setIsModalActive={(e) => setIsModalActive(e)}
              showAddConnectionModal={connectedModal}
              setConnectedModal={(e) => setConnectedModal(e)}
              connectionIds={connectionIds}
              setConnectionIds={(e) => setConnectionIds(e)}
            />
          ) : null}
        </ConnectionsContext.Provider>
      </main>
      {activeContent != "Admin" ? <Footer /> : null}
    </>
  );
}

export const NotificationContext = createContext(false);
export const ConnectionsContext = createContext(false);

export default App;
