import drop_icon from './img/drop_icon.svg'
import drop_icon_up from './img/drop_icon_up.svg'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import "./MultiLevelDropMenu.scss"

const MultiLevelDropMenu = ({...props}) => {
    const { data, setLevel } = props

    const [isOpen, setIsOpen] = useState(true)


    return (
        <>
            {
                data?.map(({...props}) => {
                    const {label, subItems, category_id} = props
                    let level1 = {label, value: { id: category_id}, parent_id: ''}
                    return(
                        <div className='multi-level-drop-menu'>
                            <div className='multi-level-drop-menu__level-1'>
                                <p className='text_level-1' onClick={() => { if(setLevel) setLevel([ level1 ])}}>{label ? label : 'Not found'}</p>
                            </div>
                                {
                                    subItems?.map(({...props}) => {
                                        const {label, subItems, category_id} = props
                                        let level2 = {label, value: { id: category_id}, parent_id: level1?.value?.id}
                                        return(
                                            <div className='multi-level-drop-menu__content'>
                                                <div className='multi-level-drop-menu__level-2'>
                                                    <div className='text-and-icon'
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setIsOpen(prev => !prev)
                                                        }}>
                                                        <p className='text_level-2' onClick={() => { if(setLevel)  setLevel([ level1, level2 ]) }}>{label ? label : 'Not found'}</p>
                                                        {subItems?.length > 0 && <button className='btn-drop'><img src={isOpen ? drop_icon_up : drop_icon} /></button>}
                                                    </div>
                                                    <div className={isOpen ? 'multi-level-drop-menu__inside-content' : 'multi-level-drop-menu__inside-content_hide'}>
                                                        {
                                                            subItems?.map(({...props}) => {
                                                                const {label, path, category_id} = props
                                                                let level3 = {label, value: { id: category_id}, parent_id: level2?.value?.id}
                                                                return(
                                                                    <Link className='text_level-3' to={path} 
                                                                        onClick={() => { if(setLevel) setLevel([ level1, level2, level3 ]) }}
                                                                    >
                                                                        {label ? label : 'Not found'}
                                                                    </Link>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                        </div>
                    )
                })
            }
        </>
        
    )
}

export { MultiLevelDropMenu }