import "./ButtonBasic.scss"

const ButtonBasic = ({
    text,
    onClick,
    width = 210,
    minWidth,
    size,
    disabled = false,
    props, maxWidth,
    style,
    blue = false,
    orange = false,
    red = false,
    green = false,
    whiteBlue = false,
    grey = false
}) => {

    // Если передан только "width" - то она становится статичной шириной, кнопка не расширяется и не сужается
    // Если передана "minWidth" кнопка становится пластичной и тянется до with(наибольшей ширины), minWidt(наименьшей ширины)

    const colorClassName = () => {
        if (blue) return 'blue-btn'
        if (whiteBlue) return 'white-blue-btn'
        if (green) return 'green-btn'
        if (orange) return 'orange-btn'
        if (red) return 'red-btn'
        if (grey) return 'grey-btn'
    }

    return (
        <button
            className={`btn-basic ${colorClassName() || 'blue-btn'}`}
            disabled={disabled}
            style={{
                minWidth: minWidth ? (minWidth) : (width),
                maxWidth: width,
                height: size ? (size) : '40px',
                maxWidth: maxWidth ? maxWidth : '',
                ...style
            }}
            onClick={(e) => onClick(e)}
            {...props}
        >{text}</button>
    )
}

export { ButtonBasic }