import { Link } from 'react-router-dom'
import { FooterItem } from './FooterItem/FooterItem';
import { CopyrightBox } from './CopyrightBox/CopyrightBox';
import './Footer.scss'

const visa = require('./img/visa.png')
const masterCard = require('./img/masterCard.png')
const google = require('./img/googlePay.png')
const samsung = require('./img/samsungPay.png')
const troy = require('./img/troy.png')


const footerItems = [
    {
        label: 'SERVICES',
        subItems: [
            {
                label: 'Category Analytics',
                path: '/category-analysis/goods'
            },
            {
                label: 'Niche search',
                path: '/niche-selection'
            },
            {
                label: 'SKU analysis',
                path: '/sku-analysis/information'
            },
            {
                label: 'Brand analysis',
                path: '/brand-analysis/goods'
            },
            {
                label: 'Sellers analysis',
                path: '/seller-analysis/goods'
            },
        ],
    },
    {
        label: 'ABOUT US',
        subItems: [
            {
                label: 'Mission',
                path: 'https://gtcom.io/main/article/Mission_of_GTCOM/572'
            },
            {
                label: 'About company',
                path: 'https://gtcom.io/main/article/About_company/613'
            },
            {
                label: 'Offices',
                path: 'https://gtcom.io/main/article/Offices/582'
            },
            {
                label: 'News',
                path: 'https://gtcom.io/main/articles/News'
            },
        ],
    },
];

const transactionPlatforms = [
    troy, visa, masterCard, google, samsung
]


const Footer = () => {
    return (
        <footer className="footer">
            <div className='footer__content'>
                <div className="footer__upper-box">
                    <nav className="footer__nav">
                        {footerItems.map((el) => {
                            const { label, subItems } = el
                            return <FooterItem
                                onClick={e => {
                                    sessionStorage.setItem('menu_isOpenItem', JSON.stringify(e))
                                }}
                                key={label}
                                label={label}
                                subItems={subItems}
                            />
                        })}
                        <div className="footer__item">
                            <h4 className="footer__title">GTCOM.IO</h4>
                            <address className="footer__address">
                                <ul className="footer__list">
                                    {/* <li className="footer__list_item">
                                        <a href="tel:8(800)301-65-63" className="footer__link">8(800)301-65-63</a>
                                    </li> */}
                                    <li className="footer__list_item">
                                        <a href="mailto:info@gtcom.io" className="footer__link">info@gtcom.io</a>
                                    </li>
                                    <li className="footer__list_item">
                                        <Link to="/service/support" className="footer__link">Support</Link>
                                    </li>
                                    <li className="footer__list_item">
                                        <Link to="#" className="footer__link">iCERENKOY MAHALLESI USKUDAR- iCERENKOY YOLU CAD. OZIS SiT. OFiS ATASEHIR AP. NO 21/6</Link>
                                    </li>
                                </ul>
                            </address>
                        </div>
                    </nav>
                </div>
                <div className='footer__bottom-box'>
                    <CopyrightBox />
                </div>
                <div className="footer__org_info">
                    <div className="footer_org_inner">
                        <span style={{ fontSize: 12 }}>Cashless payment for legal entities and sole proprietors</span>
                        {
                            transactionPlatforms.map((el, ind) => {
                                return <img src={el} alt="Фото платежной платформы" key={el + ind} />
                            })
                        }
                    </div>
                </div>
            </div>
        </footer>
    )
}

export { Footer }