import { useState } from 'react'
import { LineChart } from '../../charts/LineChart/LineChart'
import { TableBox, DropContainer, CardStatistics } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_StockBalances.scss"

const btn_tab = [
    {
        label: 'По ключевым словам',
        value: 'by_keywords',
    },
    {
        label: 'По категориям',
        value: 'by_category',
    },
    {
        label: 'По брендам',
        value: 'by_brand',
    },
    {
        label: 'По продавцу',
        value: 'by_seller',
    }
]

const ProductCardExtended_StockBalances = ({
    chartData,
    productMetrics
}) => {

    const [showDropBox, setShowDropBox] = useState(true)
    const [pageValue, setPageValue] = useState(btn_tab[0].value)

    return (
        <section className='stock-balances-inside-content'>
            <DropContainer
                lang='en'
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
            >
                <LineChart
                    {...chartData}
                />
            </DropContainer>

            <div className='card-group'>
                {productMetrics?.map((e, i) => {
                    const title = e?.labels?.title
                    const data = e?.data
                    const unit = e?.unit
                    return <CardStatistics
                        key={i + title}
                        title={title}
                        value={data}
                        units={unit}
                    />
                })}
            </div>
        </section>
    )
}

export { ProductCardExtended_StockBalances }