import App from './App';
import ReactDOM from 'react-dom/client';
import AcceptMail  from './components/AcceptMail/';
import ServiceAuth from './pages/ServiceAuth/';
import { AuthCheck } from './pages/AuthCheck/AuthCheck';
import { AuthorizationPage } from './pages/AuthorizationPage/AuthorizationPage';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './index.css'
import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/auth/*' element={< AuthorizationPage />} />
        <Route path='/accept-mail/:id' element={<AcceptMail />} /> 
        <Route path='/auth-check/:id' element={<AuthCheck />} /> 
        <Route path='/service_auth/*' element={<ServiceAuth />} />
        <Route path='/*' element={< App />} />
      </Routes>
    </BrowserRouter>
  </HelmetProvider>
);

