import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import done from './img/done-green.svg'
import './Mail.scss'

const Mail = ({ title, text, setIsModal }) => {

    return (
        <div className='blackout'>
            <div className='modal-mail'>
                <img src={done} />
                {title && <h2 className='title'>{title}</h2>}
                {text && <p className='text_grey-blue'>{text}</p>}
                <button onClick={(e) => setIsModal(false)} className='btn__blue'>Ок</button>
            </div>
        </div>
    )
}

export { Mail }