import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import done from './img/done.svg'
import './ModalSuccessful.scss'

const ModalSuccessful = ({setIsModal, text, title}) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>  
            <div className='modal-successful'>
                <img src={done} />
                <h2 className='title'>{title ? title :'Ваш запрос успешно отправлен' }</h2>
                {text && <p className='text'>{text}</p>}
                <button  onClick={(e) => setIsModal(false)}  className='btn__blue'>Ок</button>
            </div>
       </div>
    )
}

export { ModalSuccessful }