import headers from '../../../common/headers';
import { Navigate, useParams } from 'react-router-dom';
import { LineChart } from '../../../charts/LineChart/LineChart';
import { SliderRange } from '../../Slider/Slider';
import { useState, useEffect, useRef } from 'react'
import { useChartData } from '../../../common/hooks';
import { AndreySkuHttps, AndreySkuHttp } from '../../../fetchUrls';
import { Tabs, RangeBoxDoublePeriod, TableBox, DropDownSelector } from "@lk-gtcom/ecomlab-components";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import "./ModalNiche.scss"

const btn_tab = [
    {
        label: 'Analysis',
        value: 'analysis'
    },
    {
        label: 'Segmental analysis',
        value: 'segmental_analysis'
    },
    {
        label: 'Goods',
        value: 'goods'
    },
    {
        label: 'Brands',
        value: 'brands'
    },
    {
        label: 'Sellers',
        value: 'sellers'
    },
    {
        label: 'Days',
        value: 'days'
    },
    {
        label: 'Periods compare',
        value: 'periods_compare'
    },
    {
        label: 'Price segment',
        value: 'price_segment'
    },
]

const sales_type = [
    { value: 5, label: 'Все' },
    { value: 1, label: 'FBO' },
    { value: 2, label: 'FBS' },
    { value: 3, label: 'Из-за рубежа' },
    { value: 4, label: 'RFBS' },
]

const ModalNiche = ({
    setIsModal,
    currentCategory,
    checkFbs,
    currentBreadcrumbs,
    date,
    selectedSalesType }) => {
    const [query, setQuery] = useState(null)
    const [pageValue, setPageValue] = useState(btn_tab[0].value)

    const [dateCurrent, setDateCurrent] = useState([(new Date().setHours(0, 0, 0, 10) - 604800000), new Date().setHours(23, 59, 59, 999)])
    const [datePrevious, setDatePrevious] = useState([new Date().setHours(0, 0, 0, 10) - 604800000, new Date().setHours(23, 59, 59, 999)])

    const [fetchedData, setFetchedData] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])
    const [sort, setSort] = useState({})
    const [loading, setLoading] = useState(false)
    const [columnSize, setColumnSize] = useState([])

    const [search, setSearch] = useState('')

    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)
    const [priceRange, setPriceRange] = useState([0, 20000])
    const [segments, setSegments] = useState(1)

    const [minMaxRange, setMinMaxRange] = useState([0, 0])

    const [dateOptions, setDateOptions] = useState([])
    const [selectedDateOptions, setSelectedDateOptions] = useState([])

    const [choisePrice, setChoisePrice] = useState([])
    const [selectedItems, setSelectedItems] = useState([])

    const paginatorRef = useRef()

    const path = useParams()['*']
    const navigate = useNavigate()
    const activeTab = useParams()['*']

    const resetTable = () => {
        paginatorRef.current.reset()
        paginatorRef.current.setTotal(0)
        setSort({})
        setTableHeaders([])
        setFetchedData([])
    }

    const fetchAllAccountData = (params) => {
        if (currentCategory) {
            let sort;
            let filters;

            if (params) {
                [sort, filters] = params
            }
            setLoading(true)

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${AndreySkuHttps}/api/v2/gtcom/niche_search/${pageValue}/table/`
            } else {
                url = `${AndreySkuHttp}/api/v2/gtcom/niche_search/${pageValue}/table/`
            }

            let [date_from, date_to] = date
            date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const price = pageValue == 'price_segment'
                ?
                {
                    max_price: priceRange[1],
                    min_price: priceRange[0],
                    number_of_segments: +segments
                }
                : {}

            const periods = pageValue == 'periods_compare' && {
                date_period_1: `${dayjs(datePrevious[0]).format('YYYY-MM-DD')} - ${dayjs(datePrevious[1]).format('YYYY-MM-DD')}`,
                date_period_2: `${dayjs(dateCurrent[0]).format('YYYY-MM-DD')} - ${dayjs(dateCurrent[1]).format('YYYY-MM-DD')}`,
            }

            const body = JSON.stringify({
                fbofbs_filter: checkFbs,
                category_id_filter: currentCategory,
                limit: paginatorRef.current.limit,
                page: paginatorRef.current.page,
                sort_name: sort?.[0],
                sort_order: sort?.[1],
                filters,
                date_from,
                date_to,
                ...price,
                ...periods
            })


            fetch(url, { body, method: 'POST', headers })
                .then(res => res.json())
                .then(json => {
                    const { data, labels, total, sort, column_size } = json
                    if (total) paginatorRef.current.setTotal(total)
                    if (sort) setSort(sort)
                    if (labels) setTableHeaders(labels)
                    if (column_size) setColumnSize(column_size)
                    setFetchedData(data?.length > 0 ? data : [[]])
                    setTableUrl(url)
                    setTableBody(body)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => setLoading(false))
        }
    }

    const fetchMinMaxPrice = () => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${AndreySkuHttps}/api/v2/gtcom/niche_search/price_range/`
        } else {
            url = `${AndreySkuHttp}/api/v2/gtcom/niche_search/price_range/`
        }

        let [date_from, date_to] = date
        date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0]
        date_to = new Date(date_to)?.toISOString().split('T')?.[0]

        const fbofbs_filter = { fbofbs_filter: checkFbs }

        const body = JSON.stringify({
            ...fbofbs_filter,
            category_id_filter: currentCategory,
            date_from,
            date_to
        })
        fetch(url, { body: body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                if (json) {
                    let { min_price, max_price } = json
                    setMinMaxRange([min_price, max_price])
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }

    const handleCheck = (data) => {
        setSelectedItems(data)
    }

    const onAction = (e) => {
        setChoisePrice(e?.category_name_filter)
    }

    // useEffect(() => {
    //     const params = new URL(document.location).searchParams
    //     let query = params.get("mp")
    //     setQuery(query)

    // }, [pageValue, new URL(document.location)]);

    useEffect(() => {
        if (pageValue == 'price_segment') {
            fetchMinMaxPrice()
        }
    }, [pageValue, selectedSalesType, selectedDateOptions]);

    useEffect(() => {
        // setPageValue(activeTab)
        resetTable()
    }, [activeTab])

    useEffect(() => {
        const abortController = new AbortController()
        if (pageValue !== 'price_segment') {
            fetchAllAccountData([], abortController)
        }
        return () => abortController.abort()
    }, [pageValue, dateCurrent, datePrevious])

    useEffect(() => {
        resetTable()
    }, [pageValue]);


    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-analysis-platform-by-sale-type'>
                <h1 className='title_main'>Analysis: {currentBreadcrumbs}</h1>

                <div className='analysis-platform-by-sale-type'>

                    {
                        pageValue == 'periods_compare' ?
                            <RangeBoxDoublePeriod
                                dateCurrent={dateCurrent}
                                datePrevious={datePrevious}
                                setDateCurrent={setDateCurrent}
                                setDatePrevious={setDatePrevious}
                                lang='en'
                            />
                            :
                            <>
                            </>
                    }


                    <div className='toolbar-medium'>
                        <Tabs
                            stopRedirect
                            tabs={btn_tab}
                            pageValue={pageValue}
                            setPageValue={(e) => setPageValue(e)}
                        />

                    </div>

                    {pageValue == "price_segment" && <SliderRange
                        segments={segments}
                        minValue={minMaxRange[0]}
                        maxValue={minMaxRange[1]}
                        setState={setPriceRange}
                        sliderRange={priceRange}
                        setSegments={e => setSegments(e)}
                        range={priceRange}
                        minMax
                        callbackHandler={fetchAllAccountData}
                        lang='en'
                    />}

                    <TableBox
                        loading={loading}
                        fetchedData={fetchedData}
                        headers={tableHeaders}
                        sort={sort}
                        paginator={true}
                        onAction={(e) => onAction(e)}
                        onCheck={e => handleCheck(e)}
                        onUncheckAllDependence={[pageValue, search]}
                        fetchCallback={e => fetchAllAccountData(e)}
                        sortingFunc={e => fetchAllAccountData(e)}
                        ref={paginatorRef}
                        onEdit={e => (e)}
                        onDelete={e => (e)}
                        isInsideTable={false}
                        newTable={true}
                        columnSize={columnSize}
                        tableUrl={tableUrl}
                        tableBody={tableBody}
                        lang='en'
                    />
                </div>
            </div>
        </div>

    )
}

export { ModalNiche }