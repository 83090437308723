// import customHeaders, {getSpace} from '../../common/headers';
import headers from '../../common/headers';
import add_icon from './img/add.svg'
import { useEffect, useState } from "react";
import { ButtonBasic, TableBox } from "@lk-gtcom/ecomlab-components";
import { ArtemBilling2Https, ArtemBilling2Http } from "../../fetchUrls";
import AddBalanceModal from '../../components/AddBalanceModal';
import initTranslations from "../../i18n"
import useGeneralStore from '../../store/general';
import './Payment.scss'

const namespaces = ['Payment']

const Payment = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    // const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [isModalBalance, setIsModalBalance] = useState(false)
    const [balance, setBalance] = useState(0)
    const [currency, setCurrency] = useState('₽')

    const [i18n, seti18n, ready] = useState({}, { useSuspense: false })

    const translate = i18n?.t
    const t = key => {
        if (typeof translate === 'function') {
            return translate(key)
        }
    }

    const initTranslation = async () => {
        const { i18n } = await initTranslations('en', namespaces)
        seti18n(i18n)
    }

    useEffect(() => {
        initTranslation()
    }, []);

    const fetchBalanceCount = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemBilling2Https}/balance`
        } else {
            url = `${ArtemBilling2Http}/balance`
        }

        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(({ balance, currency }) => {
                setBalance(balance?.toLocaleString())
                setCurrency(currency === 'RUB' ? '₽' : currency)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        fetchBalanceCount()
    }, [selectedSpace])

    return (
        <div className='payment'>
            {
                isModalBalance && 
                    <AddBalanceModal 
                        setIsModalActive={e => setIsModalBalance(false)} 
                    />
            }
            <div className='payment__header'>
                <h1 className='payment__title'>{t('Payment:Payment')}</h1>

            </div>

            <div className="payment__content">
                <div className="payment__balance">
                    <h3 className="payment__title-min">{t('Payment:Balance')}</h3>
                    <div className="payment__balance-content">
                        <p className="payment__sum">{balance} {currency}</p>
                        <ButtonBasic
                            green
                            width='190px'
                            text={<div className="content-btn"><img src={add_icon} alt="icon" />{t('Payment:Top_up_balance')}</div>}
                            onClick={e => setIsModalBalance(true)}
                        />
                    </div>
                </div>

                <div className="payment__promocode">
                    <h3 className="payment__title-min">{t('Payment:promo_code')}</h3>

                    <div className="payment__promocode-content">
                        <span className="payment__placeholder">{t('Payment:Enter_code')}</span>
                        <div className="payment__input-box">
                            <input className="payment__input"/>
                            <ButtonBasic
                                width='150px'
                                lilac
                                size='32px'
                                text={<div className="content-btn"><img src={add_icon} alt="icon" />{t('Payment:Activate')}</div>}
                            />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export { Payment }
