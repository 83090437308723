import { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls'
import headers from '../../common/headers'
import { processTokenResponse } from '../../common/authHelper'
import Cookies from 'universal-cookie'

const AuthCheck = () => {
    const params = useParams()
    const navigate = useNavigate()
    const cookies = new Cookies()

    const device_info = window.navigator.userAgent

    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                fontSize: '20px'
            }}
        >
            <span>E-mail confirmed</span>
            <Link to={'/top-categories'} style={{ color: 'blue' }}>Go to main page</Link>
        </div>
    )
}

export { AuthCheck}