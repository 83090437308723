import {create} from 'zustand';

const useModal = create((set) => ({
  isModalActive: false,
  setIsModalActive: (value) => set({ isModalActive: value }),

  isServiceSelected: false,
  setIsServiceSelected: (value) => set({ isServiceSelected: value }),

  isServiceConnected: false,
  setIsServiceConnected: (value) => set({ isServiceConnected: value }),
}));

export default useModal;
