// import customHeaders, {getSpace} from "../../common/headers";
import { useEffect, useState, useRef } from "react";
import { ButtonBasic, TableBox, DropDownSelector, RangeBox } from "@lk-gtcom/ecomlab-components";
import headers from "../../common/headers";
import add_icon from './img/add.svg'
import { KonstantinMasterHttp, KonstantinMasterHttps } from "../../fetchUrls";
import { useDataTable } from "../../common/hooks";
import useGeneralStore from "../../store/general";
import initTranslations from "../../i18n"
import AddBalanceModal from '../../components/AddBalanceModal';
import './FinanceForServices.scss'

const namespaces = ['ByServices']

const operationTypeArr = ['Все', 'Оплата банковской картой', 'Анализ цен на товары', 'Парсинг характеристик товара']


const FinanceForServices = () => {
    const [i18n, seti18n, ready] = useState({}, { useSuspense: false })

    const translate = i18n?.t
    const t = key => {
        if (typeof translate === 'function') {
            return translate(key)
        }
    }

    const initTranslation = async () => {
        const { i18n } = await initTranslations('en', namespaces)
        seti18n(i18n)
    }

    useEffect(() => {
        initTranslation()
    }, []);

    const options_period = [
        {
            label: t('ByServices:days'),
            value: 'День'
        },
        {
            label: 'неделям',
            value: 'Неделя'
        },

        {
            label: 'месяцам',
            value: 'Месяц'
        }
    ]

    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    // const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [selectedPeriod, setSelectedPeriod] = useState({
        label: 'days',
        value: 'День'
    })
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const [operationType, setOpearationType] = useState([])
    const [selectedOperationType, setSelectedOpearationType] = useState('Все')

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs
    const [isModalBalance, setIsModalBalance] = useState(false)


    const fetchTableData = (params, abortController = new AbortController()) => {
        let sort
        let filters

        if (params) {
            [sort, filters] = params
        }

        const [date_from, date_to] = date
        const orderParam = sort?.[0] ? `&order_param=${sort?.[0]}&order_param_direction=${sort?.[1]}` : ''
        const operationType = `&operation_type=${selectedOperationType?.value}`


        setLoading(true)
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinMasterHttps}/finance/by-services/details-by-service?period=${selectedPeriod?.value}${operationType}${orderParam}`
        } else {
            url = `${KonstantinMasterHttp}/finance/by-services/details-by-service?period=${selectedPeriod?.value}${operationType}${orderParam}`
        }


        const body = JSON.stringify({
            date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
            date_to: new Date(date_to)?.toISOString().split('T')?.[0],
            filters,
            limit: paginatorRef.current?.limit,
            page: paginatorRef.current?.page,
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setTable(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })
    }

    const fetchOperationType = (abortController = new AbortController()) => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinMasterHttps}/finance/by-services/operation-types`
        } else {
            url = `${KonstantinMasterHttp}/finance/by-services/operation-types`
        }

        const [date_from, date_to] = date

        const body = JSON.stringify({
            date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
            date_to: new Date(date_to)?.toISOString().split('T')?.[0],
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setOpearationType(json)
                setSelectedOpearationType(json?.[0])
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })
    }

    return (
        <>
            {
                isModalBalance &&
                <AddBalanceModal
                    setIsModalActive={e => setIsModalBalance(false)}
                />
            }

            <div className='finance-for-services'>
                <div className='finance-for-services__header'>
                    <h1 className='finance-for-services__title'>{t('ByServices:By_services')}</h1>
                    <ButtonBasic
                        green
                        width='190px'
                        onClick={(e) => {
                            setIsModalBalance(true)
                        }}
                        text={<div className="content-btn"><img src={add_icon} alt="icon" />{t('ByServices:Top_up_balance')}</div>}
                    />
                </div>

                <div className="finance-for-services__table">
                    <div className="finance-for-services__header-table">
                        {/* <h2 className="finance-for-services__title_period">Детализация по услугам</h2> */}

                        <div className='range-group-btn-and-range-box'>
                            <div className='drop-box operation-type'>
                                <p className='text'>{t('ByServices:Operation_type')}</p>
                                <DropDownSelector
                                    options_prop={operationType}
                                    state={selectedOperationType}
                                    setState={e => setSelectedOpearationType(e)}
                                    defaultValue={operationType?.[0]}
                                    className='connections_page_selector'
                                    isClearable={false}
                                />
                            </div>
                            <div className='drop-box'>
                                <p className='text'>{t('ByServices:By')}</p>
                                <DropDownSelector
                                    options_prop={options_period}
                                    state={selectedPeriod}
                                    setState={e => setSelectedPeriod(e)}
                                    defaultValue={selectedPeriod}
                                    className='connections_page_selector'
                                    isClearable={false}
                                />
                            </div>

                            <RangeBox lang={'en'} setDate={e => setDate(e)} />
                        </div>
                    </div>

                    <TableBox
                        ref={paginatorRef}
                        paginator={true}
                        {...tableProps}

                    />
                </div>
            </div>
        </>
    )
}

export { FinanceForServices }
