import add_icon from './img/add_icon.svg';
// import customHeaders, { getSpace } from '../../../common/headers';
import headers from '../../../common/headers';
import { OlegRulesHttps, RavshanHttps } from '../../../fetchUrls';
import { useEffect, useState } from 'react';
import {
  fetchApiFilter,
  fetchBrandFilter,
  fetchCategoryFilter,
  fetchProductFilter,
} from '../../../common/filtersFunc';
import {
  DropDownSelector,
  ButtonBasic,
  InputDinamycPlaceholder,
  CheckboxToggle,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import settings_icon from './img/settings.svg';
import close_icon from './img/close.svg';
import useGeneralStore from '../../../store/general';
import initTranslations from '../../../i18n';
import './ModalRules.scss';

// Карточка
// status == 'Активно'/ 'Не активно'
const CardDrop = ({ ...props }) => {
  const {
    id,
    name,
    status = false,
    onDragStart,
    onDragOver,
    onStrategieToggle,
    onGetParams,
  } = props;

  return (
    <div
      className="col-item__card"
      draggable={true}
      onDragStart={(e) => onDragStart(props)}
      onDragOver={(e) => onDragOver(e)}
    >
      <p className="text">{name}</p>
      <div className="status-content">
        <button
          className="col-item__btn_settings"
          onClick={(e) => {
            e.stopPropagation();
            onGetParams(id, name, status);
          }}
        >
          <img src={settings_icon} />
        </button>
        <Checkbox
          green
          value={status}
          onChange={(e) => {
            if (onStrategieToggle) {
              onStrategieToggle({ ...props, status: e });
            }
          }}
        />
        {/* <div className={`status ${statusCard(status)}`}>{status}</div> */}
      </div>
    </div>
  );
};

// Колонка
const ColItemDrop = ({
  title = '',
  arr = [],
  onDragStart,
  onDragOver,
  onDrop,
  loading = false,
  onStrategieToggle,
  onGetParams,
}) => {
  return (
    <div className="col-item">
      <div className="col-item__header">
        <h3 className="title">
          {title} <span>{arr?.length ? arr.length : 0}</span>
        </h3>
      </div>
      <div
        className="col-item__list"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => onDrop(e)}
        draggable={true}
      >
        {!loading
          ? arr?.map((props, ind) => {
            // Карточка
            const { id } = props;
            return (
              <CardDrop
                key={id}
                {...props}
                onDragStart={(e) => onDragStart(e)}
                onDragOver={(e) => onDragOver(e)}
                onStrategieToggle={(e) => onStrategieToggle(e)}
                onGetParams={(e) => onGetParams(e)}
              />
            );
          })
          : 'Loading...'}
        {arr?.length < 1 && (
          <div className="no-list">
            <img src={add_icon} />
            <p className="text">Добавьте правило</p>
          </div>
        )}
      </div>
    </div>
  );
};

// Модальное окно
const ModalRules = ({ ...props }) => {
  const {
    setIsModal,
    status,
    rule_id,
    service_id,
    isEdit,
    setIsEdit,
    isCreate,
    setRuleInfo,
    name,
    ruleName,
    handleSaveSuccess,
  } = props;
  const [i18n, seti18n, ready] = useState({}, { useSuspense: false })
  const [isModalBalance, setIsModalBalance] = useState(false)

  const translate = i18n?.t
  const t = key => {
    if (typeof translate === 'function') {
      return translate(key)
    }
  }

  const initTranslation = async () => {
    const { i18n } = await initTranslations('en', ["ModalRules"])
    seti18n(i18n)
  }

  useEffect(() => {
    initTranslation()
  }, []);
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  // const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [edit, setEdit] = useState(isEdit ? isEdit : false);
  const [create, setCreate] = useState(isCreate ? isCreate : false);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;
  const [currentCard, setCurrentCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState([]);
  const [disactive, setDisactive] = useState([]);
  const [isModalParametr, setIsModalParametr] = useState(false);
  const [ruleId, setRuleId] = useState(rule_id ? rule_id : -1);

  const [strategyInfo, setStrategyInfo] = useState(status ? { status } : {});
  const [strategyParams, setStrategyParams] = useState([]);
  const [strategyParamValue, setStrategyParamValue] = useState([]);

  const [filters, setFilters] = useState({
    1: { optionsData: [], selectedData: [], page: 1, search: '' }, // shop
    2: { optionsData: [], selectedData: [], page: 1, search: '' }, // category
    3: { optionsData: [], selectedData: [], page: 1, search: '' }, // brand
    4: { optionsData: [], selectedData: [], page: 1, search: '' }, // product
    5: { optionsData: [], selectedData: [], page: 1, search: '' }, // account
    6: { selectedData: '', search: '' }, // name
  });

  const updateFilter = (id, updates) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [id]: {
        ...prevFilters[id],
        ...updates,
      },
    }));
  };

  const fetchRuleModel = (service_id) => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/rule/model`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        console.log('Rule model:', json);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        console.log('Fetch rule model completed');
      });
  };

  const fetchClientAtrs = (service_id) => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/rule/model`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        console.log('ClientAtrs', json);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        console.log('Fetch rule model completed');
      });
  };

  const fetchRuleAttrsModel = (service_id, rule_id) => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/rule_attrs/model/${rule_id}`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        console.log('Rule attributes model:', json);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        console.log('Fetch rule attributes model completed');
      });
  };

  // const createClientRule = (service_id, ruleData) => {
  //   const url = `${OlegRulesHttps}/api/v1/${service_id}/rule`;

  //   fetch(url, {
  //     method: 'POST',
  //     headers,
  //     body: JSON.stringify(ruleData),
  //   })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res?.json();
  //       } else {
  //         throw new Error(JSON.stringify(res.json()));
  //       }
  //     })
  //     .then((json) => {
  //       console.log('Created client rule:', json);
  //     })
  //     .catch((err) => console.error(err))
  //     .finally(() => {
  //       console.log('Create client rule completed');
  //     });
  // };

  // useEffect(() => {
  //   fetchRuleModel(service_id);
  //   fetchRuleAttrsModel(service_id, rule_id);
  //   fetchClientAtrs(service_id);
  //   // createClientRule(service_id, ruleData);
  // }, []);

  const onCreate = () => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/rule`;

    const id = edit ? { id: ruleId } : { rule_id };

    const body = JSON.stringify({
      ...id,
      is_active: true,
      attributes: [
        {
          attr_id: 1,
          value: {
            type: 'array[string]',
            value: edit
              ? filters[1].selectedData.map((el) => el.value).flat()
              : filters[1].selectedData.map((el) => el.value),
            label: edit
              ? filters[1].selectedData.map((el) => el.label).flat()
              : filters[1].selectedData.map((el) => el.label),
          },
        },
        {
          attr_id: 2,
          value: {
            type: 'array[string]',
            value: edit
              ? filters[2].selectedData.map((el) => el.value).flat()
              : filters[2].selectedData.map((el) => el.value),
            label: edit
              ? filters[2].selectedData.map((el) => el.label).flat()
              : filters[2].selectedData.map((el) => el.label),
          },
        },
        {
          attr_id: 3,
          value: {
            type: 'array[string]',
            value: edit
              ? filters[3].selectedData.map((el) => el.value).flat()
              : filters[3].selectedData.map((el) => el.value),
            label: edit
              ? filters[3].selectedData.map((el) => el.label).flat()
              : filters[3].selectedData.map((el) => el.label),
          },
        },
        {
          attr_id: 4,
          value: {
            type: 'array[string]',
            value: edit
              ? filters[4].selectedData.map((el) => el.value).flat()
              : filters[4].selectedData.map((el) => el.value),
            label: edit
              ? filters[4].selectedData.map((el) => el.label).flat()
              : filters[4].selectedData.map((el) => el.label),
          },
        },
        {
          attr_id: 5,
          value: {
            type: 'array[integer]',
            value: edit
              ? filters[5].selectedData.map((el) => el.value).flat()
              : filters[5].selectedData.map((el) => el.value),
            label: edit
              ? filters[5].selectedData.map((el) => el.label).flat()
              : filters[5].selectedData.map((el) => el.label),
          },
        },
        {
          attr_id: 6,
          value: {
            type: 'string',
            value: filters[6].selectedData,
            label: filters[6].selectedData,
          },
        },
      ],
    });

    fetch(url, {
      body,
      method: edit ? 'PUT' : 'POST',
      headers: headers,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((err) => {
            throw new Error(JSON.stringify(err));
          });
        }
      })
      .then(({ id }) => {
        setCreate(false);
        setRuleId(id);
        setIsEdit(true);
        setEdit(true);
        handleSaveSuccess();
      })
      .catch((err) => console.error('Error:', err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteStrategy = (id) => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/strategy`;

    const body = JSON.stringify({
      id,
    });

    fetch(url, {
      body,
      method: 'DELETE',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then(() => {
        fetchStrategies();
        fetchClientStrategies();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeStrategy = ({ status, id }) => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/strategy`;

    const param = Object.keys(strategyParamValue).map((id) => ({
      attr_id: id,
      value: strategyParamValue[id],
    }));

    const statusCheckBody = status
      ? { strategy_id: id, client_rule_id: ruleId, is_active: true }
      : { id };

    const body = JSON.stringify({
      ...statusCheckBody,
      attributes: param,
    });

    fetch(url, {
      body,
      method: status ? 'POST' : 'PUT',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setStrategyInfo({});
        setStrategyParamValue({});
        fetchStrategies();
        fetchClientStrategies();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchStrategies = () => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/strategy/model/${ruleId}/list`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setDisactive(json?.map((el) => ({ ...el, status: false })));
        // setActive(active?.map(el => ({ ...el, status: true })))
        // setDisactive(disactive?.map(el => ({ ...el, status: false })))
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchClientStrategies = () => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/strategy/client/${ruleId}/list`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setActive(json?.map((el) => ({ ...el, status: true })));
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onGetParams = async (id, name, status) => {
    setStrategyParams([]);
    setStrategyParamValue([]);

    let url;
    if (status) {
      url = `${OlegRulesHttps}/api/v1/${service_id}/strategy_attrs/${status ? 'model' : 'client'
        }/${id}`;
    } else {
      url = `${OlegRulesHttps}/api/v1/${service_id}/strategy_attrs/${status ? 'model' : 'client'
        }/${id}`;
    }

    await fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        if (json?.length > 0) {
          setStrategyInfo({ name, status, id });
          setStrategyParams(json);
        } else {
          setStrategyInfo({
            name: 'Для этой стратегии нет параметров, она будет применена для всех товаров',
            status,
            id,
          });
        }
        setIsModalParametr(true);
      })
      .catch((err) => {
        setStrategyParams([]);
        setStrategyInfo({
          name: 'Для этой стратегии нет параметров, она будет применена для всех товаров',
          status,
          id,
        });
        setIsModalParametr(true);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onGetRuleParams = () => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/rule_attrs/client/${ruleId}`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        json.forEach((data) => {
          const filterName = data?.name;
          console.log(data, 'sjop');
          switch (filterName) {
            case ' shop':
              updateFilter(1, {
                selectedData: [
                  {
                    label: data?.value?.label,
                    ...data?.value,
                  },
                ],
              });
              return;
            case 'category':
              updateFilter(2, {
                selectedData: [
                  {
                    label: data?.value?.label,
                    ...data?.value,
                  },
                ],
              });
              return;
            case 'brand':
              updateFilter(3, {
                selectedData: [
                  {
                    label: data?.value?.label,
                    ...data?.value,
                  },
                ],
              });
              return;
            case 'product':
              updateFilter(4, {
                selectedData: [
                  {
                    label: data?.value?.label,
                    ...data?.value,
                  },
                ],
              });
              return;
            case 'account':
              updateFilter(5, {
                selectedData: [
                  {
                    label: data?.value?.label,
                    ...data?.value,
                  },
                ],
              });
              return;
            case 'name':
              updateFilter(6, { selectedData: data.value.value });
              return;
            default:
              return;
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getClientParams = async (id, name, status) => {
    await onGetParams(id, name, status);
    // onGetStrategyParams(id, name, status)
  };

  const onReset = () => {
    setRuleInfo({});
    setStrategyInfo({});
    setStrategyParamValue({});
    setStrategyParams([]);
    setRuleId(1);
    setFilters({
      1: { optionsData: [], selectedData: [], page: 1, search: '' },
      2: { optionsData: [], selectedData: [], page: 1, search: '' },
      3: { optionsData: [], selectedData: [], page: 1, search: '' },
      4: { optionsData: [], selectedData: [], page: 1, search: '' },
      5: { optionsData: [], selectedData: [], page: 1, search: '' },
      6: { selectedData: '', search: '' },
    });
  };

  const onStrategieToggle = (props) => {
    const { id, name, status, param } = props;
    if (!status) {
      onDeleteStrategy(id);
    } else {
      setStrategyInfo({ name, status, id });
      onGetParams(id, name, status);
    }
  };

  const dragStartHandler = (e, card) => {
    // Перетаскиваемая карточка
    setCurrentCard(e);
  };

  const dragOverHandler = (e, card) => {
    e.preventDefault();
  };

  const dropHandler = (e, status) => {
    e.preventDefault();
    if (!currentCard) return;
    onStrategieToggle({ ...currentCard, status });
  };

  const fetchAccounts = (signal) => {
    fetch(`${RavshanHttps}/api/social/get_accounts`, {
      method: 'GET',
      headers,
      signal,
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('bad response');
        }
        return res.json();
      })
      .then((json) => {
        updateFilter(5, {
          optionsData: json.map(({ id, name }) => ({ label: name, value: id })),
        });
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: filters[1].page,
      setApiData: (data) => {
        updateFilter(1, { optionsData: data });
      },
      searchParam: filters[1].search,
      withAll: true,
    });
  }, [filters[1].page, filters[1].search]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: filters[2].page,
      apiData: filters[1].optionsData,
      dateFrom,
      dateTo,
      selectedApi: filters[1].selectedData,
      setCategoryData: (data) => updateFilter(2, { optionsData: data }),
      searchParam: filters[2].search,
    });
  }, [
    filters[1].optionsData,
    filters[1].selectedData,
    filters[2].page,
    filters[2].search,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: filters[3].page,
      apiData: filters[1].optionsData,
      categoryData: filters[2].optionsData,
      selectedCategory: filters[2].selectedData,
      searchParam: filters[3].search,
      selectedApi: filters[1].selectedData,
      setBrandData: (data) => updateFilter(3, { optionsData: data }),
    });
  }, [
    filters[1].optionsData,
    filters[2].optionsData,
    filters[2].selectedData,
    filters[3].page,
    filters[3].search,
  ]);

  useEffect(() => {
    fetchProductFilter({
      selectedSpace: selectedSpace?.value,
      page: filters[4].page,
      apiData: filters[1].optionsData,
      categoryData: filters[2].optionsData,
      brandData: filters[3].optionsData,
      selectedCategory: filters[2].selectedData,
      selectedBrand: filters[3].selectedData,
      selectedApi: filters[1].selectedData,
      searchParam: filters[4].search,
      setProductsData: (data) => updateFilter(4, { optionsData: data }),
    });
  }, [
    filters[1].optionsData,
    filters[2].optionsData,
    filters[3].optionsData,
    filters[4].page,
    filters[4].search,
  ]);

  useEffect(() => {
    if (!edit) {
      onReset();
    }
    fetchAccounts();
  }, [selectedSpace]);

  useEffect(() => {
    if (edit) {
      onGetRuleParams();
      // setTitle(ruleName);
      updateFilter(6, { selectedData: ruleName });
    }
  }, [edit, selectedSpace]);

  useEffect(() => {
    if (edit) {
      fetchStrategies();
      fetchClientStrategies();
    }
  }, [ruleId, selectedSpace]);

  return (
    <div
      style={{ overflow: 'hidden' }}
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
          setIsEdit(false);
          setEdit(false);
          onReset();
        }
      }}
    >
      <div
        className="modal-promotion-rules container"
        onClick={(e) => {
          let el = e.target;
          if (!el.closest('.promotion-rules-parametr')) {
            setIsModalParametr(false);
            // onChangeStrategy()
          }
        }}
      >
        <div className="modal-promotion-rules__header">
          <h1 className="title">
            {edit ? t('ModalRules:Editing_rule') : t('ModalRules:Create_rule')}
          </h1>
          <InputDinamycPlaceholder
            onChange={(e) => updateFilter(6, { selectedData: e })}
            textErr={'Неверное название правила'}
            placeholder={t('ModalRules:Rule_name')}
            changeValue={filters[6].selectedData ? filters[6].selectedData : ''}
            autoComplete={false}
            style={{ maxWidth: '500px', minWidth: '500px' }}
          />
          <div
            className="modal-promotion-rules__btn-close"
            onClick={(e) => {
              setIsModal(false);
              setIsEdit(false);
              setEdit(false);
              onReset();
            }}
          >
            <img src={close_icon} />
          </div>
        </div>

        <div className="modal-promotion-rules__toolbar-top">
          <div className="modal-promotion-rules__toogle-checkbox">
            <p className="modal-promotion-rules__text_label">
              {t('ModalRules:Rule')}:
              {/* класс для красного цвета */}
              {/* modal-promotion-rules__text_status_disactive */}
              <span className="modal-promotion-rules__text_status_active">
                {' '}
                {t('ModalRules:Active')}
              </span>
            </p>
            <CheckboxToggle checked />
            <p style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              {t('ModalRules:Where_to_notify')}
              <DropDownSelector
                style={{ maxWidth: '210px' }}
                options_prop={filters[5].optionsData}
                state={filters[5].selectedData}
                setState={(e) => updateFilter(5, { selectedData: e })}
                className="connections_page_selector"
                placeholder={t('ModalRules:Accounts')}
                multi
              />
            </p>
          </div>

          <ButtonBasic
            disabled={
              !(
                (filters[6].selectedData?.length > 0) &
                (filters[1].selectedData?.length > 0)
              )
            }
            green
            minWidth="172px"
            width="172px"
            maxWidth="172px"
            text={create ? t('ModalRules:Create') : 'Сохранить'}
            style={{ height: '100%' }}
            onClick={(e) => {
              onCreate();
              if (!create) {
                onReset();
                setIsModal(false);
              }
            }}
          />
        </div>

        <div className="modal-promotion-rules__toolbar-medium">
          <DropDownSelector
            style={{ maxWidth: '210px' }}
            options_prop={filters[1].optionsData}
            state={filters[1].selectedData}
            setState={(e) => updateFilter(1, { selectedData: e })}
            className="connections_page_selector"
            placeholder={t('ModalRules:Shop')}
            fetchCallback={(e) => {
              updateFilter(1, { page: e });
            }}
            setInputChange={(e) => updateFilter(1, { search: e })}
            multi
          />
          <DropDownSelector
            style={{ maxWidth: '210px' }}
            options_prop={filters[2].optionsData}
            state={filters[2].selectedData}
            setState={(e) => updateFilter(2, { selectedData: e })}
            className="connections_page_selector"
            placeholder={t('ModalRules:Category')}
            fetchCallback={(e) => {
              updateFilter(2, { page: e });
            }}
            setInputChange={(e) => updateFilter(2, { search: e })}
            multi
          />
          <DropDownSelector
            style={{ maxWidth: '210px' }}
            options_prop={filters[3].optionsData}
            state={filters[3].selectedData}
            setState={(e) => updateFilter(3, { selectedData: e })}
            className="connections_page_selector"
            placeholder={t('ModalRules:Brand')}
            fetchCallback={(e) => updateFilter(3, { page: e })}
            setInputChange={(e) => updateFilter(3, { search: e })}
            multi
          />
          <DropDownSelector
            style={{ maxWidth: '210px' }}
            options_prop={filters[4].optionsData}
            state={filters[4].selectedData}
            setState={(e) => updateFilter(4, { selectedData: e })}
            className="connections_page_selector"
            placeholder={t('ModalRules:Products')}
            fetchCallback={(e) => updateFilter(4, { page: e })}
            setInputChange={(e) => updateFilter(4, { search: e })}
            multi
          />
        </div>

        <div className="modal-promotion-rules__content">
          <div className="rules-content">
            {!create || isEdit ? (
              <section className="rules-content__content">
                <div className="rules-content__tooltip">
                  Активируйте нужное правило переключателем или просто
                  перенесетие его вправо
                  <CheckboxToggle checked />
                </div>
                <div className="column-group">
                  <ColItemDrop
                    title="Доступные"
                    loading={loading}
                    arr={disactive}
                    onDragStart={(e) => dragStartHandler(e)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dropHandler(e, false)}
                    onStrategieToggle={(e) => onStrategieToggle(e)}
                    onGetParams={(e) => getClientParams(e)}
                  />

                  <ColItemDrop
                    title="Активные"
                    arr={active}
                    loading={loading}
                    onDragStart={(e) => dragStartHandler(e)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dropHandler(e, true)}
                    onStrategieToggle={(e) => onStrategieToggle(e)}
                    onGetParams={(e) => getClientParams(e)}
                  />
                </div>
              </section>
            ) : null}
          </div>
        </div>

        {isModalParametr && (
          <div className="promotion-rules-parametr">
            <div className="promotion-rules-parametr__header">
              <h1 className="title_main">Параметры</h1>
            </div>

            <div className="promotion-rules-parametr__content">
              <h1 className="title">
                {strategyInfo?.name ? strategyInfo?.name : 'Название стратегии'}
              </h1>
              {strategyParams?.map(
                ({
                  portal_name,
                  name,
                  type,
                  description,
                  id,
                  attr_id,
                  value = '',
                }) => {
                  return (
                    <InputDinamycPlaceholder
                      onChange={(e) => {
                        if (e) {
                          setStrategyParamValue((prev) => {
                            let paramValue = value ? value : value?.value;
                            let paramType = type ? type : value?.type;

                            if (paramType === 'integer') {
                              paramValue = Number(e);
                            }
                            if (paramType === 'string') {
                              paramValue = String(e);
                            }

                            return {
                              ...prev,
                              [+id ? +id : attr_id]: {
                                type: paramType,
                                value: paramValue,
                              },
                            };
                          });
                        }
                      }}
                      placeholder={portal_name ? portal_name : name ? name : ''}
                      autoComplete={false}
                      changeValue={value?.value ? String(value?.value) : value}
                      size="s"
                    />
                  );
                }
              )}
              <ButtonBasic
                grey
                width="100%"
                text={strategyParams?.length < 1 ? 'ОК' : 'Сохранить'}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalParametr(false);
                  onChangeStrategy(strategyInfo);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { ModalRules };
