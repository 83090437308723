import { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { LogoBox } from '../Logo/Logo'
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls'
import done from './img/done-green.svg'
import { processTokenResponse } from '../../common/authHelper'
import Cookies from 'universal-cookie'
import './AcceptMail.scss'

const AcceptMail = () => {
    const params = useParams()

    const device_info = window.navigator.userAgent

    useEffect(() => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/gtcom/web/verify-email`
        } else {
            url = `${KonstantionAuthHttp}/gtcom/web/verify-email`
        }

        const body = JSON.stringify({
            token: params.id,
            device_info
        })

        fetch(url,
            {
                body,
                method: 'POST',
                headers: { 'Content-type': 'application/json' }
            })
            .then(res => res.json())
            .then(async json => {
                if (json.detail == 'VERIFY_USER_BAD_TOKEN') {
                    window.location.assign(`/auth`)
                } else {
                    processTokenResponse(json, false, true)
                }
            })
            .catch(err => {
                console.log(err)
                window.location.assign(`/auth`)
            })
    }, [])

    return (
        <div className='autorization__content'>

            <header className='autorization__header'>
                < LogoBox />
            </header>

            <div className='accept-mail'>
                <img src={done} />
                <h2 className='title'>Thank you for registration</h2>
                <p className='text_grey-blue'>Now you can use your account</p>
                <Link className='link__blue' to="/top-categories">Go to the main page</Link>
            </div>
        </div>
    )
}

export { AcceptMail }