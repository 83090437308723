import Slider from 'rc-slider';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { useState, useEffect } from 'react';
import './Slider.scss'
import 'rc-slider/assets/index.css';

const SliderRange = ({ minValue, maxValue, minMax = true, defaultValue = false, step, setState, segments = false, setSegments, callbackHandler, lang = 'ru' }) => {

  const [min, setMin] = useState(minValue ? minValue : 0)
  const [max, setMax] = useState(maxValue ? maxValue : 20000)

  const [sliderRange, setSliderRange] = useState(defaultValue ? defaultValue : [min, max])

  useEffect(() => {
    if (setState) {
      setState(sliderRange)
    }
  }, [sliderRange]);

  useEffect(() => {
    setMax(maxValue)
    setMin(minValue)
    setSliderRange([minValue, maxValue])
  }, [minValue, maxValue])


  console.log(segments)

  return (
    <section className='slider-panel'>
      <div className='slider-box'>
        {minMax && <input
          className='slider-num'
          min={0}
          placeholder={min}
          value={sliderRange[0]}
          type='number'
          onChange={e => {
            const prev = sliderRange[1]
            setSliderRange([e.target.value, prev])
          }}
        />}
        <Slider
          defaultValue={defaultValue ? defaultValue : []}
          min={min}
          max={max}
          value={sliderRange}
          onChange={(e) => setSliderRange(e)}
          marks={{}}
          step={step ? step : false}
          draggableTrack
          // allowCross={false}
          pushable
          range />

        {minMax && <input
          className='slider-num'
          value={sliderRange[1]}
          placeholder={max}
          type='number'
          onChange={e => {
            const prev = sliderRange[0]
            setSliderRange([prev, e.target.value])
          }}
        />}

      </div>

      {minMax && <div className='input-segment-box'>
        <div className='input-box'>
          <p className='text_grey'>{lang === 'ru' ? 'Сегментов' : 'Segments'}:</p>
          <input
            defaultValue={segments ? segments : 1}
            value={segments}
            onChange={e => {
              if(e.target.value > 0) {
                setSegments(e.target.value)
              } else {
                setSegments(1)
              }
            }}
            // max={max}
            min={1}
            step={1}
            placeholder='0'
            type='number' />
          <div className='btn-group'>
            <button className='btn_top'></button>
            <button className='btn_bottom'></button>
          </div>
        </div>
        <ButtonBasic
          blue
          text={lang === 'ru' ? 'Применить' : 'Confirm'}
          width='88px'
          size='40px'
          onClick={e => {
            if (callbackHandler) {
              callbackHandler()
            }
          }}
        />
      </div>}
    </section>
  );

}

export { SliderRange }