import { useEffect, useState } from 'react'
import eye from './img/eye_icon.svg'
import drr from './img/drr_icon.svg'
import max from './img/max_icon.svg'
import arrows from './img/arrows_icon.svg'
import traffic from './img/traffic_icon.svg'
import { ButtonBasic } from '../../Buttons/ButtonBasic/ButtonBasic'
import './ModalAddNotificationMethod.scss'

const ModalAddNotificationMethod = ({ setIsModal, setConnectedModal }) => {

    const [currentNotification, setCurrentNotification] = useState(null)
    const arrIcons = [eye, traffic, max, drr, arrows]

    const social = [
        { label: 'Viber' },
        { label: 'WhatsApp' }
    ]

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-add-notification-method'>
                <h2 className='title'>Add notification method</h2>
                <input className='input-field' placeholder='Search service' />

                <div className='modal-add-notification-method__content'>
                    {
                        social.map(({ label }, ind) => {
                            return (
                                <div
                                    className={label === currentNotification ? 'notification-item_active' : 'notification-item'} key={label + ind}
                                    onClick={(e) => setCurrentNotification(label)}
                                >
                                    <img src={arrIcons[ind]} />
                                    <p className='text'>{label}</p>
                                </div>
                            )
                        })
                    }
                </div>

                {currentNotification && <ButtonBasic
                    text='Add'
                    maxWidth='calc(560px - 48px)'
                    minWidth='200px'
                    size='56px'
                    width='100%'
                    style={{
                        marginLeft: '24px',
                        marginRight: '24px'
                    }}
                />}
                <p className='text_grey-blue'>Diid not find the right service? <a className='link__blue' href='#'> Write us </a> </p>
            </div>
        </div>
    );
}

export { ModalAddNotificationMethod };