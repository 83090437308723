import customHeaders, {getSpace} from '../../../common/headers'
import AccAuthBlock from './AccAuthBlock/';
import { ConnectionsContext } from '../../../App';
import { OlegHttps, RavshanHttps, RavshanHttp, RavshanArticlesHttps } from '../../../fetchUrls';
import { InputDinamycPlaceholder, ButtonBasic, ArticleView, DropDownSelector } from "@lk-gtcom/ecomlab-components"
import { useState, useEffect, useContext, useRef } from 'react';
import useGeneralStore from '../../../store/general';
import initTranslations from '../../../i18n';
import './ModalAddConnection.scss'

const ModalAddConnection = ({
    fetchedServiceData,
    setIsServiceConnected,
    loading,
    setLoading,
    button,
    setConnectedModal,
    setIsModalActive,
    backBtnHandler,
    dispatch,
    setButton,
    setSelectedSpace,
    optionList
}) => {
    const [i18n, seti18n, ready] = useState({}, { useSuspense: false })
  
    const translate = i18n?.t
    const t = key => {
        if (typeof translate === 'function') {
            return translate(key)
        }
    }
  
    const initTranslation = async () => {
        const { i18n } = await initTranslations('en', ["Modal"])
        seti18n(i18n)
    }
  
    useEffect(() => {
        initTranslation()
    }, []);
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [error, setError] = useState(false)

    const [serviceName, setServiceName] = useState('')
    const [marketplaceId, setMarketplaceId] = useState([])
    const [attrArr, setAttrArr] = useState([])
    const [data, setData] = useState({})
    const [isEmptyRequired, setIsEmptyReqired] = useState({})

    const [selectedName, setSelectedName] = useState('')
    const [accAuthBlocksList, setAccAuthBlocksList] = useState([])
    const [fetchInProgress, setFetchInProgress] = useState(false)
    const { editableConnection } = useContext(ConnectionsContext)
    const [additionalServiceIds, setAdditionalServiceIds] = useState([])
    const [additionalFieldArrayIndex, setAdditionalFieldArrayIndex] = useState(0)
    const [showNoAdditionalFieldMessage, setShowNoAdditionalFieldMessage] = useState(false)
    const prevFetchedServiceData = useRef(null)

    const [contentData, setContentData] = useState([])
    const [apiData, setApiData] = useState([])

    const isEditAccountData = editableConnection?.accountId !== 0

    const mapServiceData = (serviceData, additional) => {

        let wb_index = -1
        let wb_index_ads = -1
        if (serviceData?.attr_name) {
            wb_index = serviceData?.attr_name?.indexOf('внутренний api_id')
            wb_index_ads = serviceData?.attr_name?.indexOf('внутренний api_id_ads')

            Object.keys(serviceData)?.forEach((el) => {
                if (wb_index !== -1) {
                    serviceData = { ...serviceData, [el]: serviceData[el]?.filter((el, ind) => ind !== wb_index) }
                }

                if (wb_index_ads !== -1) {
                    serviceData = { ...serviceData, [el]: serviceData[el]?.filter((el, ind) => ind !== wb_index_ads) }
                }
            })
        }

        const {
            attr_name,
            attribute_name,
            description,
            mp_id,
            mp_name,
            service_id,
            attribute_value,
            required
        } = serviceData



        if (Object.keys(serviceData).length > 0) {

            setServiceName(mp_name[0])
            if (additional) {
                setAdditionalServiceIds(service_id)
                setAdditionalFieldArrayIndex(prev => prev + 1)
            }
            setAttrArr(prev => {
                return [
                    ...prev,
                    ...attr_name?.map((el, ind) =>
                    ({
                        attr_name: attr_name?.[ind],
                        attribute_name: attribute_name?.[ind],
                        description: description?.[ind],
                        service_id: `${service_id[ind]}${additional ? `_${additionalFieldArrayIndex}` : ''}`,
                        mp_name: mp_name?.[ind],
                        mp_id,
                        attribute_value: attribute_value ? attribute_value?.[ind] : '',
                        required: required?.[ind]
                    }))
                ]
            }
            )

            if (!additional) {
                for (const key of service_id) {
                    setData({
                        ...data,
                        [key.toString()]: ''
                    })
                }
            }

            setMarketplaceId([...mp_id])
        }
    }

    if (prevFetchedServiceData.current !== fetchedServiceData) {
        mapServiceData(fetchedServiceData)
        prevFetchedServiceData.current = fetchedServiceData
    }

    const fetchFilterAndServiceData = () => {
        setLoading(true)
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/service/post_account_service_data`
        } else {
            url = `${RavshanHttps}/api/service/post_account_service_data`
        }

        const body = JSON.stringify({
            account_name: selectedName,
            marketplace_id: marketplaceId[0],
            service: data,
            space_id_list: selectedSpace?.value ? [selectedSpace?.value] : []
        })

        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setLoading(false)
                setIsServiceConnected(true)
            })
            .catch(err => {
                console.error(err)
                setError(true)
            })
            .finally(() => setLoading(false))
    }

    const onEditAccountData = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/service/put_account_service_data`
        } else {
            url = `${RavshanHttp}/api/service/put_account_service_data`
        }

        const { accountId, name } = editableConnection

        let processedData = data
        if (additionalServiceIds.length > 0) {
            additionalServiceIds.forEach(service_id => {
                const additionalData = []
                const additionalFields = Object.keys(data).filter(id => id.startsWith(service_id))
                additionalFields.forEach(field => {
                    additionalData.push(data[field])
                    delete processedData[field]
                })
                processedData[service_id] = additionalData
            })
        }

        const body = JSON.stringify({
            account_id: accountId,
            name,
            service: processedData,
            space_id_list: selectedSpace?.value ? [selectedSpace?.value] : []
        })

        fetch(url, { body, method: 'PUT', headers })
            .then(res => res.json())
            .then(json => {
                const {
                    name,
                } = json
                setIsModalActive(false)
                setConnectedModal(false)
            })
            .catch(err => {
                console.error(err)
                setError(true)
            })
            .finally(() => setLoading(false))
    }

    const fetchAdditionalData = () => {
        setFetchInProgress(true)

        const url = `${OlegHttps}/api/v1/account/info/create/additional`
        const body = JSON.stringify({
            account_id: editableConnection?.accountId
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (res.status === 204) {
                    setShowNoAdditionalFieldMessage(true)
                    return
                }
                return res.json()
            })
            .then(json => {
                if (json) mapServiceData(json, true)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setFetchInProgress(false))
    }

    const onSubmitClick = () => {
        const isEmpty = Object.values(isEmptyRequired)?.filter(el => !!el)
        if (selectedName !== '' & isEmpty?.length === 0) {
            if (isEditAccountData) {
                onEditAccountData()
            } else {
                fetchFilterAndServiceData()
            }
        } else {
            setError(true)
        }
    }


    const fetchDataById = () => {
        if (fetchedServiceData?.article_id) {
            setLoading(true)
            const url = `${RavshanArticlesHttps}/get_article`
            const headers = {
                'article-id': fetchedServiceData?.article_id,
                'Accept': 'application/json'
            }
            fetch(url, { headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setApiData(json)
                })
                .catch(error => console.error(error))
                .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        if (isEditAccountData) {
            setSelectedName(editableConnection?.name)
            mapServiceData(editableConnection?.serviceData)
        }
        fetchDataById()
    }, [])

    useEffect(() => {
        setAccAuthBlocksList(attrArr.map((el, ind) =>
            <AccAuthBlock key={ind + el.input_name + el.name_for_portal} {...el} error={error} setData={e => setData(e)} setIsEmptyReqired={e => setIsEmptyReqired(e)} />
        ))
    }, [attrArr, error])


    return (
        <div className='modal-add-connection-data'>
            <div className='left-content'>
                <header className='left-content__header'>
                    <button className="btn-back"
                        onClick={e => {
                            setButton(null)
                            backBtnHandler()
                            dispatch({ type: 'reset' })
                        }}
                    />
                    <h2 className='title'>{isEditAccountData ? t('Modal:Make_changes_to_your_connection') : t('Modal:Enter_connection')}</h2>
                    <ButtonBasic
                        green
                        style={{ whiteSpace: 'nowrap' }}
                        width='fit-content'
                        size='40px'
                        text={isEditAccountData ? t('Modal:Değiştir') : t('Modal:Add_connections')}
                        onClick={(e) => onSubmitClick(e)}
                    />
                </header>
                <div className='left-content__content'>
                    <DropDownSelector
                        options_prop={optionList}
                        state={selectedSpace}
                        setState={e => setSelectedSpace(e)}
                        placeholder={t('Modal:Selecting_space')}
                        multi={false}
                        width='100%'
                    />

                    {button && <div className='acc-auth__block' style={{ marginBottom: '1em' }}>
                        {button}
                    </div>}

                    <p class="btn__social-media_text"
                    >{t('Modal:Manual_input_connection')}:</p>
                    <div className='left-content__inputs'>

                        <InputDinamycPlaceholder
                            changeValue={editableConnection?.name ? editableConnection?.name : ''}
                            onChange={e => setSelectedName(e)}
                            type="text"
                            classname='autorization__input-field'
                            placeholder={t('Modal:Connection_name')}
                            autoComplete={false}
                            err={error & selectedName?.length < 1}
                            textErr={t('Modal:field_required')}
                            disabled={isEditAccountData}
                        />

                        {accAuthBlocksList}
                    </div>




                    {
                        isEditAccountData
                        &&
                        <div className='additional_section'>
                            <button
                                className='btn_additional btn__green-and-white'
                                disabled={fetchInProgress || showNoAdditionalFieldMessage}
                                onClick={fetchAdditionalData}
                            >
                                +
                            </button>
                            {showNoAdditionalFieldMessage && <span className='error-text'>{t('Modal:No_additional_connection_this_service')}</span>}
                        </div>
                    }
                    {error ? <span className='error-text'>{t('Modal:fields_completed')}</span> : null}
                </div>
            </div>
            <div className='right-content' style={{ padding: '1em', boxSizing: 'border-box', overflowY: 'overlay' }}>
                {
                    apiData?.id ?
                        <ArticleView
                            articleTitle={apiData?.name}
                            portalId={1}
                            readonly={true}
                            apiData={apiData}
                            contentData={contentData}
                            setContentData={setContentData}
                            setListOfContent={() => { }}
                            hideInfoPanel
                            hideRecommendation
                        />
                        :
                        <h1>{t('Modal:Description_is_not_available')}</h1>
                }
            </div>
        </div>
    )
}

export { ModalAddConnection }