import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import banner from  './img/banner.svg'
import "./NotActive.scss"

const NotActive = () => {

    return (
        <div className='not-active'>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <h2 className='title'>This section is under development, please check back later</h2>
            <embed type='image/svg+xml' src={banner} />
       </div>
    )
}

export { NotActive }