import { Link } from 'react-router-dom'
import bkg_1 from './img/bkg_1.svg'
import bkg_2 from './img/bkg_2.svg'
import bkg_3 from './img/bkg_3.svg'
import bkg_4 from './img/bkg_4.svg'
import bkg_5 from './img/bkg_5.svg'
import headers from '../../common/headers'
import trendyol_icon from './img/trendyol_icon.svg'
import lightning_icon from './img/lightning_icon.svg'
import { LineChart } from '../../charts/LineChart/LineChart'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from "react";
import { RavshanArticlesHttps,  Andrey2Http, Andrey2Https  } from '../../fetchUrls'
import { ShortTileArticleBox, getArticleBoxData, TableBox, Tabs } from '@lk-gtcom/ecomlab-components'
import "./HomePage.scss"
import { useChartData } from '../../common/hooks'
import { Helmet } from 'react-helmet-async'
 
const list_info_color = [
    {
        title: 'Category Analysis',
        text: 'All integrations in one service. Efficient management of advertising services and data',
        color: '#F0EFFE',
        path: '/category-analysis/goods',
        bkg: bkg_1
    },
    {
        title: 'Niche search',
        text: 'All integrations in one service. Efficient management of advertising services and data',
        color: '#FCF1FE',
        path: '/niche-selection',
        bkg: bkg_2
    },
    {
        title: 'Article analysis',
        text: 'All integrations in one service. Efficient management of advertising services and data',
        color: '#FDEEEB',
        path: '/sku-analysis/information',
        bkg: bkg_3
    },
    {
        title: 'Brands analysis',
        text: 'All integrations in one service. Efficient management of advertising services and data',
        color: '#E0F8E7',
        path: '/brand-analysis/goods',
        bkg: bkg_4
    },
    {
        title: 'Sellers analysis',
        text: 'All integrations in one service. Efficient management of advertising services and data',
        color: '#F6F6FD',
        path: '/sellers-analysis/goods',
        bkg: bkg_5
    }
]

const list_info = [
    {
        info: "Operational statistics of the world's leading marketplaces"
    },
    {
        info: "Quick and easy transfer of content between sites in 1 click"
    },
    {
        info: "All integrations in one service. Efficient management of advertising services and data"
    },
    {
        info: "Always up-to-date competitive analysis of any product areas"
    },
]

const btn_tab_1 = [
    {
        label: 'Products',
        value: 'products'
    },
    {
        label: 'Categories',
        value: 'categories'
    },
    {
        label: 'Brands',
        value: 'brands'
    },
    {
        label: 'Sellers',
        value: 'sellers'
    }
]

const btn_tab_2 = [
    {
        label: 'Products',
        value: 'products'
    },
    {
        label: 'Sales',
        value: 'sales'
    },
    {
        label: 'Brands',
        value: 'brands'
    },
    {
        label: 'Sellers',
        value: 'sellers'
    }
]

export const PORTAL_ID = 7

const handleApiResponseOrThrow = async response => {
    if (response.status !== 200) {
        const errorBody = await response.json()
        throw new Error(errorBody?.reason)
    }
    return response.json()
}

const HomePage = () => {

    
    const client_name = localStorage.getItem('client_name')
    const activeLang = localStorage.getItem('lang')
    const [pageValue1, setPageValue1] = useState(btn_tab_1[0].value)
    const [pageValue2, setPageValue2] = useState(btn_tab_2[0].value)
    const [articles, setArticles] = useState([])
    const [fetchedData, setFetchedData] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])
    const [loading, setLoading] = useState([])
    const [sort, setSort] = useState({})
    const [columnSize, setColumnSize] = useState([])
    const [labels, setLabels] = useState([])

    const [ setChart, chartData, chartFuncs ] = useChartData()
    const { setChartLoading, resetChart } = chartFuncs
    const navigate = useNavigate()

    const fetchArticlesByPortal = (handleResponseJson, setLoading, activeLang, page, limit, params, orderParam, orderParamDirection) => {
        setLoading(true)
        const abortController = new AbortController()

        let filters = null, _
        if (params?.length) {
            [_, filters] = params
        }

        const url = `${RavshanArticlesHttps}/get_articles_by_portal`

        const getHeaders = {
            ...headers,
            "level-id": PORTAL_ID,
            "language": activeLang?.toLowerCase(),
            "page": page,
            "limit": limit ?? 10,
            "status": "active",
            'Referer': 'https://gtcom.io'
        }

        if (orderParam) Object.assign(getHeaders, { "order-param": btoa(encodeURI(orderParam)) })
        if (orderParamDirection) Object.assign(getHeaders, { "order-param-direction": orderParamDirection })
        if (filters && filters.length) Object.assign(getHeaders, { "filters": btoa(encodeURI(JSON.stringify({ filters }))) })

        fetch(url, { headers: getHeaders, signal: abortController.signal })
            .then(response => handleApiResponseOrThrow(response))
            .then(json => handleResponseJson(json))
            .catch(error => console.error(error))
            .finally(() => setLoading(false))

        return abortController
    }

    const fetchTableData = (params, abortController = new AbortController()) => {
        let sort;
        let filtersParam;

        if (params) {
            [sort, filtersParam] = params
        }
        setLoading(true)

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v1/gtcom/main_page/table/`
        } else {
            url = `${Andrey2Http}/api/v1/gtcom/main_page/table/`
        }

        const body = JSON.stringify({
            request_type: pageValue1,
            sort_name: sort?.[0],
            sort_order: sort?.[1],
            page: 1,
            limit: 5,
            filters: filtersParam
        })

        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => res.json()
                .then(json => {
                    const { data, labels, total, sort, column_size } = json
                    if (sort) setSort(sort)
                    if (labels) setTableHeaders(labels)
                    if (column_size) setColumnSize(column_size)
                    setFetchedData(data.length > 0 ? data : [[]])
                    setLoading(false)
                }))
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })
    }

    const fetchChart = (abortController = new AbortController()) => {

        setChart({})
        setChartLoading(true)

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v1/gtcom/main_page/graph/`
        } else {
            url = `${Andrey2Http}/api/v1/gtcom/main_page/graph/`
        }

        const body = JSON.stringify({
            request_type: pageValue2,
            page: 1,
            limit: 5
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setChart(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setChartLoading(false)
            })
    }

    useEffect(() => {
        const abortController = fetchArticlesByPortal(
            json => {
                if (!Object.entries(json).length) return
                setArticles(json)
            },
            () => { },
            activeLang,
            1,
            4
        )

        return () => {
            if (abortController) abortController.abort()
        }
    }, [])

    useEffect(() => {
        fetchTableData()
    }, [pageValue1]);

    useEffect(() => {
        fetchChart()
    }, [pageValue2]);

    const onRedirect = e => {
        const links = {
            'products': '/sku-analysis/information',
            'categories': '/category-analysis/goods',
            'brands': '/brand-analysis/goods',
            'sellers': '/sellers-analysis/goods'
        }
        window.open(links[pageValue1], '_blank')
    }


    return (
        <div className='home-page'>
            <Helmet>
                <title>GTCOM</title>
                <meta name="description" content="1000+ customers are successfully using our service" />
            </Helmet>
            <header className='home-page__header'>
                <h3 className='title_greetings'>{`Hello, ${client_name ? client_name : 'User'}! 👋`}</h3>
                <h3 className='title_main'>Look at some features of the service</h3>
            </header>

            <section className='card-group'>
                {list_info_color.map(({ title, text, color, path, bkg }, ind) =>
                    <div className='card-color' key={title + ind}
                        style={{
                            background: color,
                            backgroundImage: `url('${bkg}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'bottom 10px right 10px'
                        }}
                        onClick={() => {
                            navigate(path)
                        }}
                    >
                        <h3 className='title'>{title}</h3>
                        <p className='text'>{text}</p>
                    </div>
                )}
            </section>

            <section className='info-block'>
                <h2 className='title'>GTCOM</h2>
                <section className='info-block__content'>
                    <div className='left-content'>
                        {list_info.map(({ info }, ind) =>
                            <div className='info-item' key={info + ind}>
                                <p className='text'>{info}</p>
                            </div>
                        )}
                    </div>

                    <div className='right-content'>
                        <div className='info-item'>
                            <p className='text_count'>1000+</p>
                            <p className='text_description'>customers are successfully using our service</p>
                        </div>

                        <div className='info-item'>
                            <p className='text_count'><span>by</span> 127%</p>
                            <p className='text_description'>increased revenue for our clients with the help of the service</p>
                        </div>

                        <div className='info-item'>
                            <p className='text_count'>100+</p>
                            <p className='text_description'>analytical reports</p>
                        </div>
                    </div>
                </section>
            </section>

            <section className='table-block'>
                <h3 className='title'><img src={trendyol_icon} />Trendyol</h3>
                <section className='table-block__content'>
                    <div className='table-block__item'>
                        <header className='table-header'>
                            <div className='top'>Top<img src={lightning_icon} /></div>
                        </header>
                        <div className="table-content">
                            <Tabs tabs={btn_tab_1}
                                pageValue={pageValue1}
                                setPageValue={(e) => setPageValue1(e)}
                                stopRedirect
                            /> {/* <TableBox
                                fetchCallback={e => fetchTableData(e)}
                                maxHeight='400px'
                                {...tableProps}
                            /> */}
                            <TableBox lang='en'
                                hideHeader
                                hideFilters
                                sort={sort}
                                loading={loading}
                                headers={tableHeaders}
                                columnSize={columnSize}
                                fetchedData={fetchedData}
                                onRedirect={onRedirect}
                                sortingFunc={e => fetchTableData(e)}
                            />
                        </div>
                    </div>

                    <div className='table-block__item'>
                        <header className='table-header'>
                            <div className='top'>Total<img src={lightning_icon} /></div>
                        </header>
                        <div className="table-content">
                            <Tabs
                                tabs={btn_tab_2}
                                pageValue={pageValue2}
                                setPageValue={(e) => setPageValue2(e)}
                                stopRedirect
                            />
                            <LineChart
                                direction='x'
                                legendPosition='top'
                                {...chartData}
                                type={['bar']}
                                maxHeight='452px'
                            />
                        </div>
                    </div>
                </section>
            </section>

            <section className='article-block'>
                <h3 className="title">Blog</h3>
                <section className='article-block__content'>
                    {getArticleBoxData(articles, activeLang)?.map(articleData => (
                        <ShortTileArticleBox linkString='https://gtcom.io' activeLang={activeLang} key={articleData.id} {...articleData} pathBread={'main'} />
                    ))}
                </section>
            </section>
        </div>
    )
}

export { HomePage }