import { useState, useRef } from "react";
import { useEffect } from "react";
import { TableBox, DropContainer, LineChart } from "@lk-gtcom/ecomlab-components";
import { useChartData } from "../../common/hooks";
import { useDataTable } from "../../common/hooks";
import { Helmet } from "react-helmet-async";
import { Andrey2Https, Andrey2Http } from "../../fetchUrls";
import headers from "../../common/headers";
import "./TopBrands.scss"

const TopBrands = () => {
    const [showDropBox, setShowDropBox] = useState(false)
    const [tableMetrics, setTableMetrics] = useState([])
    const [tableMetric, setTableMetric] = useState('IDC')

    const [setChartData, chartData, setChartFuncs] = useChartData()
    const { setChartLoading, resetChart } = setChartFuncs
    const [checkFbs, setCheckFbs] = useState(false)
    const paginatorRef = useRef()

    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs
    const [selectedItems, setSelectedItems] = useState([])
    const [choisePrice, setChoisePrice] = useState([])


    const fetchAllAccountData = (params, abortController = new AbortController()) => {
        let sort;
        let filtersParam;

        if (params) {
            [sort, filtersParam] = params
        }
        setLoading(true)

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v2/gtcom/brands/top/table/`
        } else {
            url = `${Andrey2Https}/api/v2/gtcom/brands/top/table/`
        }

        const body = JSON.stringify({
            fbofbs_filter: checkFbs,
            sort_name: sort?.[0],
            sort_order: sort?.[1],
            filters: filtersParam,
            limit: paginatorRef.current.limit,
            page: paginatorRef.current.page,
        })

        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setTable(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })

    }

    const fetchChartData = (abortController = new AbortController()) => {

        setChartLoading(true)

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v2/gtcom/brands/top/graph/`
        } else {
            url = `${Andrey2Http}/api/v2/gtcom/brands/top/graph/`
        }

        let searchId
        const id_arr = selectedItems?.map(el => {
            if (el) {
                return el?.brand_id
            }
        })
        searchId = { brand_id: id_arr }

        const body = JSON.stringify({
            ...searchId,
            limit: 300,
            page: 1,
            fbofbs_filter: checkFbs,
            metric_name: tableMetric,
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setChartData(json)
            })
            .catch(err => {
                resetChart()
                console.log(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setChartLoading(false)
            })

    }

    const fetchMetricsList = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${Andrey2Https}/api/v2/gtcom/brands/top/metric_list/`
        } else {
            url = `${Andrey2Http}/api/v2/gtcom/brands/top/metric_list/`
        }

        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setTableMetrics(json);
                setTableMetric(json[0]?.value)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchMetricsList()
    }, []);

    useEffect(() => {
        if (selectedItems?.length > 0) {
            const abortController = new AbortController()
            fetchChartData(abortController)
            return () => abortController.abort()
        }
    }, [chartData?.selectedLegend, selectedItems, checkFbs, tableMetric, choisePrice])

    useEffect(() => {
        const abortController = new AbortController()
        fetchAllAccountData([], abortController)
        return () => abortController.abort()
    }, [checkFbs])

    const handleCheck = (data) => {
        setSelectedItems(data)
    }

    return (
        <div className='top-brands'>
            <Helmet>
                <title>Top brands</title>
            </Helmet>
            <h1 className='title_main'>Top brands</h1>

            <DropContainer
                lang='en'
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
                name={chartData?.title}
                selectedMetric={tableMetric}
                setSelectedMetric={setTableMetric}
                metricsList={tableMetrics}
            >
                <div className='charts-container'>
                    <LineChart
                        {...chartData}
                        legendPosition='top'
                    />
                </div>
            </DropContainer>

            <TableBox lang='en'
                {...tableProps}
                paginator={true}
                onCheck={e => handleCheck(e)}
                ref={paginatorRef}
                sortingFunc={e => fetchAllAccountData(e)}
                fetchCallback={e => fetchAllAccountData(e)}
            />
        </div>
    )
}

export { TopBrands }