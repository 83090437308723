import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './ModalSuccessfullyAddingAnEmployee.scss'
import done_icon from './img/done_icon.svg'

const ModalSuccessfullyAddingAnEmployee = ({ setIsModal }) => {

    return (
        <div className='blackout'  onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-successfully-adding-an-employee'>
                <button className='btn-close'
                onClick={(e) => setIsModal(false)}
                ></button>
                <img src={done_icon} />
                <p className='text'>Employee added successfully</p>
                <button className='btn__blue'
                onClick={(e) => setIsModal(false)}
                >Ок</button>
            </div>
        </div>
    )
}

export { ModalSuccessfullyAddingAnEmployee }