import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components";
import { KonstantinAccountInSettingsHttps, KonstantinAccountInSettingsHttp } from '../../fetchUrls';
import { ModalSuccessful } from '../Modal/ModalSuccessful/ModalSuccessful'
import Cookies from 'universal-cookie'
import { CheckboxField } from '../CheckboxField/CheckboxField';
import "./AccountSafety.scss"

const AccountSafety = ({ changePassword, setChangePassword, setShowModalPasswordRecovery }) => {

    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const [errPassword, setErrPassword] = useState(false)
    const [errPasswordRepeat, setErrPasswordRepeat] = useState(false)
    const [errNewPassword, setErrNewPassword] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [err, setErr] = useState('')

    const auth = new Cookies().get('auth')
    const device_info = window.navigator.userAgent

    const handlerPassword = (e) => {
        setPassword(e)
    }

    const handlerPasswordRepeat = (e) => {
        setPasswordRepeat(e)
    }

    const handlerNewPassword = (e) => {
        setNewPassword(e)
    }

    const fetchChangePassword = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinAccountInSettingsHttps}/gtcom/web/change-password`
        } else {
            url = `${KonstantinAccountInSettingsHttp}/gtcom/web/change-password`
        }

        const body = JSON.stringify({
            old_password: password,
            new_password: newPassword,
            device_info: device_info
        })

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json()
                .then(json => {
                    const { access_token, client_id, login, not_one_account, refresh_token } = json
                    const cookies = new Cookies()
                    let d = new Date()
                    let d2 = new Date()
                    d.setTime(d.getTime() + (1000 * 60 * 60 * 24))
                    d2.setTime(d.getTime() + (1000 * 60 * 60 * 24 * 7))

                    if (login) {
                        localStorage.setItem("email", login)
                    }
                    if (client_id) {
                        localStorage.setItem("client_id", client_id)
                    }
                    if (not_one_account !== 'undefined') {
                        localStorage.setItem('not_one_account', not_one_account)
                    }
                    if (access_token) {
                        cookies.set('auth', access_token, { expires: d })
                    }
                    if (refresh_token) {
                        cookies.set('refresh', refresh_token, { expires: d2 })
                    }
                    setIsModal(true)
                }))
            .catch(err => {
                console.log(err)
            })
    }

    const deleteErr = () => {
        setErr('')
        setErrNewPassword(false)
        setErrPassword(false)
        setErrPasswordRepeat(false)
    }

    useEffect(() => {
        deleteErr()
    }, [password, newPassword, passwordRepeat]);

    useEffect(() => {
        if (changePassword) {
            const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/
            if (!regexPassword.test(password)) setErrPassword(true)
            if (!regexPassword.test(newPassword)) setErrNewPassword(true)
            if (!regexPassword.test(passwordRepeat)) setErrPasswordRepeat(true)

            if (regexPassword.test(password) && regexPassword.test(newPassword) && regexPassword.test(passwordRepeat)) {
                if (newPassword === passwordRepeat) {
                    fetchChangePassword()
                } else {
                    setErr("Passwords don't match")
                }

            }
            setChangePassword(false)
        }
    }, [changePassword]);

    return (
        <>
            {isModal && <ModalSuccessful title='Successfully' text='You can always change your password in settings' setIsModal={(e) => setIsModal(e)} />}
            <div className='account-safety'>
                <h2 className='title'>Change password</h2>

                <div style={{ marginTop: '28px' }}>
                    <InputDinamycPlaceholder
                        onChange={(e) => handlerPassword(e)}
                        type="password"
                        err={errPassword}
                        textErr={'Wrong password'}
                        classname='autorization__input-field'
                        placeholder='Old password'
                        value={password}
                        setValue={(e) => setPassword(e)}
                        autoComplete={false}
                        style={{ marginTop: '28px' }}
                    />
                    <a href='#' className='link__blue'
                        onClick={(e) => {
                            e.preventDefault(e)
                            setShowModalPasswordRecovery(true)
                        }}
                    >Forgot password?</a>
                </div>
                <div style={{ marginTop: '11px'}}>
                 <InputDinamycPlaceholder
                    onChange={(e) => handlerNewPassword(e)}
                    type="password"
                    err={errNewPassword}
                    textErr={'Wrong password'}
                    classname='autorization__input-field'
                    placeholder='New password'
                    value={newPassword}
                    setValue={(e) => setNewPassword(e)}
                    autoComplete={false}
                    tooltipStatic='Password must contain minimum 8 symbols, numbers and uppercase'
                />   
                </div>
                
                <div style={{ marginTop: '15px'}}>
                <InputDinamycPlaceholder
                    onChange={(e) => handlerPasswordRepeat(e)}
                    type="password"
                    err={errPasswordRepeat}
                    textErr={'Wrong password'}
                    classname='autorization__input-field'
                    placeholder='Confirm new password'
                    value={passwordRepeat}
                    setValue={(e) => setPasswordRepeat(e)}
                    autoComplete={false}
                />     
                </div>
                
                <div className='err-box'>
                    {err && <span className='text text_red'>{err}</span>}
                </div>

            </div>
        </>

    )
}

export { AccountSafety }