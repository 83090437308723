import { OlegHttps, OlegHttp } from "../fetchUrls"
import headers from './headers'
import dayjs from "dayjs"

export const setDataFromJson = (json, page, setState, withAll = true, mapFunc = el => ({ label: el.name, value: el.id })) => {
    const dataArr = json.map(mapFunc)
    if (page > 1) {
        setState(prev => [...prev, ...dataArr])
    } else {
        if (withAll) setState([{ label: 'Все', value: 'all' }, ...dataArr])
        else setState([...dataArr])
    }
}

export const fetchApiFilter = ({ page = 1, setApiData, searchParam = null, type = 'union' }) => {
    let url
    if (!window.location.hostname.match('localhost')) {
        url = `${OlegHttps}/api/v1/filter/products/api_id?filter_type=${type}`
    } else {
        url = `${OlegHttp}/api/v1/filter/products/api_id?filter_type=${type}`
    }
    const body = JSON.stringify({
        search_param: searchParam.length < 1 ? null : searchParam,
        api_id_list: ['all'],
        category_id_list: ['all'],
        brand_id_list: ['all'],
        product_id_list: ['all'],
        limit: 50,
        page
    })

    fetch(url, { body, method: 'POST', headers })
        .then(res => res.json())
        .then(json => setDataFromJson(json, page, setApiData, false))
        .catch(err => console.log(err))
}

export const fetchCategoryFilter = ({ apiData, dateFrom, dateTo, selectedApi, setCategoryData, page = 1, searchParam = null,  }) => {
    if (apiData?.length > 0 & dateFrom !== '' & dateTo !== '') {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/products/category?filter_type=union`
        } else {
            url = `${OlegHttp}/api/v1/filter/products/category?filter_type=union`
        }
        let api_id_list

        if (selectedApi.length > 0) {
            api_id_list = selectedApi.map(el => el.value)
        } else {
            api_id_list = apiData.map(el => el.value)
        }
        if(selectedApi.value){
            api_id_list = [selectedApi?.value]
        } 

        const body = JSON.stringify({
            search_param: searchParam.length < 1 ? null : searchParam,
            api_id_list,
            category_id_list: ['all'],
            brand_id_list: ['all'],
            product_id_list: ['all'],
            date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
            date_to: dayjs(dateTo).format('YYYY-MM-DD'),
            page,
            limit: 50
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const dataArr = json?.map((el) => ({ label: el.name, value: el.id }))
                if (page > 1) {
                    setCategoryData(prev => [...prev, ...dataArr])
                } else {
                    setCategoryData([{ label: 'Все', value: 'all' }, ...dataArr])
                }
            })
            .catch(err => console.log(err))
    }
}

export const fetchBrandFilter = ({ apiData, categoryData, selectedCategory, dateFrom, dateTo, selectedApi, page = 1, setBrandData, searchParam = null,  }) => {
    if (apiData?.length > 0 & dateFrom !== '' & dateTo !== '' & categoryData?.length > 0) {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/products/brand?filter_type=union`
        } else {
            url = `${OlegHttp}/api/v1/filter/products/brand?filter_type=union`
        }
        let api_id_list
        let category_id_list

        if (selectedApi.length > 0) {
            api_id_list = selectedApi.map(el => el.value)
        } else {
            api_id_list = apiData.map(el => el.value)
        }
        if(selectedApi.value){
            api_id_list = [selectedApi?.value]
        } 

        if (selectedCategory.length > 0) {
            category_id_list = selectedCategory.map(el => el.value)
        } else {
            category_id_list = categoryData.map(el => el.value)
        }

        const body = JSON.stringify({
            search_param: searchParam.length < 1 ? null : searchParam,
            api_id_list,
            category_id_list,
            brand_id_list: ['all'],
            product_id_list: ['all'],
            date_from: dayjs(dateFrom).format('YYYY-MM-DD'),
            date_to: dayjs(dateTo).format('YYYY-MM-DD'),
            page,
            limit: 50
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const dataArr = json.map((el) => ({ label: el.name, value: el.id }))
                if (page > 1) {
                    setBrandData(prev => [...prev, ...dataArr])
                } else {
                    setBrandData([{ label: 'Все', value: 'all' }, ...dataArr])
                    setBrandData([{ label: 'Все', value: 'all' }, ...dataArr])
                }
            })
            .catch(err => console.log(err))
    }
}

export const fetchProductFilter = ({ apiData, categoryData, selectedCategory, selectedBrand, brandData, selectedApi, page = 1, setProductsData, searchParam = null,  }) => {
    let url
    if (!window.location.hostname.match('localhost')) {
        url = `${OlegHttps}/api/v1/filter/products/product?filter_type=union`
    } else {
        url = `${OlegHttp}/api/v1/filter/products/product?filter_type=union`
    }

    let api_id_list
    let category_id_list
    let brand_id_list

    if (selectedApi?.length > 0) {
        api_id_list = selectedApi?.map(el => el.value)
    } else {
        api_id_list = apiData?.map(el => el.value)
    }
    if(selectedApi.value){
        api_id_list = [selectedApi?.value]
    } 

    if (selectedCategory?.length > 0) {
        category_id_list = selectedCategory?.map(el => el.value)
    } else {
        category_id_list = categoryData?.map(el => el.value)
    }

    if (selectedBrand?.length > 0) {
        brand_id_list = selectedBrand?.map(el => el.value)
    } else {
        brand_id_list = brandData?.map(el => el.value)
    }

    const body = JSON.stringify({
        
        search_param: searchParam.length < 1 ? null : searchParam,
        api_id_list,
        category_id_list,
        brand_id_list,
        product_id_list: ['all'],
        page,
        limit: 50
    })

    fetch(url, { body, method: 'POST', headers })
        .then(res => res.json())
        .then(json => {
            const dataArr = json?.map((el) => ({ label: el.name, value: el.id }))
            if (page > 1) {
                setProductsData(prev => [...prev, ...dataArr])
            } else {
                setProductsData([{ label: 'Все', value: 'all' }, ...dataArr])
            }
        })
        .catch(err => console.log(err))
}





export const fetchCompetitorsFilter = ({type, page = 1, setApiData, searchParam = null,  }) => {
    let url
    if (!window.location.hostname.match('localhost')) {
        url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/shop`
    } else {
        url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/shop`
    }

    const body = JSON.stringify({
        
        shop_id_list: ['all'],
        brand_id_list: ['all'],
        category_id_list: ['all'],
        seller_id_list: ['all'],
        super_id_list: ['all']
    })

    fetch(url, { body, method: 'POST', headers })
        .then(res => res.json())
        .then(json => {
            console.log(json)
            const dataArr = json.map((el) => ({ label: el.name ? el.name : 'Не указано', value: el.id }))
            if (page > 1) {
                setApiData(prev => [...prev, ...dataArr])
            } else {
                setApiData([...dataArr])
            }
        })
        .catch(err => console.log(err))
}

export const fetchCompetitorCategoryFilter = ({type, apiData, selectedApi, setCategoryData, page = 1, searchParam = null,  }) => {
    if (apiData.length > 0) {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/super/category`
        } else {
            url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/super/category`
        }

        let shop_id_list;
        
        if (selectedApi.length > 0) {
            shop_id_list = selectedApi.map(el => el.value)
        } else {
            shop_id_list = apiData.map(el => el.value)
        }

        const body = JSON.stringify({
            shop_id_list,
            category_id_list: ['all'],
            brand_id_list: ['all'],
            seller_id_list: ['all'],
            super_id_list: ['all']
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const dataArr = json.map((el) => ({ label: el.name ? el.name : 'Не указано', value: el.id }))
                if (page > 1) {
                    setCategoryData(prev => [...prev, ...dataArr])
                } else {
                    setCategoryData([{ label: 'Все', value: 'all' }, ...dataArr])
                }
            })
            .catch(err => console.log(err))
    }
}

export const fetchCompetitorBrandFilter = ({type, apiData, categoryData, selectedCategory, selectedApi, page = 1, setBrandData, searchParam = null,  }) => {
    if (apiData.length > 0 & categoryData.length > 0) {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/super/brand`
        } else {
            url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/super/brand`
        }
        let shop_id_list
        let category_id_list

        if (selectedApi.length > 0) {
            shop_id_list = selectedApi.map(el => el.value)
        } else {
            shop_id_list = apiData.map(el => el.value)
        }

        if (selectedCategory.length > 0) {
            category_id_list = selectedCategory.map(el => el.value)
        } else {
            category_id_list = categoryData.map(el => el.value)
        }

        const body = JSON.stringify({
            shop_id_list,
            category_id_list,
            brand_id_list: ['all'],
            seller_id_list: ['all'],
            super_id_list: ['all']
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const dataArr = json.map((el) => ({ label: el.name ? el.name : 'Не указано', value: el.id }))
                if (page > 1) {
                    setBrandData(prev => [...prev, ...dataArr])
                } else {
                    setBrandData([{ label: 'Все', value: 'all' }, ...dataArr])
                }
            })
            .catch(err => console.log(err))
    }
}

export const fetchCompetitorProductFilter = ({type, apiData, categoryData, selectedCategory, selectedBrand, brandData, selectedApi, page = 1, setProductsData, searchParam = null,  }) => {
    if (apiData.length > 0  & categoryData.length > 0 & selectedBrand.length > 0) {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/competitors/type_${type}super/seller`
        } else {
            url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/super/seller`
        }

        let shop_id_list
        let category_id_list
        let brand_id_list

        if (selectedApi.length > 0) {
            shop_id_list = selectedApi.map(el => el.value)
        } else {
            shop_id_list = apiData.map(el => el.value)
        }

        if (selectedCategory.length > 0) {
            category_id_list = selectedCategory.map(el => el.value)
        } else {
            category_id_list = categoryData.map(el => el.value)
        }

        if (selectedBrand.length > 0) {
            brand_id_list = selectedBrand.map(el => el.value)
        } else {
            brand_id_list = brandData.map(el => el.value)
        }


        const body = JSON.stringify({
            shop_id_list,
            brand_id_list,
            category_id_list,
            seller_id_list: ['all'],
            super_id_list: ['all']
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const dataArr = json.map((el) => ({ label: el.name ? el.name : 'Не указано', value: el.id }))
                if (page > 1) {
                    setProductsData(prev => [...prev, ...dataArr])
                } else {
                    setProductsData([{ label: 'Все', value: 'all' }, ...dataArr])
                }
            })
            .catch(err => console.log(err))
    }
}

export const fetchCompetitorSuperFilter = ({type, apiData, categoryData, selectedCategory, selectedBrand, brandData, selectedApi, page = 1, setSuperData, productsData,selectedProduct, searchParam = null,  }) => {
    if (apiData.length > 0  & categoryData.length > 0 & selectedBrand.length > 0) {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/super/prouct`
        } else {
            url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/super/product`
        }

        let shop_id_list
        let category_id_list
        let brand_id_list
        let seller_id_list

        if (selectedApi.length > 0) {
            shop_id_list = selectedApi.map(el => el.value)
        } else {
            shop_id_list = apiData.map(el => el.value)
        }

        if (selectedCategory.length > 0) {
            category_id_list = selectedCategory.map(el => el.value)
        } else {
            category_id_list = categoryData.map(el => el.value)
        }

        if (selectedBrand.length > 0) {
            brand_id_list = selectedBrand.map(el => el.value)
        } else {
            brand_id_list = brandData.map(el => el.value)
        }

        if (selectedProduct.length > 0) {
            seller_id_list = selectedProduct.map(el => el.value)
        } else {
            seller_id_list = productsData.map(el => el.value)
        }

        const body = JSON.stringify({
            shop_id_list,
            brand_id_list,
            category_id_list,
            seller_id_list,
            super_id_list: ['all']
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const dataArr = json.map((el) => ({ label: el.name ? el.name : 'Не указано', value: el.id }))
                if (page > 1) {
                    setSuperData(prev => [...prev, ...dataArr])
                } else {
                    setSuperData([{ label: 'Все', value: 'all' }, ...dataArr])
                }         
            })
            .catch(err => console.log(err))
    }
}



export const fetchCompetitorsCategoryMasterFilter = ({ page = 1, setApiData, searchParam = null }) => {
    let url
    if (!window.location.hostname.match('localhost')) {
        url = `${OlegHttps}/api/v1/filter/competitors/category_master`
    } else {
        url = `${OlegHttp}/api/v1/filter/competitors/category_master`
    }
    const body = JSON.stringify({
        search_param: searchParam.length < 1 ? null : searchParam,
        limit: 50,
        page
    })

    fetch(url, { body, method: 'POST', headers })
        .then(res => res.json())
        .then(json => {
            const dataArr = json.map((el) => ({ label: el.name, value: el.id }))
            if (page > 1) {
                setApiData(prev => [...prev, ...dataArr])
            } else {
                setApiData([...dataArr])
            }
        })
        .catch(err => console.log(err))
}

export const fetchCompetitorsCategorySlaveFilter = ({ page = 1, setApiData, searchParam = null, master_category_id_list }) => {
    let url
    if (!window.location.hostname.match('localhost')) {
        url = `${OlegHttps}/api/v1/filter/competitors/category_slave`
    } else {
        url = `${OlegHttp}/api/v1/filter/competitors/category_slave`
    }
    const body = JSON.stringify({
        master_category_id_list,
        search_param: searchParam.length < 1 ? null : searchParam,
        limit: 50,
        page
    })

    fetch(url, { body, method: 'POST', headers })
        .then(res => res.json())
        .then(json => {
            const dataArr = json.map((el) => ({ label: el.name, value: el.id }))
            if (page > 1) {
                setApiData(prev => [...prev, ...dataArr])
            } else {
                setApiData([...dataArr])
            }
        })
        .catch(err => console.log(err))
}

const fetchCompetitorsFilterInternal = ({ url, body, page = 1, setData, mapFunc, errorCallback, cleanupCallback, emptyResponseCallback }) => {

    fetch(url, { headers, method: 'POST', body })
        .then(response => {
            if (response.status === 204) {
                if (typeof emptyResponseCallback === 'function') emptyResponseCallback()
                return []
            }
            if (response.status !== 200) throw new Error('bad competitors brand response')
            return response.json()
        })
        .then(json => json.length ? setDataFromJson(json, page, setData, true, mapFunc) : {})
        .catch(error => {
            if (typeof errorCallback === 'function') errorCallback(error)
            else console.error(error)
        })
        .finally(() => typeof cleanupCallback === 'function' ? cleanupCallback() : {})
}

export const fetchCompetitorsBrandFilter = ({
    page = 1,
    limit = 50,
    searchParam = null,
    setData,
    mapFunc,
    errorCallback,
    cleanupCallback,
    emptyResponseCallback, 
    url
}) => {
    const body = JSON.stringify({
        search_param: searchParam,
        page,
        limit
    })

    fetchCompetitorsFilterInternal({
        url: `${OlegHttps}/api/v1/filter/competitors/trendyol/brand`,
        page,
        body,
        setData,
        mapFunc,
        errorCallback,
        cleanupCallback,
        emptyResponseCallback
    })
}

export const fetchCompetitorsSellerFilter = ({
    page = 1,
    limit = 50,
    searchParam,
    setData,
    mapFunc,
    errorCallback,
    cleanupCallback,
    emptyResponseCallback
}) => {
    const body = JSON.stringify({
        search_param: searchParam,
        page,
        limit
    })

    fetchCompetitorsFilterInternal({
        url: `${OlegHttps}/api/v1/filter/competitors/trendyol/seller`,
        page,
        body,
        setData,
        mapFunc,
        errorCallback,
        cleanupCallback,
        emptyResponseCallback
    })
}

export const fetchCompetitorsLinkBrandFilter = ({
    page = 1,
    limit = 50,
    searchParam = null,
    shopData,
    selectedShop,
    categoryData,
    selectedCategory,
    setData,
    mapFunc,
    errorCallback,
    cleanupCallback
}) => {
    if (!shopData?.length || !categoryData.length) {
        console.warn('no shop or category data for brand filter is provided')
        return
    }

    const body = JSON.stringify({
        search_param: searchParam,
        page,
        limit,
        shop_name_list: selectedShop?.length ? selectedShop.map(el => el.label === 'Все' ? 'all' : el.label) : shopData.map(el => el.label === 'Все' ? 'all' : el.value),
        category_name_list: selectedCategory?.length ? selectedCategory.map(el => el.label === 'Все' ? 'all' : el.label) : categoryData.map(el => el.label === 'Все' ? 'all' : el.value),
        brand_name_list: ['all']
    })

    fetchCompetitorsFilterInternal({
        url: `${OlegHttps}/api/v1/filter/competitors/link/brand`,
        page,
        body,
        setData,
        mapFunc,
        errorCallback,
        cleanupCallback
    })
}

export const fetchCompetitorsLinkCategoryFilter = ({
    page = 1,
    limit = 50,
    searchParam = null,
    shopData,
    selectedShop,
    setData,
    mapFunc,
    errorCallback,
    cleanupCallback
}) => {
    if (!shopData.length) {
        console.warn('no shop data for category filter is provided')
        return
    }

    const body = JSON.stringify({
        search_param: searchParam,
        page,
        limit,
        shop_name_list: selectedShop?.length ? selectedShop.map(el => el.label === 'Все' ? 'all' : el.label) : shopData.map(el => el.label === 'Все' ? 'all' : el.value),
        category_name_list: ['all'],
        brand_name_list: ['all']
    })
    
    fetchCompetitorsFilterInternal({
        url: `${OlegHttps}/api/v1/filter/competitors/link/category`,
        page,
        body,
        setData,
        mapFunc,
        errorCallback,
        cleanupCallback
    })
}

export const fetchCompetitorsLinkShopFilter = ({
    page = 1,
    limit = 50,
    searchParam = null,
    setData,
    mapFunc,
    errorCallback,
    cleanupCallback
}) => {
    const body = JSON.stringify({
        search_param: searchParam,
        page,
        limit,
        shop_name_list: ['all'],
        category_name_list: ['all'],
        brand_name_list: ['all']
    })

    fetchCompetitorsFilterInternal({
        url: `${OlegHttps}/api/v1/filter/competitors/link/shop`,
        page,
        body,
        setData,
        mapFunc,
        errorCallback,
        cleanupCallback
    })
}

export const fetchCompetitor2CategoryFilter = ({type, apiData, selectedApi, setCategoryData, page = 1, searchParam = null,  }) => {
    if (apiData.length > 0) {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/category`
        } else {
            url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/category`
        }
        let shop_id_list

        if (selectedApi.length > 0) {
            shop_id_list = selectedApi.map(el => el.value)
        } else {
            shop_id_list = apiData.map(el => el.value)
        }

        const body = JSON.stringify({
            shop_id_list,
            category_id_list: ['all'],
            brand_id_list: ['all'],
            seller_id_list: ['all'],
            page,
            limit: 50
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const dataArr = json.map((el) => ({ label: el.name ? el.name : 'Не указано', value: el.id }))
                if (page > 1) {
                    setCategoryData(prev => [...prev, ...dataArr])
                } else {
                    setCategoryData([{ label: 'Все', value: 'all' }, ...dataArr])
                }
            })
            .catch(err => console.log(err))
    }
}

export const fetchCompetitor2BrandFilter = ({type, apiData, categoryData, selectedCategory, selectedApi, page = 1, setBrandData, searchParam = null,  }) => {
    if (apiData.length > 0 & categoryData.length > 0) {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/brand`
        } else {
            url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/brand`
        }
        let shop_id_list
        let category_id_list

        if (selectedApi.length > 0) {
            shop_id_list = selectedApi.map(el => el.value)
        } else {
            shop_id_list = apiData.map(el => el.value)
        }

        if (selectedCategory.length > 0) {
            category_id_list = selectedCategory.map(el => el.value)
        } else {
            category_id_list = categoryData.map(el => el.value)
        }

        const body = JSON.stringify({
            shop_id_list,
            category_id_list: ['all'],
            brand_id_list: ['all'],
            seller_id_list: ['all']
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const dataArr = json.map((el) => ({ label: el.name ? el.name : 'Не указано', value: el.id }))
                if (page > 1) {
                    setBrandData(prev => [...prev, ...dataArr])
                } else {
                    setBrandData([{ label: 'Все', value: 'all' }, ...dataArr])
                }
            })
            .catch(err => console.log(err))
    }
}

export const fetchCompetitor2ProductFilter = ({type, apiData, categoryData, selectedCategory, selectedBrand, brandData, selectedApi, page = 1, setProductsData, searchParam = null,  }) => {
    if (apiData.length > 0) {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/competitors/type_${type}/seller`
        } else {
            url = `${OlegHttp}/api/v1/filter/competitors/type_${type}/seller`
        }
        let shop_id_list
        let category_id_list
        let brand_id_list

        if (selectedApi.length > 0) {
            shop_id_list = selectedApi.map(el => el.value)
        } else {
            shop_id_list = apiData.map(el => el.value)
        }

        if (selectedCategory.length > 0) {
            category_id_list = selectedCategory.map(el => el.value)
        } else {
            category_id_list = categoryData.map(el => el.value)
        }

        if (selectedBrand.length > 0) {
            brand_id_list = selectedBrand.map(el => el.value)
        } else {
            brand_id_list = brandData.map(el => el.value)
        }

        console.log(category_id_list, brand_id_list)


        const body = JSON.stringify({
            shop_id_list,
            brand_id_list: brand_id_list.length == 0 ? ['all'] : brand_id_list,
            category_id_list: category_id_list.length == 0 ? ['all'] : category_id_list,
            seller_id_list: ['all'],
            page,
            limit: 50
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                const dataArr = json.map((el) => ({ label: el.name ? el.name : 'Не указано', value: el.id }))
                if (page > 1) {
                    setProductsData(prev => [...prev, ...dataArr])
                } else {
                    setProductsData([{ label: 'Все', value: 'all' }, ...dataArr])
                }
            })
            .catch(err => console.log(err))
    }
}




export const fetchWarehouseFilter = ({ setApiData, page = 1, limit = 50, searchParam = null, }) => {
    let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/warehouse/api_id?filter_type=union`
        } else {
            url = `${OlegHttp}/api/v1/filter/warehouse/api_id?filter_type=union`
        }

        const body = JSON.stringify({
            page,
            limit
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => setDataFromJson(json, page, setApiData, false))
            .catch(err => console.log(err))
}

export const fetchWarehouseWarehouseFilter = ({ apiData, selectedApi, setWarehouseData, page = 1 }) => {
    if (apiData.length > 0) {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/filter/warehouse/warehouse?filter_type=union`
        } else {
            url = `${OlegHttp}/api/v1/filter/warehouse/warehouse?filter_type=union`
        }
        let api_id_list

        if (selectedApi.length > 0) {
            api_id_list = selectedApi.map(el => el.value)
        } else {
            api_id_list = apiData.map(el => el.value)
        }

        const body = JSON.stringify({
            api_id_list,
            limit: 50, 
            page
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => setDataFromJson(json, page, setWarehouseData, false))
            .catch(err => console.log(err))
    }
}

