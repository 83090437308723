import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import "./TooltipBlack.scss"

const TooltipBlack = ({ child, text, top, left, right, position, maxWidth, hideTooltip = false,  }) => {

    return (
        <div className='tooltip-container' style={{position: position === 'absolute' ? 'static' : 'relative'}}>
            {child}
            {
                !hideTooltip &&
                <div className='tooltip-black'
                    style={{
                        top: top ? top : '',
                        left: left ? left : '',
                        right: right ? right : '',
                        maxWidth: maxWidth ? maxWidth : '',
                        position: position ? position : ''
                    }}
                >{text}</div>
            }
        </div>
    )
}

export { TooltipBlack }