import { useState } from 'react';
import { useEffect } from 'react';
import { LineChart } from '../../charts/LineChart/LineChart';
import { TableBox, DropContainer, Tabs } from '@lk-gtcom/ecomlab-components';
import './ProductCardExtended_Position.scss';

const btn_tab = [
  {
    label: 'Search',
    value: '',
  },
  {
    label: 'Categories',
    value: 'category',
  },
];

const ProductCardExtended_Position = ({
  tableData,
  chartData,
  tableCallback,
  setPositionPageValue,
  metricsList,
  selectedMetric,
  setSelectedMetric,
  setSelectedSearchQuery,
  pageValue,
  setPageValue,
  onCheck
}) => {
  const [showDropBox, setShowDropBox] = useState(true);
  // const [pageValue, setPageValue] = useState(btn_tab[0].value);
  // const onCheck = (obj) => {
  //   const querySearch = obj.map((item) => item?.['Ключевое слово']?.[0]);
  //   setSelectedSearchQuery(querySearch);
  // };

  useEffect(() => {
    setPageValue(btn_tab[1].value)
  }, []);

  return (
    <section className="position-inside-content">
      <DropContainer
        lang='en'
        showDropBox={showDropBox}
        setShowDropBox={(e) => setShowDropBox(e)}
        name={chartData?.title}
        metricsList={metricsList}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
      >
        <LineChart lang='en' {...chartData} legend={metricsList} />
      </DropContainer>

      <Tabs
        tabs={btn_tab}
        pageValue={pageValue}
        setPageValue={(e) => {
          setPageValue(e);
          setPositionPageValue(e);
        }}
        stopRedirect
      />

      <TableBox
        {...tableData}
        paginator={true}
        fetchCallback={tableCallback}
        onCheck={(e) => onCheck(e)}
      />
    </section>
  );
};

export { ProductCardExtended_Position };
