import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls';
import Cookies from 'universal-cookie';
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components";
import { ModalErr } from '../Modal/ModalErr/ModalErr';
import { validationEmail } from '../../common/validationFunc';
import './ResetPasswordBox.scss'
import { processTokenResponse } from '../../common/authHelper';


const ResetPasswordBox = ({ email }) => {
    const navigate = useNavigate()
    const path = useParams()
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [inValid, setInvalid] = useState(false)
    const [isModalErr, setIsModalErr] = useState(false)
    const [err, setErr] = useState(false)

    const [errPassword, setErrPassword] = useState(false)
    const [errPasswordRepeat, setErrPasswordRepeat] = useState(false)

    const deleteErrNotofication = () => {
        setErrPassword(false)
        setErrPasswordRepeat(false)
        setErr(false)
        setInvalid(false)
    }

    const handlerSetPassword = (e) => {
        setPassword(e)
        deleteErrNotofication()
    }

    const handlerSetRepeatPassword = (e) => {
        setPasswordRepeat(e)
        deleteErrNotofication()
    }

    let form = {
        token: path['*'].split('/')[1],
        password: password,
        device_info: ''
    }

    const handlerSubmit = (e) => {
        if (password !== passwordRepeat) {
            setInvalid(true)
        } else {
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${KonstantionAuthHttps}/gtcom/web/reset-password`
            } else {
                url = `${KonstantionAuthHttp}/gtcom/web/reset-password`
            }

            fetch(url,
                {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: { 'Content-type': 'application/json' }
                })
                .then((response) => response.json())
                .then(async data => processTokenResponse(data))
                .catch(err => {
                    setIsModalErr(true)
                    console.log(err)
                })
        }
    }

    const checkInputField = () => {

        const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/

        if (!regexPassword.test(password)) {
            setErrPassword(true)
        }

        if (!regexPassword.test(passwordRepeat)) {
            setErrPasswordRepeat(true)
        }

        if (!regexPassword.test(password) || !regexPassword.test(passwordRepeat)) {
            return
        } else {
            handlerSubmit()
        }
    }

    return (
        <>
            {isModalErr && <ModalErr isModal={isModalErr} setIsModal={(e) => setIsModalErr(e)} />}
            <div className='autorization' onSubmit={e => e.preventDefault()} spellCheck='false'>
                <h2 className='title'>Create password</h2>
                <InputDinamycPlaceholder
                    onChange={(e) => handlerSetPassword(e)}
                    type='password'
                    classname='autorization__input-field'
                    placeholder='Enter your new password'
                    err={errPassword}
                    textErr={'Passwords do not match'}
                    value={password}
                    setValue={(e) => setPassword(e)}
                    autoComplete={false}
                    passwordTooltip
                    tooltip='Password must contain minimum 8 symbols, numbers and uppercase'
                />
                <InputDinamycPlaceholder
                    onChange={(e) => handlerSetRepeatPassword(e)}
                    type='password'
                    err={errPasswordRepeat}
                    textErr={'Passwords do not match'}
                    classname='autorization__input-field'
                    placeholder='Confirm password '
                    value={passwordRepeat}
                    setValue={(e) => setPasswordRepeat(e)}
                    autoComplete={false}
                />

                {inValid ? <Link to="#" className='text text_red'>Passwords don't match</Link> : null}

                {err && <span className='text text_red'>Password reset failed</span>}

                <button
                    onClick={e => {
                        e.preventDefault()
                        checkInputField()
                    }}
                    className='btn__blue'
                >
                    Save password
                </button>
            </div>
        </>
    )
}

export { ResetPasswordBox }