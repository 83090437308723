import { Routes, Route } from 'react-router-dom'
import arrow_green from './img/arrow_green.svg'
import arrow_red from './img/arrow_red.svg'
import './DynamicsComponent.scss'

const data = [
    {
        label: 'Показы показыыыыыы ыыыыыыыыыыыыыыы ыыыыыыыыы ыыыыыыыыыыыы',
        data_1: {
            sum: 30000000,
            percent: 44,
            сonversion: 5
        },
        data_2: {
            sum: 60000000,
            percent: 44,
            сonversion: 5
        }
    },
    {
        label: 'Просмотры',
        data_1: {
            sum: 10000,
            percent: -44,
            сonversion: 5
        },
        data_2: {
            sum: 6000,
            percent: 44,
            сonversion: 5
        }
    },
    {
        label: 'Просмотры',
        data_1: {
            sum: 10000,
            percent: -44,
            сonversion: 5
        },
        data_2: {
            sum: 6000,
            percent: 44,
            сonversion: 5
        }
    },
    {
        label: 'Просмотры',
        data_1: {
            sum: 10000,
            percent: -44,
            сonversion: 5
        },
        data_2: {
            sum: 6000,
            percent: 44,
            сonversion: 5
        }
    },
    {
        label: 'Просмотры',
        data_1: {
            sum: 10000,
            percent: -44,
            сonversion: 5
        },
        data_2: {
            sum: 6000,
            percent: 44,
            сonversion: 5
        }
    }
]

const DynamicsComponent = ({ title }) => {

    const dynamicElement = (dynamic) => {
        if (dynamic > 0) {
            return <div className='dynamic_positive'><img src={arrow_green} />{dynamic + ' %'}</div>
        } else if (dynamic < 0) {
            return <div className='dynamic_negative'><img src={arrow_red} />{dynamic + ' %'}</div>
        } else return <div className='dynamic_neutral'>{'0 %'}</div>
    }

    return (
        <div className='dynamics-component'>
            {title && <h3>{title}</h3>}
            <div className='dynamics-component__content'>
                {data?.length ?
                    data.map((el, ind) =>
                        <div className='row-item'>
                            <h4 className='title'>{el?.label}</h4>
                            <div className='data-item'>
                                <div className='data-item__top'>
                                    <p className='text'>{el?.data_1?.sum?.toLocaleString()}</p>
                                    {dynamicElement(el?.data_1?.percent)}
                                </div>
                                <div className='data-item__bottom'>
                                    <p className='text'>Конверсия:</p>
                                    <div className='dynamic_neutral'>{el?.data_1?.сonversion}%</div>
                                </div>
                            </div>

                            <div className='data-item'>
                                <div className='data-item__top'>
                                    <p className='text'>{el?.data_2?.sum?.toLocaleString()}</p>
                                    {dynamicElement(el?.data_2?.percent)}
                                </div>
                                <div className='data-item__bottom'>
                                    <p className='text'>Конверсия:</p>
                                    <div className='dynamic_neutral'>{el?.data_2?.сonversion}%</div>
                                </div>
                            </div>
                        </div>
                    )
                    :
                    <div class="no-data">Нет данных</div>
                }
            </div>
        </div>
    )
}

export { DynamicsComponent }