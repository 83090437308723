import { Link } from 'react-router-dom';
import "./CopyrightBox.scss"

const CopyrightBox = () => {

    return (
        <section className='copyright'>
            <p className="footer__copyright">© GTCOM.IO 2023. All rights reserved.</p>
            <a href="https://gtcom.io/main/article/Terms_of_Service/578" target='_blank' className="footer__link">User Agreement</a>
            <a href="https://gtcom.io/main/article/Terms_of_payment/587" target='_blank' className="footer__link">Payment Politics</a>
            <a href="https://gtcom.io/main/article/PRIVACY_POLICY/579" target='_blank' className="footer__link">Confidential Politics</a>
            <p className="footer__link country" style={{ 'border': 'none' }}>Turkey</p>
        </section>
    )
}

export { CopyrightBox }