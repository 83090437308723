import { useState, useEffect } from 'react'
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components";
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls';
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import { Mail } from '../../components/PasswordRecoveryBox/Mail/Mail'
import Cookies from 'universal-cookie';
import './PasswordRecoveryPage.scss'
import { validationEmail } from '../../common/validationFunc';
import { ModalErr } from '../../components/Modal/ModalErr/ModalErr';

const PasswordRecoveryPage = ({ setIsModal }) => {
    const path = useParams();
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [errEmail, setErrEmail] = useState(false)

    useEffect(() => {
        if (path['*'] === '') {
            navigate('email')
        }
    }, [])

    const [inValid, setInvalid] = useState(false)
    const [incorrectEmail, setIncorrectEmail] = useState(false)
    const [activeContent, setActiveContent] = useState(true)
    // const [disabledBtn, setdisabledBtn] = useState(false)
    const [isModalErr, setIsModalErr] = useState(false)
    const [isModalMail, setIsModalMail] = useState(true)
    const auth = new Cookies().get('auth')

    const handlerSetEmail = (e) => {
        setIncorrectEmail(false)
        setErrEmail(false)
        setEmail(e)
    }

    const checkInputField = () => {

        if (!validationEmail(email)) {
            setErrEmail(true)
        }

        if (validationEmail(email)) {
            handlerSubmit()
        }
    }


    const handlerSubmit = (e) => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/gtcom/web/forgot-password`
        } else {
            url = `${KonstantionAuthHttp}/gtcom/web/forgot-password`
        }

        const body = JSON.stringify({
            email
        })

        fetch(url,
            {
                body,
                method: 'POST',
                headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${auth}` }
            })
            .then(res => res.json())
            .then(async (json) => {
                setIsModalMail(true)
                setActiveContent(false)
            })
            .catch(err => {
                setIsModalErr(true)
                console.log(err)
            })
    }

    return (
        <>
            {activeContent ? <div className='blackout' onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout')) {
                    setIsModal(false)
                }
            }}>
                <div className='password-recovery'>
                    <h2 className='title'
                        onClick={(e) => setIsModal(false)}
                    >Forgot password?</h2>
                    <div className='input-with-btn'>
                        <InputDinamycPlaceholder
                            onChange={e => handlerSetEmail(e)}
                            type="e-mail"
                            classname='autorization__input-field'
                            placeholder='Email'
                            err={errEmail}
                            textErr="Please enter the right email address"
                            value={email}
                            setValue={(e) => setEmail(e)}
                        />
                    </div>
                    <button
                        className='btn__blue'
                        onClick={e => {
                            checkInputField()
                        }}
                    >Send reset instruction</button>
                </div>
            </div >
                :
                <>
                    {
                        isModalErr ? <ModalErr isModal={isModalErr} setIsModal={(e) => setIsModalErr(e)} /> :

                            < Mail
                                title='Check your email address'
                                text={`We sent you the reset instruction`}
                                setIsModal={(e) => setIsModal(e)}
                            />
                    }
                </>

            }
        </>
    )
}

export { PasswordRecoveryPage }