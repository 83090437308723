import { useEffect, useState } from 'react'
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import initTranslations from '../../../../i18n'
import './AccAuthBlock.scss'

const AccAuthBlock = ({
    attr_name,
    attribute_name,
    description,
    service_id,
    mp_name,
    mp_id,
    setData = false,
    attribute_value = '',
    required,
    setIsEmptyReqired,
    error
}) => {
    const [i18n, seti18n, ready] = useState({}, { useSuspense: false })
  
    const translate = i18n?.t
    const t = key => {
        if (typeof translate === 'function') {
            return translate(key)
        }
    }
  
    const initTranslation = async () => {
        const { i18n } = await initTranslations('en', ["Modal"])
        seti18n(i18n)
    }
  
    useEffect(() => {
        initTranslation()
    }, []);
    const [inputValue, setInputValue] = useState(attribute_value ? attribute_value : '')

    useEffect(() => {
        if((typeof setIsEmptyReqired === 'function') & required){
            setIsEmptyReqired(prev => ({...prev, [service_id]: !inputValue?.length > 0}))
        }
    }, [inputValue])

    return (
        <div className='acc-auth__input'>
            <InputDinamycPlaceholder
                onChange={value => {
                    setInputValue(value)
                    if (setData) {
                        setData(prev => ({
                            ...prev,
                            [service_id]: value
                        }))
                    }
                }}
                changeValue={inputValue}
                type="text"
                placeholder={attribute_name}
                autoComplete={false}
                tooltipStatic={error & required & inputValue?.length < 1 ? '' : description}
                err={error & required & inputValue?.length < 1}
                textErr={t('Modal:field_required')}
            />
        </div>
    )
}

export { AccAuthBlock }