import { Link } from 'react-router-dom';
import { useState } from 'react';
import "./ButtonCopy.scss"

const ButtonCopy = ({ value }) => {

    const [currentText, setCurrentText] = useState('Copy')

    return (
        <div className='button-copy' >
            <button className='btn-copy'
                onBlur={() => setCurrentText('Copy')}
                onClick={() => {
                    navigator.clipboard.writeText(value)
                    setCurrentText('Copied')
                }}
            ></button>
            <div className='tooltip_black'>{currentText}</div>

        </div>
    )
}

export { ButtonCopy }