import { useState } from 'react'
import { TableBox } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_ProductVariants.scss"

const ProductCardExtended_ProductVariants = ({tableData, tableCallback, chartData, paginatorRef}) => {

    return (
        <section className='product-variants-inside-content'>
            <TableBox
                {...tableData}
                ref={paginatorRef}
                sortingFunc={tableCallback}
                paginator={true}
                fetchCallback={tableCallback}
            />
        </section>
    )
}

export { ProductCardExtended_ProductVariants }