import { useState } from 'react'
import { TableBox } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_Stocks.scss"

const ProductCardExtended_Stocks = () => {

    return (
        <section className='stocks-inside-content'>
            <TableBox
                loading={null}
                fetchedData={[]}
                headers={[]}
                paginator={true}
            />
        </section>
    )
}

export { ProductCardExtended_Stocks }