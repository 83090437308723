// import customHeaders, {getSpace} from "../../common/headers";
import headers from '../../common/headers';
import add_icon from './img/add.svg'
import { useDataTable } from "../../common/hooks";
import { useState, useRef, useEffect } from "react";
import { KonstantinMasterHttps, KonstantinMasterHttp } from './../../fetchUrls';
import { ButtonBasic, TableBox, DropDownSelector, RangeBox } from "@lk-gtcom/ecomlab-components";
import useGeneralStore from "../../store/general";
import initTranslations from "../../i18n"
import AddBalanceModal from '../../components/AddBalanceModal';
import './FinanceByPeriod.scss'

const namespaces = ['ByPeriod']

const FinanceByPeriod = () => {
    const [i18n, seti18n, ready] = useState({}, { useSuspense: false })

    const translate = i18n?.t
    const t = key => {
        if (typeof translate === 'function') {
            return translate(key)
        }
    }

    const initTranslation = async () => {
        const { i18n } = await initTranslations('en', namespaces)
        seti18n(i18n)
    }

    useEffect(() => {
        initTranslation()
    }, []);

    const options_period = [
        {
            label: t('ByPeriod:days'),
            value: 'День'
        },
        {
            label: 'неделям',
            value: 'Неделя'
        },

        {
            label: 'месяцам',
            value: 'Месяц'
        }
    ]

    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    // const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [selectedPeriod, setSelectedPeriod] = useState({
        label: 'days',
        value: 'День'
    })
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs
    const [isModalBalance, setIsModalBalance] = useState(false)


    const fetchTableData = (params, abortController = new AbortController()) => {
        let sort
        let filters

        if (params) {
            [sort, filters] = params
        }

        const [date_from, date_to] = date
        const orderParam = sort?.[0] ? `&order_param=${sort?.[0]}&order_param_direction=${sort?.[1]}` : ''


        setLoading(true)
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinMasterHttps}/finance/by-period/reports-by-period?period=${selectedPeriod?.value}${orderParam}`
        } else {
            url = `${KonstantinMasterHttp}/finance/by-period/reports-by-period?period=${selectedPeriod?.value}${orderParam}`
        }


        const body = JSON.stringify({
            date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
            date_to: new Date(date_to)?.toISOString().split('T')?.[0],
            filters,
            limit: paginatorRef.current?.limit,
            page: paginatorRef.current?.page,
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setTable(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })
    }


    return (
        <>
            {
                isModalBalance &&
                <AddBalanceModal
                    setIsModalActive={e => setIsModalBalance(false)}
                />
            }

            <div className='finance-by-period'>
                <div className='finance-by-period__header'>
                    <h1 className='finance-by-period__title'>{t('ByPeriod:By_periods')}</h1>
                    <ButtonBasic
                        green
                        width='190px'
                        onClick={(e) => {
                            setIsModalBalance(true)
                        }}
                        text={<div className="content-btn"><img src={add_icon} alt="icon" />{t('ByPeriod:Top_up_balance')}</div>}
                    />
                </div>

                <div className="finance-by-period__table">
                    <div className="finance-by-period__header-table">
                        {/* <h2 className="finance-by-period__title_period">Отчеты по периодам</h2> */}

                        <div className='range-group-btn-and-range-box'>
                            {<div className='drop-box'>
                                <p className='text'>{t('ByPeriod:By')}</p>
                                <DropDownSelector
                                    options_prop={options_period}
                                    state={selectedPeriod}
                                    setState={e => setSelectedPeriod(e)}
                                    defaultValue={selectedPeriod}
                                    className='connections_page_selector'
                                    isClearable={false}
                                />
                            </div>}

                            <RangeBox lang={'en'} setDate={e => setDate(e)} />
                        </div>
                    </div>

                    <TableBox
                        ref={paginatorRef}
                        {...tableProps}
                        paginator={true}
                    />
                </div>
            </div>
        </>

    )
}

export { FinanceByPeriod }
