import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import "./TooltipLightBlue.scss"

const TooltipLightBlue = ({ child, text, top, left, right, maxWidth }) => {

    return (
        <div className='tooltip-container'>
            {child}
            <div className='tooltip-light-blue'
                style={{
                    top: top ? top : '',
                    left: left ? left : '',
                    right: right ? right : '',
                    maxWidth: maxWidth ? maxWidth : ''
                }}
            >{text}</div>
        </div>
    )
}

export { TooltipLightBlue }