import AddBalanceModal from '../../components/AddBalanceModal';
import { Page404 } from '../Page404/Page404';
import { Employees } from '../../components/Employees/Employees';
import { NotActive } from '../../components/NotActive/NotActive';
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import { DownloadHistory } from '../../components/DownloadHistory/DownloadHistory';
import { AccountManagement } from '../../components/AccountManagement/AccountManagement';
import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { Summary } from '../Summary/Summary';
import { Payment } from '../Payment/Payment';
import { TariffPlan } from '../../components/TariffPlan/TariffPlan';
import { FinanceByPeriod } from '../FinanceByPeriod/FinanceByPeriod';
import { FinanceForServices } from '../FinanceForServices/FinanceForServices';
import "./Settings.scss"

const sidebarItemArr = [
    {
        label: 'Account management',
        subItems: [],
        path: 'account-management/my-details'
    },
    {
        label: 'Finance',
        // path: 'finance',
        subItems: [
            {
                label: 'Payment',
                path: 'payment',
                subItems: [],
            },
            {
                label: 'Summary',
                subItems: [],
                path: 'summary'
            },
            {
                label: 'By periods',
                path: 'by-periods',
                subItems: [],
            },
            {
                label: 'By services',
                path: 'for-services',
                subItems: [],
            },
            {
                label: 'Tariffs',
                path: 'tariff',
                subItems: [],
            }
        ],
    },
    {
        label: 'Download history',
        path: 'download-history',
        subItems: [],
    },
    {
        label: 'Employees',
        path: 'employees',
        subItems: [],
    },
]

const sidebarItemArrTur = [
    {
        label: 'Hesap Yönetimi',
        subItems: [],
        path: 'account-management/my-details'
    },
    {
        label: 'Finans',
        // path: 'finance',
        subItems: [
            {
                label: 'Ödeme',
                path: 'payment',
                subItems: [],
            },
            {
                label: 'Özet',
                subItems: [],
                path: 'summary'
            },
            {
                label: 'Dönemlere göre',
                path: 'by-periods',
                subItems: [],
            },
            {
                label: 'Hizmetlere göre',
                path: 'for-services',
                subItems: [],
            },
            {
                label: 'Tarifeler',
                path: 'tariff',
                subItems: [],
            }
        ],
    },
    {
        label: 'İndirme geçmişi',
        path: 'download-history',
        subItems: [],
    },
    {
        label: 'Çalışanlar',
        path: 'employees',
        subItems: [],
    },
]


const Settings = ({
    setIsModalActive,
    isModalActive,
    setConnectedModal
}) => {
    const pathMain = useParams()
    const navigate = useNavigate()
    const [hideMenu, setHideMenu] = useState(true)
    const [isBalanceModalActive, setIsBalanceModalActive] = useState(false)
    const [isActive, setIsActive] = useState('')
    const [isActiveItems, setIsActiveItems] = useState('')
    const [sidebarItems, setSidebarItems] = useState(sidebarItemArr);

    useEffect(() => {

        // После перезагрузки страницы, посвечиваем корректные пункты меню

        sidebarItems?.forEach(({ label, subItems, path }) => {
            const title = label
            const comparePaths = path && (pathMain['*'].split('/')[0] == path.split('/')[0])
            if (comparePaths) {
                setIsActive(null)
                setIsActiveItems(label)
            } else {
                subItems.forEach(({ label, path }) => {
                    if (comparePaths) {
                        setIsActive(title + label)
                        setIsActiveItems(null)
                    }
                })
            }
        })

        if (pathMain['*'].split('/')[0] == 'not-active') {
            navigate('connections')
            setIsActive('')
            setIsActiveItems('Список подключений')
        }
    }, [])

    useEffect(() => {
        if (pathMain['*'].split('/')[0] == 'download-history') {
            setIsActive('')
            setIsActiveItems('История загрузок')
        }

        if (pathMain['*'].split('/')[0] == 'connections') {
            setIsActive('')
            setIsActiveItems('Список подключений')
        }
    }, [pathMain]);



    return (
        <div className='settings__content'>
            <div className='flex'>
                <div className='title-and-nav-box'>
                    <div className='sidebar-inside'>
                        <nav className='sidebar-inside__nav'>
                            {sidebarItems.map((el, idx, { label }) => <SidebarMenu {...el} isActiveItems={isActiveItems} setIsActiveItems={e => setIsActiveItems(e)} setActive={e => setIsActive(e)} active={isActive} key={label + '' + idx} />)}
                        </nav>
                    </div>
                </div>
                <div className='settings__main-box'>
                    <Routes>
                        {/* Финансы */}
                        <Route path='summary' element={<Summary />} />
                        <Route path='payment' element={<Payment />} />
                        <Route path='tariff' element={<TariffPlan />} />
                        <Route path='by-periods' element={<FinanceByPeriod />} />
                        <Route path='for-services' element={<FinanceForServices />} />


                        <Route path='account-management/*' element={<AccountManagement
                              setIsModalActive={(e) => setIsModalActive(e)}
                              isModalActive={isModalActive}
                              setConnectedModal={(e) => setConnectedModal(e)}/>} />
                        <Route path='employees' element={<Employees />} />
                        <Route path='download-history' element={<DownloadHistory />} />
                        <Route path='not-active' element={<NotActive />} />
                        <Route path='*' element={< Page404 />} />

                    </Routes>
                </div>
            </div>
            {isBalanceModalActive && <AddBalanceModal setIsModalActive={e => setIsBalanceModalActive(e)} />}
        </div>
    )
}

export { Settings }