import { useNavigate } from 'react-router-dom';
import "./ProfileSettings.scss"

const ProfileSettings = ({ onLogOutHandler }) => {

    const navigate = useNavigate();

    return (
        <div className='profile-settings'>
            <button className='profile-settings__btn' onClick={(e) => navigate(`/settings/account-management/my-details`)}>Settings</button>
            <button className='profile-settings__btn'>Language</button>
            <button className='profile-settings__btn profile-settings__btn_exit' onClick={e => onLogOutHandler(e)}>Logout</button>
        </div>
    )
}

export { ProfileSettings }