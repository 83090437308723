import { MoonLoader } from 'react-spinners';
import arrow_green from './img/arrow_green.svg'
import arrow_red from './img/arrow_red.svg'
import './CardDynamic.scss'

const CardDynamic = ({ title, value, loading, units, prev, dynamic = 0 }) => {

    const dynamicElement = (dynamic) => {

        if (dynamic > 0) {
            return <div className='dynamic_positive'><img src={arrow_green} />{dynamic + ' %'}</div>
        } else if (dynamic < 0) {
            return <div className='dynamic_negative'><img src={arrow_red} />{dynamic + ' %'}</div>
        } else return <div className='dynamic_neutral'>{'0 %'}</div>
    }

    return (
        <div className='card-dynamic' key={title + value}>
            {
                loading ?
                    <div className='center' style={{ width: '100%', height: '86px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <MoonLoader
                            size={20}
                            color="#7b7b7b"
                            speedMultiplier={0.5}
                        />
                    </div>
                    :
                    <>
                        <h3 className='title_min'>{title ? title : '-'}</h3>
                        <div className='card-dynamic__info'>
                            <p className='text'>{value ? value : '-'} <span>{units ? units : ''}</span></p>
                             {dynamicElement(dynamic)}
                        </div>                      
                        <p className='text_grey'>{`${(prev || prev == 0) ? prev : '-'} for prev. period`}</p>
                    </>
            }
        </div>
    )
}

export { CardDynamic }