import { useState } from 'react'
import { LineChart } from '../../charts/LineChart/LineChart'
import { TableBox, DropContainer } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_Sellers.scss"

const ProductCardExtended_Sellers = ({
    tableData,
    tableCallback,
    chartData,
    metricsList,
    selectedMetric,
    setSelectedMetric,
    paginatorRef,
    onCheck
}) => {

    const [showDropBox, setShowDropBox] = useState(true)


    return (
        <section className='general-indicators-inside-content'>
            <DropContainer
                lang='en'
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
                metricsList={metricsList}
                selectedMetric={selectedMetric}
                setSelectedMetric={setSelectedMetric}
            >
                <LineChart
                    {...chartData}
                    lang='en'
                />
            </DropContainer>

            <TableBox
                {...tableData}
                paginator={true}
                onCheck={(e) => onCheck(e)}
                fetchCallback={tableCallback}
                sortingFunc={tableCallback}
                paginatorRef={paginatorRef}
            />
        </section>
    )
}

export { ProductCardExtended_Sellers }