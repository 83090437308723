import { useState } from 'react'
import { LineChart } from '../../charts/LineChart/LineChart'
import { TableBox, DropContainer, Tabs } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_Competitors.scss"

const btn_tab = [
    {
        label: 'По ключевым словам',
        value: 'by_keywords',
    },
    {
        label: 'По категориям',
        value: 'by_category',
    },
    {
        label: 'По брендам',
        value: 'by_brand',
    },
    {
        label: 'По продавцу',
        value: 'by_seller',
    }
]

const ProductCardExtended_Competitors = () => {

    const [showDropBox, setShowDropBox] = useState(true)
    const [pageValue, setPageValue] = useState(btn_tab[0].value)

    return (
        <section className='competitors-inside-content'>
            <DropContainer
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
            >
                <LineChart
                    legendPosition='top'
                    datasets_prop={[]}
                    labels_prop={[]}
                    loading={null}
                    lang='en'
                />
            </DropContainer>

            <Tabs tabs={btn_tab} pageValue={pageValue} setPageValue={e => setPageValue(e)} stopRedirect />

            <TableBox
                loading={null}
                fetchedData={[]}
                headers={[]}
                paginator={true}
            />
        </section>
    )
}

export { ProductCardExtended_Competitors }