import headers from '../../common/headers';
import { LineChart } from '../../charts/LineChart/LineChart';
import { useDataTable } from '../../common/hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Andrey2Http, Andrey2Https } from '../../fetchUrls';
import { useState, useEffect, useRef } from 'react'
import { CardStatistics, SearchComponent, Tabs, TableBox, RangeBox, CheckboxToggleBlue } from "@lk-gtcom/ecomlab-components";
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import "./NicheSearchVer2.scss"
import { Helmet } from 'react-helmet-async';

const list_statistic = [
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
    {
        label: 'ㅤ',
        value: 'ㅤ'
    },
]

const btn_tab = [
    {
        label: 'Dashboard',
        value: 'dashboard'
    },
    {
        label: 'Products',
        value: 'goods'
    },
    {
        label: 'Brands',
        value: 'brands'
    },
    {
        label: 'Sellers',
        value: 'sellers'
    }
]

const NicheSearchVer2 = () => {
    const [pageValue, setPageValue] = useState('parameters')

    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const activeTab = useParams()['*']

    const [searchData, setSearchData] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [search, setSearch] = useState('')
    const [searchLoading, setSearchLoading] = useState(false)
    const [errSearch, setErrSearch] = useState(false)
    const [isSearchLastPage, setIsSearchLastPage] = useState(false)

    const [chartRevenueData, setChartRevenueData] = useState([])
    const [chartRevenueLoading, setChartRevenueLoading] = useState(false)

    const [chartSellersData, setChartSellersData] = useState([])
    const [chartSellersLoading, setChartSellersLoading] = useState(false)

    const [chartGoodsData, setChartGoodsData] = useState([])
    const [chartGoodsLoading, setChartGoodsLoading] = useState(false)

    const [metricsBlocks, setMetricsBlocks] = useState([])

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs
    const [searchParams, setSearchParams] = useSearchParams()
    const [checkFbs, setCheckFbs] = useState(false)


    const resetFilter = () => {
        setIsSearchLastPage(false)
        setSearchData([])
    }

    const fetchFilter = ([search, page = 1], abortController = new AbortController()) => {
        if (search?.length >= 3) {

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v1/gtcom/niche_search/niche_analysis/search_string/`
            } else {
                url = `${Andrey2Http}/api/v1/gtcom/niche_search/niche_analysis/search_string/`
            }

            let [date_from, date_to] = date

            date_from = new Date(date_from+86401000)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                category_search: search,
                page: 1,
                limit: 15,
                date_from,
                date_to,
            })


            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    if (Array.isArray(json)) {
                        setSearchData(json.map(el => el?.category_name));
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    const fetchAllAccountData = (params, abortController = new AbortController()) => {
        if (searchValue?.length >= 3) {
            let sort;
            let filtersParam;

            if (params) {
                [sort, filtersParam] = params
            }
            setLoading(true)


            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v2/gtcom/niche_search/${pageValue}/table/`
            } else {
                url = `${Andrey2Http}/api/v2/gtcom/niche_search/${pageValue}/table/`
            }

            let [date_from, date_to] = date
            date_from = new Date(date_from+86401000)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                category_filter: searchValue,
                limit: paginatorRef.current?.limit,
                page: paginatorRef.current?.page,
                fbofbs_filter: checkFbs,
                date_from,
                date_to,
                sort_name: sort?.[0],
                sort_order: sort?.[1],
                filters: filtersParam,
            })


            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    if (!Array.isArray(json)) {
                        setTable(json)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchChartData = (path, setState, setLoading, abortController = new AbortController()) => {
        if (searchValue?.length >= 3) {
            setLoading(true)
            setState({})

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v1/gtcom/niche_search/${pageValue}/${path}/graph/`
            } else {
                url = `${Andrey2Http}/api/v1/gtcom/niche_search/${pageValue}/${path}/graph/`
            }


            let [date_from, date_to] = date
            date_from = new Date(date_from+86401000)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                category_filter: searchValue,
                fbofbs_filter: checkFbs,
                date_from,
                date_to,
                limit: 10,
                page: 1,
            })


            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    const { datasets: { data, label, type, tooltips, multiAxisData, multiAxisKeys }, labels } = json

                    setState({ multiAxisData_prop: multiAxisData, multiAxisKeys_prop: multiAxisKeys, type, tooltips, datasets_prop: data, labels_prop: labels, data_name: label })
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchMetricsBlock = (abortController = new AbortController()) => {
        if (searchValue?.length >= 3) {
            setLoading(true)


            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v1/gtcom/niche_search/${pageValue}/metrics_block/`
            } else {
                url = `${Andrey2Http}/api/v1/gtcom/niche_search/${pageValue}/metrics_block/`
            }

            let [date_from, date_to] = date            
            date_from = new Date(date_from+86401000)?.toISOString().split('T')?.[0]
            date_to = new Date(date_to)?.toISOString().split('T')?.[0]

            const body = JSON.stringify({
                category_filter: searchValue,
                limit: paginatorRef.current?.limit,
                page: paginatorRef.current?.page,
                date_from,
                date_to,
            })


            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setMetricsBlocks(prev => [
                        ...prev,                   
                        ...json.map(json => {
                            const { labels, data, unit } = json
                            const { header } = labels
                            
                            const arr = header.map((el, ind) => ({ title: el, value: data?.[ind], units: unit?.[ind]  }))?.[0]
                            return ({ data: arr })
                        })
                    ])
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const onRedirect = e => {
        const [__, link] = e
        window.open(link, '_blank')
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (pageValue !== 'dashboard') {
            fetchAllAccountData([], abortController)
        }

        if (pageValue === 'dashboard') {
            setMetricsBlocks([])
            fetchMetricsBlock(abortController)
            fetchChartData('revenue_sales', setChartRevenueData, setChartRevenueLoading, abortController)
            fetchChartData('sellers_revenue', setChartSellersData, setChartSellersLoading, abortController)
            fetchChartData('goods_count', setChartGoodsData, setChartGoodsLoading, abortController)
        }

        return () => abortController.abort()
    }, [pageValue, searchValue, date, checkFbs]);

    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    useEffect(() => {
        const query = searchParams.get('query')
        setSearch({ label: query, value: query })
    }, [searchParams])

    return (

        <div className='niche-search-ver2'>
            <Helmet>
                <title>Niche search</title>
            </Helmet>
            <h1 className='title_main'>Niche  analysis</h1>
            <div className='toolbar-top'>
                <SearchComponent
                    fetchCallback={e => {
                        const abortController = new AbortController()
                        fetchFilter(e, abortController)
                        return () => abortController.abort()
                    }}
                    fetchData={e => {
                        if (pageValue !== 'dashboard') {
                            fetchAllAccountData(e)
                        }
                    }}
                    loading={searchLoading}
                    data={searchData}
                    resetData={resetFilter}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    errSearch={errSearch}
                    setErrSearch={(e) => setErrSearch(e)}
                    value={search}
                    placeholder='Search...'
                    errText=''
                    isSearchLastPage={isSearchLastPage}
                    lang="en"
                />
                <TooltipLightBlue
                    text={<p className='text_tooltip'>FBO (sales from the site's warehouse) is the default. Click on the switch to see FBS (sales from the site's warehouse)</p>}
                    top='32px'
                    left='0px'
                    maxWidth='110px'
                    child={
                        <label className='checkbox-container'>
                            <CheckboxToggleBlue
                                value={checkFbs}
                                onChange={(e) => setCheckFbs(!checkFbs)}
                            />
                            <p className='text_label'>FBS</p>
                        </label>
                    }
                />
            </div>

            <div className='toolbar-medium'>
                <Tabs tabs={btn_tab} pageValue={pageValue} />
                <RangeBox lang='en' setDate={e => setDate(e)} />
            </div>

            {
                pageValue === 'dashboard' ?
                    <div className='charts-and-cards-box'>
                        <div className='cards-box'>
                            {
                                metricsBlocks?.length > 0 ?
                                    metricsBlocks.map(({ data }, ind) => {
                                        return <CardStatistics
                                            key={JSON.stringify(data) + ind}
                                            {...data}
                                        />
                                    })
                                    :
                                    list_statistic.map(({ value, label }, ind) => {
                                        return <CardStatistics
                                            key={ind + label}
                                            title={label}
                                            value={value}
                                        />
                                    })
                            }
                        </div>



                        <div className='charts-group'>
                            <LineChart
                                direction='y'
                                legendPosition='bottom'
                                {...chartRevenueData}
                                loading={chartRevenueLoading}
                                nameChart='Revenue and Sales'
                                maxHeight='300px'
                            />

                            <LineChart
                                direction='y'
                                legendPosition='bottom'
                                {...chartSellersData}
                                loading={chartSellersLoading}
                                nameChart='Sellers and Revenue'
                                maxHeight='300px'
                            />

                            <LineChart
                                direction='y'
                                legendPosition='bottom'
                                {...chartGoodsData}
                                loading={chartGoodsLoading}
                                nameChart='Goods count'
                                maxHeight='300px'
                            />
                        </div>
                    </div>
                    :
                    <TableBox
                        {...tableProps}
                        paginator={true}
                        ref={paginatorRef}
                        fetchCallback={e => fetchAllAccountData(e)}
                        onRedirect={e => onRedirect(e)}
                    />
            }
        </div >
    )
}

export { NicheSearchVer2 }