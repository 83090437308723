
import "./CardStatisticsVer2.scss"

const CardStatisticsVer2 = ({ label, value, unit, dynamic }) => {
    return (
        <div className='card-statistics-ver2'>
            <h4 className='card-item__title'>{label}</h4>
            <div className='value-and-dynamic'>
                <p className='card-item__value-content'>{typeof +value == 'number' ? value?.toLocaleString() : value}
                    <span> {unit}</span>
                </p>
               {(dynamic || dynamic === 0)  && <div className={dynamic >= 0 ? 'dynamic_violet' : 'dynamic_red'}>{`${dynamic}%`}</div>}
            </div>
        </div>
    )
}

export { CardStatisticsVer2 }