import headers from '../../common/headers';
import not_img from './img/not-img.svg';
import star_icon from './img/star_icon.svg';
import description_tab from './img/description_tab.svg';
import brand_icon from './img/brandIcon.svg';
import seller_icon from './img/categoryIcon.svg';
import category_icon from './img/category_icon.svg';
import marketplace_icon from './img/marketplace_icon.svg';
import { Page404 } from '../Page404/Page404';
import { BarCharts } from '../../charts/BarCharts/BarCharts';
import { MoonLoader } from 'react-spinners';
import { ButtonCopy } from '../../components/ButtonCopy/ButtonCopy';
import { CardDynamic } from '../../components/CardDynamic/CardDynamic';
import { useChartData } from '../../common/hooks';
import { useDataTable } from '../../common/hooks';
import { TooltipLightBlue } from '../../components/Tooltip/TooltipLightBlue/TooltipLightBlue';
import { CardStatisticsVer2 } from '../../components/CardStatisticsVer2/CardStatisticsVer2';
import { ProductCardExtended_Stocks } from '../ProductCardExtended_Stocks/ProductCardExtended_Stocks';
import { ProductCardExtended_Mapping } from '../ProductCardExtended_Mapping/ProductCardExtended_Mapping';
import { ProductCardExtended_Position } from '../ProductCardExtended_Position/ProductCardExtended_Position';
import { AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls';
import { ProductCardExtended_Feedbacks } from '../ProductCardExtended_Feedbacks/ProductCardExtended_Feedbacks';
import { ProductCardExtended_Information } from '../ProductCardExtended_Information/ProductCardExtended_Information';
import { ProductCardExtended_Description } from '../ProductCardExtended_Description/ProductCardExtended_Description';
import { ProductCardExtended_Competitors } from '../ProductCardExtended_Competitors/ProductCardExtended_Competitors';
import { ProductCardExtended_StockBalances } from '../ProductCardExtended_StockBalances/ProductCardExtended_StockBalances';
import { ProductCardExtended_OrdersAndSales } from '../ProductCardExtended_OrdersAndSales/ProductCardExtended_OrdersAndSales';
import { ProductCardExtended_RelatedProducts } from '../ProductCardExtended_RelatedProducts/ProductCardExtended_RelatedProducts';
import { ProductCardExtended_ProductVariants } from '../ProductCardExtended_ProductVariants/ProductCardExtended_ProductVariants';
import { ProductCardExtended_AdvertisingPlace } from '../ProductCardExtended_AdvertisingPlace/ProductCardExtended_AdvertisingPlace';
import { ProductCardExtended_GeneralIndicators } from '../ProductCardExtended_GeneralIndicators/ProductCardExtended_GeneralIndicators';
import { ProductCardExtended_FinancialAnalysis } from '../ProductCardExtended_FinancialAnalysis/ProductCardExtended_FinancialAnalysis';
import { ProductCardExtended_ProductImprovement } from '../ProductCardExtended_ProductImprovement/ProductCardExtended_ProductImprovement';
import { ProductCardExtended_AdvertisingCompanies } from '../ProductCardExtended_AdvertisingCompanies/ProductCardExtended_AdvertisingCompanies';
import { ProductCardExtended_Sellers } from '../ProductCardExtended_Sellers/ProductCardExtended_Sellers';
import { useState, useEffect, useRef, createContext } from 'react';
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom';
import {
  Tabs,
  TableBox,
  RangeBox,
  DropDownSelector,
  CheckboxToggleBlue,
} from '@lk-gtcom/ecomlab-components';
import './ProductCardExtended.scss';
import { addFiltersByDataObj, addFiltersToUrlQuery, getDataObjByFilters, getFiltersByUrlQuery } from '../../common/utils'
import useGeneralStore from '../../store/general';

const btn_tab = [
  {
    label: 'General data',
    value: 'general'
  },
  {
    label: 'Content',
    value: 'description'
  },
  {
    label: 'Reviews',
    value: 'reviews',
  },
  {
    label: 'Colors and sizes',
    value: 'product_variants',
  },
  {
    label: 'Orders and sales',
    value: 'orders_and_sales',
  },
  {
    label: 'Product position',
    value: 'position',
  },
  {
    label: 'Sellers',
    value: 'sellers',
  },
  {
    label: 'Stock balances',
    value: 'stock_balances',
  },
]
const btn_tab_2 = [
  {
    label: 'Категории',
    value: 'categories',
    icon: description_tab,
  },
  {
    label: 'Поисковые запросы',
    value: 'search',
    icon: description_tab,
  },
];

const btn_tab_3 = [
  {
    label: 'Продажи',
    value: 'sales',
  },
  {
    label: 'Цена',
    value: 'price',
  },
  {
    label: 'Остатки',
    value: 'stock',
  },
  {
    label: 'Выручка',
    value: 'revenue',
  },
];

const options_period = [
  {
    label: 'дням',
    value: 'day',
  },
  {
    label: 'неделям',
    value: 'week',
  },

  {
    label: 'месяцам',
    value: 'month',
  },
];

const metrics_names = ['7days', '30days', '60days', '90days'];

const metrics_names_info = [
  'first_discovered',
  'sales_revenue',
  'price_stock_reviews',
];

export const DataContext = createContext({});

export const dataActionTypes = Object.seal({
  createProduct: 'create product',
  done: 'done',
});

const ProductCardExtended = ({ title, content }) => {
  const selectedSpace = useGeneralStore(state => state.selectedSpace)

  const currentTab = () => {
    return btn_tab;
  };

  const [query, setQuery] = useState(null);
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [pageValue, setPageValue] = useState(currentTab().value);
  const [pageValue2, setPageValue2] = useState(btn_tab_2[0].value);
  const [pageValue3, setPageValue3] = useState(btn_tab_3[0].value);

  const [fetchedMetrics, setFetchedMetrics] = useState([]);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');

  const [selectedPeriod, setSelectedPeriod] = useState(options_period[0]);
  const [stateSlider, setStateSlider] = useState([]);
  const activeTab = useParams()['*'];
  const [searchParams, setSearchParams] = useSearchParams();

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
  const { loading, sort, filters } = tableProps
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs

  const [generalData, setGeneralData] = useState([]);
  const [generalDataLoading, setGeneralDataLoading] = useState(false);
  const [description, setDescription] = useState('');

  // const [chartData, setChartData] = useState([])
  const [chartDataName, setChartDataName] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [checkFbs, setCheckFbs] = useState(false);
  const [checkedProducts, setCheckedProducts] = useState([]);

  const [product, setProduct] = useState({});
  const [nmid, setNmid] = useState('');
  const [productId, setProductId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [productMetrics, setProductMetrics] = useState([]);
  const [productDesc, setProductDesc] = useState({});
  const [setChartData, chartData, chartActions] = useChartData();

  const [selectedTableMetric, setSelectedTableMetric] = useState(null);
  const [selectedSearchQuery, setSelectedSearchQuery] = useState('');

  const [positionPageValue, setPositionPageValue] = useState('by_keywords');
  const [metricsList, setMetricsList] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState('');
  const [searchData, setSearchData] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [tableMetric, setTableMetric] = useState('')

  function getCategoryPosition(state) {
    const categories = {
      by_keywords: 'keywords',
      by_category: 'category',
      by_brand: 'brand',
      by_seller: 'seller',
    };
    return categories[state] || 'keywords';
  }

  const categoryPosition = getCategoryPosition(positionPageValue);

  const dataState = {
    pageValue3,
    setPageValue3,
    fetchedMetrics,
    chartData,
    labels,
    chartDataName,
    chartLoading,
  };

  useEffect(() => {
    if (pageValue !== 'position') {
      setPositionPageValue('by_keywords');
    }
  }, [pageValue]);

  useEffect(() => {
    if (pageValue == 'description' || pageValue == 'reviews') {
      return
    } else {
      fetchTableData()
      if (pageValue != 'product_variants') {
        fetchChart();
      }
    }
    chartActions?.resetChart();
  }, [pageValue, categoryPosition, checkFbs]);

  useEffect(() => {
    setPageValue(activeTab);
    const nmid_filter = searchParams.get('nmid');
    const product_id_filter = searchParams.get('productId');
    const brand_id_filter = searchParams.get('brandId');

    if (nmid_filter) {
      setNmid(nmid_filter);
    } else {
      if (nmid) {
        setSearchParams({ nmid, productId, brandId });
      }
    }

    if (product_id_filter) {
      setProductId(product_id_filter);
    } else {
      if (productId) {
        setSearchParams({ nmid, productId, brandId });
      }
    }

    if (brand_id_filter) {
      setBrandId(brand_id_filter);
    } else {
      if (productId) {
        setSearchParams({ nmid, productId, brandId });
      }
    }
  }, [activeTab, searchParams]);

  const resetTable = () => {
    paginatorRef?.current?.reset()
    paginatorRef?.current?.setTotal(0)
    // setCheckedProducts([])
    setFetchedData([])
    setHeaders([])
    setSort({})
    setFilters([])
}

  const fetchProductMetrics = (abortController = new AbortController()) => {
    setProductMetrics([])
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v2/gtcom/sku/general/metrics/`;
    } else {
      url = `${AndreySkuHttp}/api/v2/gtcom/sku/general/metrics/`;
    }

    let [date_from, date_to] = date;
    date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0];
    date_to = new Date(date_to)?.toISOString().split('T')?.[0];

    const body = JSON.stringify({
      nm_id_filter: nmid,
      // brand_id_filter: brandId,
      fbofbs_filter: checkFbs,
      date_from,
      date_to,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setProductMetrics(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });
  };

  const [productMetrics2, setProductMetrics2] = useState([])

  const fetchProductMetricsReviews = (abortController = new AbortController()) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v2/gtcom/sku/${pageValue}/metrics/`;
    } else {
      url = `${AndreySkuHttp}/api/v2/gtcom/sku/${pageValue}/metrics/`;
    }

    let [date_from, date_to] = date;
    date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0];
    date_to = new Date(date_to)?.toISOString().split('T')?.[0];

    const body = JSON.stringify({
      nm_id_filter: nmid,
      // brand_id_filter: brandId,
      fbofbs_filter: checkFbs,
      date_from,
      date_to,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setProductMetrics2(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });
  };

  useEffect(() => {
    if (pageValue == 'reviews' || pageValue == 'stock_balances') {
      fetchProductMetricsReviews()
    }
  }, [pageValue, checkFbs, date]);

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort
    let filtersParam

    if (params) {
        [sort, filtersParam] = params
    }

    let options = pageValue === 'options' ? 'product_options/table/' : ''
    let position = pageValue === 'position' ? pageValue2 + '/table/' : pageValue + '/table/'

    setLoading(true)
    let url
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v2/gtcom/sku/${position}${options}`
    } else {
      url = `${AndreySkuHttp}/api/v2/gtcom/sku/${position}${options}`
    }

    const [date_from, date_to] = date


    const body = JSON.stringify({
      nm_id_filter: nmid,
      rating_min: stateSlider?.[0],
      rating_max: stateSlider?.[1],
      fbofbs_filter: checkFbs,
      sort_name: sort && sort[0],
      sort_order: sort && sort[1],
      date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
      page: paginatorRef.current?.page,
      limit: paginatorRef.current?.limit,
      filters,
    })


    fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
      .then(async res => {
        if (res.ok) {
          return res.json()
        } else {
          const err = await res.json()
          throw new Error(JSON.stringify(err))
        }
      })
      .then(json => {
        setTable(json)
        setTableUrl(url)
        setTableBody(body)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        if (abortController.signal.aborted) return
        setLoading(false)
      })

  }

  const fetchCards = (path, abortController = new AbortController()) => {



    let url
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v2/gtcom/sku/${pageValue}/metrics`
    } else {
      url = `${AndreySkuHttp}/api/v2/gtcom/sku/${pageValue}/metrics`
    }

    const [date_from, date_to] = date
    const body = JSON.stringify({
      nmid_filter: searchValue,
      date_from: new Date(date_from + 86401000)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
      fbofbs_filter: checkFbs
    })

    fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
      .then(async res => {
        if (res.ok) {
          return res.json()
        } else {
          const err = await res.json()
          throw new Error(JSON.stringify(err))
        }
      })
      .then(json => {
        const data = Array.isArray(json) ? [...json] : [json]
        setFetchedMetrics(prev => [...prev, ...data])
      })
      .catch(err => {
        if (abortController.signal.aborted) return
        console.error(err)
      })

  }

  const fetchGeneralData = (abortController = new AbortController()) => {
    setGeneralDataLoading(true)
    let url
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v2/gtcom/sku/general/product_card/`
    } else {
      url = `${AndreySkuHttp}/api/v2/gtcom/sku/general/product_card/`
    }

    const body = JSON.stringify({
      nm_id_filter: nmid,
    })


    fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
      .then(async res => {
        if (res.ok) {
          return res.json()
        } else {
          const err = await res.json()
          throw new Error(JSON.stringify(err))
        }
      })
      .then(json => {
        if (json) {
          setGeneralData(json)
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        if (abortController.signal.aborted) return
        setGeneralDataLoading(false)
      })

  }

  const fetchChart = (abortController = new AbortController()) => {
    setChartData([])
    setChartLoading(true)

    let url
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v2/gtcom/sku/${pageValue == 'position' ? pageValue2 : pageValue}/graph/`
    } else {
      url = `${AndreySkuHttp}/api/v2/gtcom/sku/${pageValue == 'position' ? pageValue2 : pageValue}/graph/`
    }

    const [date_from, date_to] = date

    let searchId
    const id_arr = selectedItems?.map(el => {
      if (el) {
        if (pageValue === 'sellers' && el?.seller_id) {
          return el?.seller_id
        }
        if (pageValue === 'position' && el?.Category) {
          return el?.Category[0]
        }
      }
    })
    if (pageValue === 'sellers') {
      searchId = { seller_id: id_arr }
    }
    if (pageValue === 'position') {
      searchId = { category_name: id_arr }
    }

    const body = JSON.stringify({
      request_type: pageValue3,
      nm_id_filter: nmid,
      chrt_id_filter: checkedProducts?.map(({ chrt_id }) => chrt_id),
      date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
      fbofbs_filter: checkFbs,
      metric_name: selectedMetric,
      ...searchId
    })


    fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
      .then(async res => {
        if (res.ok) {
          return res.json()
        } else {
          const err = await res.json()
          throw new Error(JSON.stringify(err))
        }
      })
      .then(json => {
        setChartData(json)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        if (abortController.signal.aborted) return
        setChartLoading(false)
      })

  }

  const fetchDescription = (abortController = new AbortController()) => {
    let url
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v2/gtcom/sku/description_and_characteristics/`
    } else {
      url = `${AndreySkuHttp}/api/v2/gtcom/sku/description_and_characteristics/`
    }

    const body = JSON.stringify({
      nm_id_filter: nmid,
    })


    fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
      .then(async res => {
        if (res.ok) {
          return res.json()
        } else {
          const err = await res.json()
          throw new Error(JSON.stringify(err))
        }
      })
      .then(json => {
        setProductDesc(json);
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        if (abortController.signal.aborted) return
        setChartLoading(false)
      })

  }

  // const fetchMetricsListPosition = () => {
  //   if (pageValue === 'position') {
  //     let url;
  //     if (!window.location.hostname.match('localhost')) {
  //       url = `${AndreySkuHttps}/api/v1/gtcom/sku/position/${categoryPosition}/metric_list/`;
  //     } else {
  //       url = `${AndreySkuHttp}/api/v1/gtcom/sku/position/${categoryPosition}/metric_list/`;
  //     }

  //     fetch(url, {
  //       method: 'GET',
  //       headers,
  //     })
  //       .then(async (res) => {
  //         if (res.ok) {
  //           return res.json();
  //         } else {
  //           const err = await res.json();
  //           throw new Error(JSON.stringify(err));
  //         }
  //       })
  //       .then((json) => {
  //         setMetricsList(json);
  //         setSelectedMetric(json[0].value)
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       })
  //       .finally(() => { });
  //   }
  // };

  const fetchMetricsList = () => {

    setMetricsList([])
    setSelectedMetric('')

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v2/gtcom/sku/${pageValue}/metric_list/`;
    } else {
      url = `${AndreySkuHttp}/api/v2/gtcom/sku/${pageValue}/metric_list/`;
    }

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setMetricsList(json);
        setSelectedMetric(json[0].value)
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => { });

  };

  useEffect(() => {
    const abortController = new AbortController();
    if (nmid) {
      fetchProductMetrics();
    }
    return () => abortController.abort();
  }, [nmid]);

  useEffect(() => {
    if (pageValue == 'description' || pageValue == 'reviews') {
      return
    } else {
      const abortController = new AbortController();
      fetchTableData([], abortController);
      return () => abortController.abort();
    }
  }, [selectedTableMetric]);

  useEffect(() => {
    if (pageValue == 'description' || pageValue == 'reviews') {
      return
    } else {
      const abortController = new AbortController();
      fetchChart(abortController);
      return () => abortController.abort();
    }
  }, [selectedSearchQuery, selectedMetric]);


  useEffect(() => {
    const abortController = new AbortController();
    if (nmid) {
      if (
        pageValue === 'general' ||
        pageValue === 'position' ||
        pageValue === 'category' ||
        pageValue === 'orders_and_sales' ||
        pageValue === 'advertisin_place' ||
        pageValue === 'stock_balances' ||
        pageValue === 'sellers' ||
        pageValue === 'product_variants' ||
        pageValue === 'reviews'
      ) {
        resetTable();
        chartActions?.resetChart();
        fetchTableData([], abortController);

        if ((pageValue == 'reviews' ||
          pageValue == 'product_variants') == false
        ) {
          fetchChart(abortController);
        }

      }

      if (pageValue === 'description') {
        fetchDescription();
      }
      return () => abortController.abort();
    }
    if (searchValue) {
      if (pageValue == 'wasted_revenue') {
        setFetchedMetrics([]);
        metrics_names?.map((path) => fetchCards(path, abortController));
      }

      if (
        pageValue == 'options' ||
        pageValue === 'feedbacks' ||
        pageValue === 'reviews'
      ) {
        resetTable();
        fetchTableData([], abortController);
      }

      if (pageValue == 'description') {
        fetchDescription(abortController);
      }

      if (pageValue == 'information') {
        setFetchedMetrics([]);
        metrics_names_info?.map((path) => fetchCards(path, abortController));
      }

      if (
        pageValue === 'options' ||
        pageValue === 'info' ||
        pageValue == 'wasted_revenue' ||
        pageValue === 'information'
      ) {
        fetchChart(abortController);
      }

      return () => abortController.abort();
    }
  }, [pageValue, pageValue2, pageValue3, searchValue, date, checkFbs]);

  useEffect(() => {
    if (nmid) {
      if (
        pageValue === 'general' ||
        pageValue === 'position' ||
        pageValue === 'category' ||
        pageValue === 'orders_and_sales' ||
        pageValue === 'advertisin_place' ||
        pageValue === 'stock_balances' ||
        pageValue === 'sellers'
      ) {
        fetchMetricsList()
      }
    }
  }, [pageValue]);

  useEffect(() => {
    const abortController = new AbortController();
    if (
      pageValue === 'options' ||
      pageValue === 'info' ||
      pageValue == 'wasted_revenue' ||
      pageValue === 'information' ||
      pageValue === 'sellers' ||
      pageValue === 'position'
    ) {
      fetchChart(abortController);
    }
    return () => abortController.abort();
  }, [checkedProducts, selectedItems]);

  useEffect(() => {
    fetchGeneralData();
  }, [nmid]);

  // useEffect(() => {
  //   const dataObj = getFiltersByUrlQuery(searchParams);
  //   addFiltersByDataObj({
  //     dataObj,
  //     setDate,
  //     setQuery,
  //     // setFbofbs: e => setCheckFbs(e)
  //   })
  // }, [searchParams]);

  // const setQueryByFilters = ({ current }) => {
  //   const queryDataObj = getFiltersByUrlQuery(searchParams);
  //   const dataObj = getDataObjByFilters({
  //     rest: queryDataObj,
  //     date,
  //     fbofbs: `${checkFbs}`,
  //     ...current
  //   });

  //   addFiltersToUrlQuery(dataObj, setSearchParams);
  // }

  const handleCheck = (data) => {
    setSelectedItems(data)
  }

  return (
    <div className="product-card-extended">
      <div className="product-card-extended__main-content">
        <div className="product-card-extended__card-info">
          <div className="product-card-extended__card-info">
            <div className="img-info">
              <div className="img-box">
                {generalData.nm_id ?
                  <img className='img' src={generalData.images} />
                  : <img className='not-img' src={not_img} />
                }
              </div>
              <div className="header-panel">
                <div className="card-product">
                  {generalDataLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        minHeight: '100%',
                      }}
                    >
                      <MoonLoader />
                    </div>
                  ) : (
                    <>
                      <div className="info-content">
                        <div className="article-container">
                          <div className="article-container__content">
                            <div className="text-box">
                              <span className="text-placeholder">Article number:</span>
                              <p className="text">
                                {generalData.nm_id ? generalData.nm_id : '-'}
                              </p>
                            </div>
                          </div>
                          <ButtonCopy
                            value={generalData.nm_id ? generalData.nm_id : ''}
                          />
                        </div>
                        <div className="info-content__item">
                          <div className="info-content__label">
                            <img src={brand_icon} />
                            <span className="text-placeholder">Brand</span>
                          </div>
                          <div className="text-box">
                            <p className="text">
                              {generalData.brand_name ? generalData.brand_name : 'Нет названия'}
                            </p>
                          </div>
                        </div>

                        <div className="info-content__item">
                          <div className="info-content__label">
                            <img src={category_icon} />
                            <span className="text-placeholder">Categories</span>
                          </div>
                          <div className="text-box">
                            <p className="text">
                              {generalData.categories ? generalData.categories : 'Нет названия'}
                            </p>
                          </div>
                        </div>

                        <div className="info-content__item">
                          <div className="info-content__label">
                            <img src={seller_icon} />
                            <span className="text-placeholder">Number of sellers</span>
                          </div>
                          <div className="text-box">
                            <p className="text">
                              {generalData?.seller ? generalData?.seller : 'Нет названия'}
                            </p>
                          </div>
                        </div>

                        {/* <div className='rating'>
                                <Rating
                                    precision={0.2}
                                    defaultValue={4.5}
                                    max={5}
                                    readOnly
                                    emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
                                    icon={<StarIcon style={{ fill: '#faaf00' }} />}
                                    size="large"
                                />
                                <p>Rate <span>4,5</span></p>
                            </div>
                        </div> */}

                        <div className='info-content__item'>
                          <div className='review-container'>
                            <p className='text_rating'>
                              <img src={star_icon} />
                              {generalData?.rating ? generalData?.rating : '-'}
                            </p>
                            <p className='text'>reviews - <span>{generalData?.count_reviews ? generalData?.count_reviews : '-'}</span></p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="cards-container">
              {productMetrics?.length > 0
                ? productMetrics?.map((data, i) => (
                  <CardDynamic key={JSON.stringify(data)} {...data} />
                ))
                : [...Array(5)]?.map((e, i) => (
                  <CardDynamic key={i} title={''} dynamic={''} value={''} />
                ))}
            </div>
          </div>
        </div>

        <div className="product-card-extended__right-content">
          <div className="product-card-extended__content">
            <div className="product-card-extended__toolbar-top">
              {title && (
                <a href={generalData?.trendyol_link} target='_blank'>
                  <h1 className="title_main">
                    {title} : {generalData?.product_name}
                  </h1>
                </a>
              )}

              <div className="range-group-btn-and-range-box">
                {pageValue == 'by_days' && (
                  <div className="drop-box">
                    <p className="text">По</p>
                    <DropDownSelector
                      options_prop={options_period}
                      state={selectedPeriod}
                      setState={(e) => setSelectedPeriod(e)}
                      defaultValue={selectedPeriod}
                      className="connections_page_selector"
                      isClearable={false}
                    />
                  </div>
                )}

                {/* Нужный элемент - не удалять */}

                {/* <div className='checkbox-group-filters'>
                                <p className='text'>Склад:</p>
                                <div className='checkbox-group-filters__checkbox-group'>
                                    <Checkbox
                                        value={checkMp}
                                        onChange={(e) => setCheckMp(e)}
                                    >
                                        Маркетплейс
                                    </Checkbox>

                                    <Checkbox
                                        value={checkSeller}
                                        onChange={(e) => setCheckSeller(e)}
                                    >
                                        Продавца
                                    </Checkbox>
                                </div>
                            </div> */}

                {/* <div className='checkbox-group-filters'>
                                <p className='text'>Товары:</p>
                                <div className='checkbox-group-filters__checkbox-group'>
                                    <Checkbox
                                        value={checkZeroBalance}
                                        onChange={(e) => setCheckZeroBalance(e)}
                                    >
                                        С нулевым остатком
                                    </Checkbox>
                                </div>
                            </div> */}

                <TooltipLightBlue
                  text={<p className='text_tooltip'>
                    FBO (sales from the site's warehouse) is the default.
                    Click on the switch to see FBS
                    (sales from the site's warehouse)</p>}
                  top='32px'
                  left='0px'
                  maxWidth='110px'
                  child={
                    <label className="checkbox-container">
                      <CheckboxToggleBlue
                        value={checkFbs}
                        onChange={(e) => {
                          setCheckFbs(!checkFbs)
                          // setQueryByFilters({ current: { fbofbs: !checkFbs } })
                        }}
                      />
                      <p className="text_label">FBS</p>
                    </label>
                  }
                />

                <RangeBox
                  lang='en'
                  setDate={(e) => {
                    setDate(e)
                    // setQueryByFilters({ current: { date: e } })
                  }} />
              </div>
            </div>
            <Tabs
              tabs={currentTab()}
              // query={`?mp=${query}`}
              pageValue={pageValue}
            />

            <div className="changed-content">
              <div className="left-content">
                <DataContext.Provider value={dataState}>
                  <Routes>
                    {/* Общие показатели */}
                    <Route
                      path="/general"
                      element={
                        <ProductCardExtended_GeneralIndicators
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          paginatorRef={paginatorRef}
                          tableCallback={fetchTableData}
                          chartData={chartData}
                          metricsList={metricsList}
                          selectedMetric={selectedMetric}
                          setSelectedMetric={setSelectedMetric}
                        />
                      }
                    />
                    {/* Описание и характеристики */}
                    <Route
                      path="/description"
                      element={
                        <ProductCardExtended_Description
                          // description={description}
                          {...productDesc}
                        />
                      }
                    />
                    {/* Позиция товара */}
                    <Route
                      path="/position"
                      element={
                        <ProductCardExtended_Position
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          pageValue={pageValue2}
                          setPageValue={setPageValue2}
                          tableCallback={fetchTableData}
                          chartData={chartData}
                          setPositionPageValue={setPositionPageValue}
                          metricsList={metricsList}
                          selectedMetric={selectedMetric}
                          setSelectedMetric={setSelectedMetric}
                          setSelectedSearchQuery={setSelectedSearchQuery}
                          onCheck={(e) => handleCheck(e)}
                        />
                      }
                    />
                    {/* Отзывы */}
                    <Route path='/reviews' element={<ProductCardExtended_Feedbacks
                      tableData={{ ...tableProps, tableUrl, tableBody }}
                      paginatorRef={paginatorRef}
                      fetchedMetrics={fetchedMetrics}
                      fetchTableData={e => fetchTableData(e)}
                      setCheckedProducts={e => setCheckedProducts(e)}
                      productMetrics={productMetrics2}
                    />} />
                    {/* Отзывы */}
                    <Route
                      path="/feedbacks"
                      element={<ProductCardExtended_Feedbacks />}
                    />
                    {/* Варианты товара */}
                    <Route
                      path="/product_variants"
                      element={
                        <ProductCardExtended_ProductVariants
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          paginatorRef={paginatorRef}
                          tableCallback={fetchTableData}
                          chartData={chartData}
                        />
                      }
                    />
                    {/* Остатки по складам */}
                    <Route
                      path="/stock_balances"
                      element={
                        <ProductCardExtended_StockBalances
                          productMetrics={productMetrics2}
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          tableCallback={fetchTableData}
                          chartData={chartData}
                          metricsList={metricsList}
                          selectedMetric={selectedMetric}
                          setSelectedMetric={setSelectedMetric}
                        />
                      }
                    />
                    {/* Конкуренты */}
                    <Route
                      path="/competitors"
                      element={<ProductCardExtended_Competitors />}
                    />
                    {/* Финансовый анализ */}
                    <Route
                      path="/financial_analysis"
                      element={<ProductCardExtended_FinancialAnalysis />}
                    />
                    {/* Заказы и продажи */}
                    <Route
                      path="orders_and_sales"
                      element={
                        <ProductCardExtended_OrdersAndSales
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          tableCallback={fetchTableData}
                          paginatorRef={paginatorRef}
                          chartData={chartData}
                          productMetrics={productMetrics}
                          metricsList={metricsList}
                          selectedMetric={selectedMetric}
                          setSelectedMetric={setSelectedMetric}
                        />
                      }
                    />
                    {/* Акции */}
                    <Route
                      path="/stocks"
                      element={<ProductCardExtended_Stocks />}
                    />
                    {/* Рекламные компании */}
                    <Route
                      path="/advertising_companies"
                      element={<ProductCardExtended_AdvertisingCompanies />}
                    />
                    {/* Продавцы */}
                    <Route
                      path="/sellers"
                      element={<ProductCardExtended_Sellers
                        tableData={{ ...tableProps, tableUrl, tableBody }}
                        tableCallback={fetchTableData}
                        paginatorRef={paginatorRef}
                        chartData={chartData}
                        metricsList={metricsList}
                        selectedMetric={selectedMetric}
                        setSelectedMetric={setSelectedMetric}
                        onCheck={(e) => handleCheck(e)}
                      />}
                    />

                    {/* 404 */}
                    <Route path="/*" element={<Page404 />} />

                    <Route
                      path="/information"
                      element={
                        <ProductCardExtended_Information
                          pageValue3={pageValue3}
                          setPageValue3={(e) => setPageValue3(e)}
                          fetchedMetrics={fetchedMetrics}
                          chartData={chartData}
                          labels={labels}
                          chartDataName={chartDataName}
                          chartLoading={chartLoading}
                        />
                      }
                    />
                  </Routes>
                </DataContext.Provider>
                {/*                     
                        {pageValue == 'information' && <section className='product-info'>
                            <div className='product-info__content'>

                                <div className='card-container'>
                                    {fetchedMetrics?.map(({ labels, data }) =>
                                        <CardStatisticsVer2
                                            label={labels?.header?.[0]}
                                            value={data?.[0] && data?.[0]}
                                            unit={'₽'}
                                            dynamic={data?.[1] && data?.[1]}
                                        />
                                    )}
                                </div>

                                <Tabs tabs={btn_tab_3} pageValue={pageValue3} setPageValue={e => setPageValue3(e)} stopRedirect />
                                <BarCharts
                                    direction='x'
                                    legendPosition='top'
                                    datasets_prop={chartData}
                                    labels_prop={labels}
                                    data_name={chartDataName}
                                    loading={chartLoading}
                                />
                            </div>
                        </section>} */}

                {/* {pageValue == 'position' &&
                            <div className='position-content'>
                                <Tabs tabs={btn_tab_2} pageValue={pageValue2} setPageValue={setPageValue2} stopRedirect />
                                <TableBox
                                    fetchCallback={e => {
                                        resetTable()
                                        fetchTableData(e)
                                    }}
                                    {...tableProps}
                                    ref={paginatorRef}
                                    onCheck={setCheckedProducts}
                                />
                            </div>
                        } */}

                {pageValue == 'wasted_revenue' && (
                  <div className="lost_revenue-content">
                    <div className="card-container">
                      {fetchedMetrics?.map(({ labels, data }) => (
                        <CardStatisticsVer2
                          label={labels?.title[0]}
                          value={data && data[0]}
                          unit={'₽'}
                          dynamic={data && data[1]}
                        />
                      ))}
                    </div>

                    <BarCharts
                      direction="x"
                      legendPosition="top"
                      datasets_prop={chartData}
                      labels_prop={labels}
                      data_name={chartDataName}
                      loading={chartLoading}
                    />
                  </div>
                )}

                {/* {pageValue == 'feedbacks' && <section className='review-table'>
                            <div className='slider-review'>
                                <div className='rating'>
                                    <div className='rating_info'>Оценка
                                        <div className='grey-box'>{`${stateSlider[0]}-${stateSlider[1]}`}
                                            <Rating
                                                precision={0.2}
                                                defaultValue={2.5}
                                                max={1}
                                                readOnly
                                                emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
                                                icon={<StarIcon style={{ fill: '#faaf00' }} />}
                                                size="large"
                                            />
                                        </div>
                                    </div>

                                </div>
                                <SliderRange
                                    minValue={1}
                                    maxValue={5}
                                    minMax={false}
                                    step={0.5}
                                    setState={(e) => setStateSlider(e)}
                                    defaultValue={[2, 4]} />
                            </div>

                            <TableBox
                                fetchCallback={e => {
                                    resetTable()
                                    fetchTableData(e)
                                }}
                                {...tableProps}
                                ref={paginatorRef}
                                onCheck={setCheckedProducts}
                            />
                        </section>} */}

                {pageValue == 'options' && (
                  <section className="similar-products">
                    <BarCharts
                      direction="x"
                      legendPosition="top"
                      datasets_prop={chartData}
                      labels_prop={labels}
                      data_name={chartDataName}
                      loading={chartLoading}
                    />

                    <TableBox
                      {...tableProps}
                      fetchCallback={(e) => {
                        fetchTableData(e);
                      }}
                      ref={paginatorRef}
                      tableUrl={tableUrl}
                      tableBody={tableBody}
                      onCheck={setCheckedProducts}
                    />
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProductCardExtended };
