import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { TextareaAutosize } from '@mui/material';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import "./SendMessage.scss"

const SendMessage = ({
    disabled,
    frequentAnswers = false,
    message,
    value,
    onChange,
    onSendAction,
    isEdit = false,
    setIsEdit = false
}) => {

    useEffect(() => {
        if(isEdit) {
            onChange(message)
        } else {
            onChange('')
        }
    }, [isEdit]);

    return (
        <div className='send-message_container'>

            <div className='send-message'>
                {/* <div className='send-message__btn-group'>
                    <button className='btn-smile'>
                        <img src={smile_icon} />
                    </button>
                    <button className='btn-attach'>
                        <img src={attach_icon} />
                        <input className='input-file' type='file' />
                    </button>
                </div> */}

                <div className='field-and-changed-message'>
                    {isEdit && <div className='changed-message'>
                        <span>Редактирование сообщение</span>
                        <button className='btn-close' onClick={(e) => setIsEdit(false)}></button>
                    </div>}

                    <TextareaAutosize
                        height='40px'
                        style={{ backgroundColor: (disabled && !isEdit) ? '#e0e0e0' : '', overflow: 'auto' }}
                        placeholder='Напишите Ваш ответ здесь'
                        value={value}
                        disabled={disabled && !isEdit}
                        onChange={e => onChange(e.target.value)}
                    />
                </div>

                <ButtonBasic
                    green
                    disabled={disabled && !isEdit}
                    minWidth='110px'
                    width='110px'
                    maxWidth='110px'
                    text='Отправить'
                    onClick={e => onSendAction()}
                />
            </div>

            {/* {frequentAnswers && <FrequentAnswers />} */}
        </div>

    )
}

export { SendMessage }